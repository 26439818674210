import { Routes, Route, Navigate } from "react-router-dom";
import * as React from "react";
// import Dashboard from "../Pages/Dashboard";
import PrivateRoute from "./PrivateRoute";
import AuthRoute from "./AuthRoute";
import Navigation from "./navigation/navigation.component";
import Products from "./products/products.component";
import Services from "./services/services.component";
import Login from "./login/login.component";
import BookingNavigation from "./bookings/bookings-navigation.component";
import Sales from "./sales/sales.component";
import AdminRoutes from "./admin/admin.route";
import AdminProtected from "./AdminProtected.route";
import Subscription from "./subscription/subscription.component";

function RoutePage() {
  return (
    <Routes>
      <Route path="/login" element={<AuthRoute element={Login} />} />
      <Route path="/" element={<PrivateRoute element={Navigation} />}>
        <Route index element={<Services />} />
        <Route path="products/*" element={<Products />} />
        <Route path="services/*" element={<Services />} />
        <Route path="bookings/*" element={<BookingNavigation />} />
        <Route path="sales/*" element={<Sales />} />
        <Route
          path="admin/*"
          element={<AdminProtected element={AdminRoutes} />}
        />
        {/* <Route path="subscription/*" element={<Subscription />} /> */}
      </Route>
      {/* FOR NOW, if user is entering wrong URL, redirecting them to our home page i.e services temporary */}
      <Route path="*" element={<Navigate to="/services" />} />
    </Routes>
  );
}

export default RoutePage;
