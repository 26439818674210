import { Col } from "react-bootstrap";
import CustomButton from "../button/button.component";

const FormFooterButtons = ({
  mdSize = "12",
  lgSize = "12",
  className = "",
  displayPreviousButton,
  previousButtonText,
  handlePreviouButtonClick,
  nextButtonText,
  handleNextButtonClick,
}) => {
  return (
    <Col md={mdSize} lg={lgSize} className={className}>
      {displayPreviousButton ? (
        <div style={{ display: "flex" }}>
          <Col md="4" lg="2" style={{ marginRight: "12px" }}>
            <CustomButton
              title={previousButtonText}
              buttonType="inverted"
              handleButtonClick={handlePreviouButtonClick}
            />
          </Col>
          <Col md="8" lg="10">
            {handleNextButtonClick ? (
              <CustomButton
                title={nextButtonText}
                handleButtonClick={handleNextButtonClick}
              />
            ) : (
              <CustomButton title={nextButtonText} type="submit" />
            )}
          </Col>
        </div>
      ) : handleNextButtonClick ? (
        <CustomButton
          title={nextButtonText}
          handleButtonClick={handleNextButtonClick}
        />
      ) : (
        <CustomButton title={nextButtonText} type="submit" />
      )}
    </Col>
  );
};

export default FormFooterButtons;
