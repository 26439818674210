import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { ApolloProvider, ApolloClient, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import jwt_decode from "jwt-decode";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./store";
import { AES, enc } from "crypto-js";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { auth } from "./firebase";
import { createUploadLink } from "apollo-upload-client";

const uploadLink = createUploadLink({
  uri: `${process.env.REACT_APP_HOST_URL}/graphql`, // Your backend GraphQL endpoint
});

const encryptToken = (token) => {
  const encryptedToken = AES.encrypt(token, process.env.REACT_APP_ENCRYPTION_KEY).toString();
  return encryptedToken;
};

const decryptToken = (encryptedToken) => {
  const decryptedToken = AES.decrypt(encryptedToken, process.env.REACT_APP_ENCRYPTION_KEY).toString(enc.Utf8);
  return decryptedToken;
};

const getTokenFromLocalStorage = () => {
  const encryptedToken = localStorage.getItem("accessToken");
  if (encryptedToken) {
    return decryptToken(encryptedToken);
  }
  return null;
};

const isTokenExpired = (token) => {
  if (!token) {
    return true;
  }

  const decodedToken = jwt_decode(token);
  const currentTime = Math.floor(Date.now() / 1000); // Get current timestamp

  return decodedToken.exp < currentTime;
};

const regenerateToken = async () => {
  const user = auth.currentUser;
  if (user) {
    try {
      const token = await user.getIdToken();
      let decodedToken = jwt_decode(token);
      let encryptedAuthTime = AES.encrypt(
        decodedToken.auth_time.toString(),
        process.env.REACT_APP_ENCRYPTION_KEY
      ).toString();
      localStorage.setItem("expiryTime", encryptedAuthTime);
      localStorage.setItem("isAuthenticated", "true");
      const encryptedToken = encryptToken(token);
      localStorage.setItem("accessToken", encryptedToken);
      return token;
    } catch (error) {
      // console.log("Error regenerating token:", error);
    }
  } else {
    localStorage.clear();
    return null;
  }
};

const authLink = setContext(async (_, { headers }) => {
  let accessToken = getTokenFromLocalStorage();

  // Check if the token has expired
  const tokenExpired = accessToken ? isTokenExpired(accessToken) : true;

  if (tokenExpired) {
    // Regenerate the token
    accessToken = await regenerateToken();
  }

  return {
    headers: {
      ...headers,
      authorization: accessToken ? accessToken : "",
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(uploadLink),
  cache: new InMemoryCache(),
});

const Root = () => {
  useEffect(() => {
    const unregisterAuthObserver = auth.onAuthStateChanged(async (user) => {
      if (user) {
        await regenerateToken();
      } else {
        localStorage.clear();
      }
    });

    return () => {
      unregisterAuthObserver();
    };
  }, []);
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ApolloProvider client={client}>
          <React.StrictMode>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </React.StrictMode>
        </ApolloProvider>
      </PersistGate>
    </Provider>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<Root/>);

reportWebVitals();