import { useEffect, useState } from "react";
import CategoryList from "../category-list/category-list.admin.component";
import CategorySearch from "../category-search/category-search.component";
import { CATEGORY_SEARCH, DELETE_SUBCATEGORY } from "../../services/graphql/admin/category";
import { useLazyQuery, useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import LoadingSpinner from "../shared/loading-spinner/loading-spinner.component";

const Categories =  () =>{
    const [isLoading, setIsLoading] = useState(true);
    const [search, setSearch] = useState("");
    const [categories, setCategories] = useState([])

  const [fetchCategories] = useLazyQuery(CATEGORY_SEARCH,{
    fetchPolicy:"no-cache",
    notifyOnNetworkStatusChange:true,
    onCompleted:(data) =>{
      if(data && data.categorySearch){
        setCategories(data.categorySearch)
      }else{
        setCategories([])
      }
      setIsLoading(false)
    },
    onError:(error) =>{
      setIsLoading(false);
      toast.error(error.message, {
        style: { width: "450px" },
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored ",
      });
    }
  });
  
  const getCategories = async()=>{
    await fetchCategories({
      variables:{
        query: search,
      }
    })
  }

  useEffect(()=>{
    if(isLoading){
      getCategories();
    }
  },[isLoading])

  const [removeSubCategory] = useMutation(DELETE_SUBCATEGORY,{
    fetchPolicy:"no-cache",
    notifyOnNetworkStatusChange:true,
    onCompleted:(data) =>{
    if(data && data.deleteSubCategory.success){
        toast.success(data.deleteSubCategory.message, {
            style: { width: "450px" },
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored ",
          });
    }else{
        toast.error(data.deleteSubCategory.message, {
            style: { width: "450px" },
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored ",
          });
    }
      setIsLoading(false)
    },
    onError:(error) =>{
      setIsLoading(false);
      toast.error(error.message, {
        style: { width: "450px" },
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored ",
      });
    }
  })
  const handleRemoveSubCategory=async(categoryId, subcategoryId) =>{
    await removeSubCategory({
        variables:{
            categoryId,
            subcategoryId
        }
    })
  }
    return(
        <div className="main-div-right-section">
            <CategorySearch setIsLoading={setIsLoading} search={search} setSearch={setSearch}/>
            {isLoading ? 
            <div style={{marginTop:"3%"}}>
                <LoadingSpinner overlay={false}/>
            </div>
            :
            <CategoryList categories={categories} setIsLoading={setIsLoading} handleRemoveSubCategory={handleRemoveSubCategory}/>
            }
        </div>
    )
}

export default Categories;