import { Row, Col } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoadingSpinner from "../shared/loading-spinner/loading-spinner.component";
import { CREATE_PRODUCT } from "../../services/graphql/product";
import IllustrationImage from "../shared/illustration-image/illustration-image.component";
import SelectImages from "../select-images/select-images.component";
import FormFooterButtons from "../../components/form-footer-buttons/form-footer-buttons.component";
import SectionTitle from "../section-title/section-title.component";

const ProductImageForm = ({ handleBackClick, setProductImage, formValue }) => {
  const [images, setImages] = useState([]);
  //General error
  const [isGeneralError, setIsGeneralError] = useState(false);
  useEffect(() => {
    if (formValue.serviceImageList && formValue.serviceImageList.length > 0) {
      setImages(formValue.serviceImageList);
    }
  }, [formValue]);
  const [createProduct, { loading }] = useMutation(CREATE_PRODUCT, {
    onCompleted: (data) => {
      toast.success(data.createProduct.message, {
        style: { width: "450px" },
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored ",
      });
      setProductImage(images, true);
    },
    onError: (error) => {
      // console.log(error, "ERROR==============");
      toast.error(error.message, {
        style: { width: "450px" },
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored ",
      });
    },
  });
  const onChange = (imageList) => {
    if (imageList.length > 0) {
      setIsGeneralError(false);
    } else {
      setIsGeneralError(true);
    }
    setImages(imageList);
  };
  const handleSubmit = async () => {
    if (images.length === 0) {
      setIsGeneralError(true);
    } else {
      setProductImage(images);
      let medias = [];
      images.map((imageValue, index) => {
        let tempStore = {
          file: imageValue.file,
          mediaType: "photo",
          height: 720,
          width: 1080,
          key: index,
        };
        // console.log("createdServiceInput----", tempStore);
        medias.push(tempStore);
      });
      const variantCombinations = formValue.setVariantCombination.map(
        (combination) => {
          return {
            ...combination,
            price: Number(parseFloat(combination.price).toFixed(2)),
            discount: Number(parseFloat(combination.discount).toFixed(2)),
            quantity: parseInt(combination.quantity),
          };
        }
      );
      let finalCategory = {...formValue.selectedCategory[0], subCategories: formValue.selectedCategory[0].subCategories && formValue.selectedCategory[0].subCategories.length > 0 ?
        formValue.selectedCategory[0].subCategories : []
      }
      if(finalCategory.subCategories.length > 0 ){
        await finalCategory.subCategories.map((category) => {
          delete category.__typename;
          delete category.isChecked;
        });
      }
      await createProduct({
        variables: {
          categories: [finalCategory],
          createProductInput: {
            medias: medias,
            title: formValue.productTitle,
            description: formValue.productDescription,
            productDetails: formValue.productDetail,
            sku: formValue.productSKU,
            price: formValue.productPrice,
            discount:
              formValue.productDiscount === "0"
                ? "0"
                : formValue.productDiscount,
            serviceType: "Personal",
            itemType: "Product",
            quantity: formValue.productQuantity,
            quantityLimit: formValue.productRestricedUser,
            variants: formValue.variants,
            variantCombinations: variantCombinations
          },
        },
      });
    }
  };

  const handleBack = () => {
    if (images.length !== 0) {
      setProductImage(images);
    }
    handleBackClick();
  };

  return (
    <div>
      <div style={{ marginTop: "5%", marginBottom: "3%" }}>
        <SectionTitle
          title={"Product images"}
          subtitle={"Upload up to 7 images"}
        />
      </div>
      <div>
        <Row className="mb-3">
          <Col
            md="6"
            lg="6"
            sm="12"
            xs="12"
            style={{ marginLeft: "3%", marginTop: "20px" }}
          >
            <SelectImages
              images={images}
              onChange={onChange}
              isGeneralError={isGeneralError}
            />
            {loading ? (
              <LoadingSpinner text={"Uploading..."} />
            ) : (
              <Row style={{ display: "flex", marginBottom: "3%" }}>
                <FormFooterButtons
                  displayPreviousButton={true}
                  previousButtonText={"Back"}
                  handlePreviouButtonClick={handleBack}
                  nextButtonText={"Submit"}
                  handleNextButtonClick={handleSubmit}
                />
              </Row>
            )}
          </Col>
          <Col
            md="5"
            lg="5"
            style={{
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
            }}
          >
            <IllustrationImage />
          </Col>
        </Row>
      </div>
    </div>
  );
};
export default ProductImageForm;
