import { Form, Row, Col } from "react-bootstrap";
import { React, useState } from "react";
import CounterInput from "react-bootstrap-counter";
import { IconContext } from "react-icons";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IoInformationCircleOutline } from "react-icons/io5";
import IllustrationImage from "../../components/shared/illustration-image/illustration-image.component";
import FormFooterButtons from "../form-footer-buttons/form-footer-buttons.component";
import SectionTitle from "../../components/section-title/section-title.component";

const ServiceClientForm = ({
  handleBackClick,
  handleNextClick,
  setServiceClient,
  formValue,
}) => {
  const [serviceNoClient, setServiceNoClient] = useState(
    formValue.serviceNoClient && formValue.serviceNoClient !== ""
      ? formValue.serviceNoClient
      : 1
  );
  const [serviceNoClientValid, setServiceNoClientValid] = useState(false);
  const [serviceMaxClient, setServiceMaxClient] = useState(
    formValue.serviceMaxClient && formValue.serviceMaxClient !== ""
      ? formValue.serviceMaxClient
      : 1
  );
  const [serviceMaxClientValid, setServiceMaxClientValid] = useState(false);

  const handleSubmit = () => {
    if (serviceNoClient === 0) {
      setServiceNoClientValid(true);
    } else if (serviceMaxClient === 0) {
      setServiceMaxClientValid(true);
    } else if(serviceNoClient > serviceMaxClient){
      const message = "Number of clients can't be greater than maximum capacity. Please enter a valid entry.";
      toast.error(message, {
        style:{width:'450px', maxWidth:'550px'},
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
     else {
      setServiceClient(serviceNoClient, serviceMaxClient);
      handleNextClick();
    }
  };

  return (
    <div>
       <div style={{ marginTop: "5%", marginBottom: "3%" }}>
        <SectionTitle title={"Determine number of clients"} subtitle={"Select the number of people that your service applies"} />
        </div>
      <Form>
        <Row className="mb-3">
          <Col md="6" lg="6" style={{marginLeft:"3%"}}>
            <Col md="12" lg="12">
              <Form.Group
                // controlId="validationCustom01"
                style={{ color: "#A6A6A6" }}
              >
                <Form.Label>
                  Number of clients at the same time per booking
                </Form.Label>
                <CounterInput
                  value={serviceNoClient}
                  min={0}
                  max={50}
                  onChange={(value) => {
                    setServiceNoClient(value);
                  }}
                />
              </Form.Group>
              {serviceNoClientValid ? (
                <label style={{ fontSize: ".875em", color: "#dc3545" }}>
                  Please select a valid number.
                </label>
              ) : null}
            </Col>
            <div style={{ display: "flex", marginTop: "20px" }}>
              <Col md="12" lg="12">
                <Form.Group
                  // controlId="validationCustom01"
                  style={{ color: "#A6A6A6" }}
                >
                  <Form.Label>
                    Maximum capacity of clients for this service (if applicable)
                  </Form.Label>
                  <CounterInput
                    min={0}
                    max={50}
                    onChange={(value) => {
                      setServiceMaxClient(value);
                    }}
                    value={serviceMaxClient}
                  />
                  <Col
                    md="12"
                    lg="12"
                    style={{ display: "flex", marginTop: "1%" }}
                  >
                    <IconContext.Provider value={{ size: 22, color: "black" }}>
                      <IoInformationCircleOutline />
                    </IconContext.Provider>
                    <label style={{ fontSize: "12px", marginLeft: "1%" }}>
                      Only if service has full capacity. E.g. trip tours, travel
                      buses, etc.
                    </label>
                  </Col>
                </Form.Group>
                {serviceMaxClientValid ? (
                  <label style={{ fontSize: ".875em", color: "#dc3545" }}>
                    Please select a valid number.
                  </label>
                ) : null}
              </Col>
            </div>
            <FormFooterButtons displayPreviousButton={true} previousButtonText={"Back"} handlePreviouButtonClick={handleBackClick} nextButtonText={"Continue"} handleNextButtonClick={handleSubmit}/>
          </Col>
          <Col
            md="5"
            lg="5"
            style={{
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
            }}
          >
           <IllustrationImage/>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default ServiceClientForm;
