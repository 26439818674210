import { useMutation } from '@apollo/client';
import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { INSERT_SUBCATEGORY } from '../../services/graphql/admin/category';

const AddSubcategoryModal = ({ selectedCategory, show, handleClose }) => {
    const [name, setName] = useState('');
    const [error, setError] = useState('');
    const [addSubCategory] = useMutation(INSERT_SUBCATEGORY,{
        onCompleted:(data)=>{
            console.log(data)
            if(data && data.addSubCategoryByCategoryId.success){
                setName('');
                setError('');
                handleClose("success");
            }else{
                setError('An error occurred while adding the subcategory.Please try again later')
            }
        }
    });
  
    const validateName = (name) => {
        if (!name.trim()) {
          return 'Name cannot be empty or just whitespace.';
        }
        if (/^[\s]/.test(name)) {
          return 'Name cannot start with a space.';
        }
        if (/[^a-zA-Z0-9 ]/.test(name)) {
          return 'Name cannot contain special characters.';
        }
        if (name.trim().length < 3) {
          return 'Name must be at least 3 characters long.';
        }
        return '';
      };
    
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      const validationError = validateName(name);
      if (validationError) {
        setError(validationError);
        return;
      }
      try {
        await addSubCategory({ variables: { categoryId:selectedCategory._id, subCategory: {name:name.trim()} } });
      } catch (err) {
        setError('An error occurred while adding the subcategory.');
      }
    };
  
    return (
      <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>Add Subcategory to {selectedCategory.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formSubcategoryName">
              <Form.Label>Subcategory Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter subcategory name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                isInvalid={!!error}
                required
              />
              <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
            </Form.Group>
            <Button variant="primary" type="submit" className="mt-3">
              Add Subcategory
            </Button>
            <Button variant="secondary" onClick={handleClose} className="mt-3 ms-2">
              Cancel
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    );
  };
  
  export default AddSubcategoryModal;
  