import { Fragment } from "react";
import { Col } from "react-bootstrap";

const BookingListHeader = () => {
  return (
    <Fragment>
      <Col lg={3} md={3} sm={3} xs={3} style={{ marginLeft: "1%" }}>
        <div>
          <label style={{ fontSize: "19px", fontWeight: "bold" }}>Sr. No</label>
        </div>
      </Col>
      <Col lg={3} md={3} sm={3} xs={3} style={{ marginLeft: "1%" }}>
        <div>
          <label style={{ fontSize: "19px", fontWeight: "bold" }}>Time</label>
        </div>
      </Col>
      <Col lg={5} md={5} sm={5} xs={5} style={{ marginLeft: "1%" }}>
        <div>
          <label style={{ fontSize: "19px", fontWeight: "bold" }}>
            Service
          </label>
        </div>
      </Col>
    </Fragment>
  );
};

export default BookingListHeader;
