import { Navigate, Route, Routes } from "react-router-dom";
import Admin from "../../components/admin/admin.component";
import Categories from "../../components/admin-categories/admin-categories.component";
import AdminCoupon from "../../components/admin-coupon/admin-coupon.component";

const AdminRoutes = () => {
  return (
    <Routes>
        <Route index element={<Admin />} />
        <Route path="categories" element={<Categories/>}/>
        <Route path="coupons" element={<AdminCoupon/>}/>
        <Route path="*" element={<Navigate to="/admin" />} />
    </Routes>
  );
};

export default AdminRoutes;
