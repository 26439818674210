import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import RoutePage from "./routes/RoutePage";
import { useAuth } from "./Hooks/useAuth";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import DesktopOnlyScreen from "./components/desktop-only-screen/desktop-only-screen.component";

function App() {
  useAuth();
  const [isCheckMobile, setIsCheckMobile] = useState(false);
  useEffect(() => {
    if (isMobile) {
      setIsCheckMobile(true);
    } else {
      setIsCheckMobile(false);
    }
  }, []);
  return (
    <div>
      {isCheckMobile ? (
        <DesktopOnlyScreen />
      ) : (
        <>
          <RoutePage />
          <ToastContainer />
        </>
      )}
    </div>
  );
}

export default App;
