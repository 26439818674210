import { Col, Form, InputGroup, Row } from "react-bootstrap";
import { BsSearch, BsXLg } from "react-icons/bs";

const CategorySearch = (props) =>{
    const {setIsLoading , search ,setSearch} = props;
    return(
        <div>
            <h2 style={{ marginTop: "3%", marginLeft:"3%"}}>Categories</h2>
            <div style={{ marginTop: "3%"}}>
                    <Row style={{display:'flex', justifyContent:"center"}}>
                    <Col lg="3" md="3" sm="2" xs="2" style={{ paddingLeft: "0px"}}>
                        <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                        >
                        <InputGroup style={{backgroundColor: "white" }}>
                            <BsSearch color="#7d7d7d" />
                            <Form.Control
                            type="text"
                            style={{backgroundColor: "white" }}
                            placeholder="Search by Category or Subcategory name"
                            value={search}
                            onChange={(e) => {
                                setIsLoading(true)
                                setSearch(e.target.value)}
                            }
                            />
                            {search ? (
                            <BsXLg
                                onClick={() => {
                                    setIsLoading(true)
                                    setSearch("");
                                }}
                                color="#7d7d7d"
                            />
                            ) : null}
                        </InputGroup>
                        </Form.Group>
                    </Col>
                    </Row>
            </div>
        </div>
    )
}

export default CategorySearch;