import { React } from "react";
import { Col, Row, Image } from "react-bootstrap";
import { useSelector } from "react-redux";
import { IconContext } from "react-icons";
import { IoNotifications, IoHelpCircleOutline } from "react-icons/io5";
import { netural50 } from "../../constants/color";

const HeaderSection = ({ currentTab }) => {
  const businesprofile = useSelector((state) => state.businesprofile);

return (
    <div
      style={{
        backgroundColor: netural50,
        width: "100%",
      }}
    >
      <div
        className="dashboard-header-shadow"
        style={{
          paddingTop: "15px",
          paddingBottom: "15px",
          paddingRight: "15px",
          paddingLeft: "30px",
          backgroundColor: "white",
        }}
      >
        <Row>
          <Col lg="10" md="8" sm="6" xs="6" style={{ paddingLeft: "0px" }}>
            <div>
              {/* <Col lg="6" md="10" sm="12" xs="12">
                {currentTab === "Create Service" ||
                currentTab === "Edit Service" ? null : (
                  <FormGroup>
                    <FormControl
                      placeholder="Searh Adidas’ workplace"
                      aria-label="Username"
                    />
                  </FormGroup>
                )}
              </Col> */}
            </div>
          </Col>
          <Col
            lg="2"
            md="4"
            sm="6"
            xs="6"
            style={{ display: "flex", alignItems: "center" }}
          >
            <Col
              lg="4"
              md="4"
              sm="4"
              xs="4"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <IconContext.Provider value={{ size: 24, color: "black" }}>
                <IoNotifications />
              </IconContext.Provider>
            </Col>
            <Col
              lg="4"
              md="4"
              sm="4"
              xs="4"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <IconContext.Provider value={{ size: 24, color: "black" }}>
                <IoHelpCircleOutline />
              </IconContext.Provider>
            </Col>
            <Col
              lg="4"
              md="4"
              sm="4"
              xs="4"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Image
                src={
                  businesprofile?.logo
                    ? `${process.env.REACT_APP_HOST_URL}${businesprofile.logo}`
                    : "./images/Profile.jpg"
                }
                alt="Profile"
                style={{
                  objectFit: "cover",
                  borderRadius: "50%",
                  height: 40,
                  width: 40,
                }}
              />
            </Col>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default HeaderSection;
