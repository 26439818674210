import { Col } from "react-bootstrap";

const BookingListEmpty = () => {
  return (
    <Col
      lg={8}
      md={8}
      sm={8}
      xs={8}
      style={{
        marginLeft: "1%",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <label style={{ fontWeight: "400", fontSize: "16px" }}>
        No services booked
      </label>
    </Col>
  );
};

export default BookingListEmpty;
