import { Route, Routes } from "react-router-dom";
import Bookings from "../../components/bookings/bookings.component";

const BookingNavigation = () => {

  return (
    <Routes>
      <Route index element={<Bookings />} />
    </Routes>
  );
};

export default BookingNavigation;
