import { City, Country, State } from "country-state-city";
import { useState } from "react";
import { useEffect } from "react";
import { React } from "react";
import { Form, Col, Row } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { IconContext } from "react-icons";
import { IoInformationCircleOutline } from "react-icons/io5";
import IllustrationImage from "../shared/illustration-image/illustration-image.component";
import FormFooterButtons from "../../components/form-footer-buttons/form-footer-buttons.component";
import SectionTitle from "../section-title/section-title.component";

const ServiceLocationForm = ({ handleBackClick, handleNextClick, setServiceLocation, formValue }) => {
  const [validated, setValidated] = useState(false);

  const [allCountry, setAllCountry] = useState([]);
  const [state, setState] = useState([]);
  const [selectedState, setSelectedState] = useState([]);
  const [city, setCity] = useState([]);
  const [selectedCity, setSelectedCity] = useState([]);
  let changeCountry = false;

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);

    if (form.checkValidity() === true) {
      setServiceLocation(
        allCountry,
        selectedState,
        selectedCity,
      )
      handleNextClick();
    }
  };

  useEffect(() => {
    if (allCountry.length !== 0) {
      getState();
    } else {
      setSelectedState([]);
      setSelectedCity([]);
      changeCountry = true;
    }
  }, [allCountry, changeCountry]);

  useEffect(() => {
    if (selectedState.length !== 0) {
      getCity();
    } else {
      setSelectedCity([]);
    }
  }, [selectedState]);

  const getState = () => {
    if (allCountry.length !== 0) {
      setState(State.getStatesOfCountry(allCountry[0].isoCode));
    }
  };
  const getCity = () => {
    if (!changeCountry) {
      if (selectedState.length !== 0) {
        setCity(
          City.getCitiesOfState(allCountry[0].isoCode, selectedState[0].isoCode)
        );
      }
    }
  };

  useEffect(() => {
    if (formValue.serviceCountry && formValue.serviceCountry !== "") {
      setAllCountry(formValue.serviceCountry);
      setSelectedState(formValue.serviceState);
      setSelectedCity(formValue.serviceCity);
    }
  }, []);

  return (
    <div>
      <div>
        <div
          style={{
            marginTop: "5%",
            backgroundColor: "#FFF3DA",
            padding: 16,
            marginRight: "5%",
          }}
        >
          <IconContext.Provider value={{ size: 22, color: "black" }}>
            <IoInformationCircleOutline />
          </IconContext.Provider>
          <label
            style={{
              fontSize: "16px",
              fontWeight: "500",
              color: "#545454",
              marginLeft: 10,
            }}
          >
            You won’t be able to edit this information later.
          </label>
        </div>
        <div style={{ marginTop: "5%", marginBottom: "3%" }}>
        <SectionTitle title={"What’s your location range?"} subtitle={"Specify which region you’ll be able to provide your services in person"} />
        </div>
      </div>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Row>
          <Col lg="6" md="6" sm="6" xs="6" style={{marginLeft:"3%"}}>
            <Col md="12" lg="12">
              <Form.Group
                controlId="validationCustom01"
                style={{ color: "#A6A6A6" }}
              >
                <Form.Label>Country / Region</Form.Label>
                <Typeahead
                  id="basic-typeahead-single"
                  labelKey="name"
                  onChange={setAllCountry}
                  options={Country.getAllCountries()}
                  placeholder="Country / Region"
                  selected={allCountry}
                  inputProps={{ required: true }}
                />
                <Form.Control.Feedback>
                  Please select a valid Country / Region.
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <div style={{ display: "flex", marginTop: "20px" }}>
              <Col md="6" style={{ color: "#A6A6A6", paddingRight: "10px" }}>
                <Form.Group controlId="validationCustom01">
                  <Form.Label>State / Province</Form.Label>
                  <Typeahead
                    id="basic-typeahead-single"
                    labelKey="name"
                    onChange={setSelectedState}
                    options={state}
                    placeholder="State / Province"
                    selected={selectedState}
                    disabled={allCountry.length === 0 ? true : false}
                    inputProps={{ required: true }}
                  />
                  <Form.Control.Feedback>
                    Please select a valid State / Province.
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>

              <Col md="6" style={{ color: "#A6A6A6" }}>
                <Form.Group controlId="validationCustom01">
                  <Form.Label>City</Form.Label>
                  <Typeahead
                    id="basic-typeahead-single"
                    labelKey="name"
                    onChange={setSelectedCity}
                    options={city}
                    placeholder="City"
                    selected={selectedCity}
                    disabled={selectedState.length === 0 ? true : false}
                    inputProps={{ required: true }}
                  />
                  <Form.Control.Feedback>
                    Please select a valid City.
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </div>
            <FormFooterButtons displayPreviousButton={true} previousButtonText={"Back"} handlePreviouButtonClick={handleBackClick} nextButtonText={"Continue"}/>
          </Col>
          <Col
            md="5"
            lg="5"
            style={{
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
            }}
          >
            <IllustrationImage/>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default ServiceLocationForm;
