import React from "react";
import PropTypes from "prop-types";
import "./loading-spinner.styles.css";

const LoadingSpinner = ({ size, overlay, color, text }) => {
  const spinnerClassName = `loading-spinner ${size}`;

  const spinnerStyle = {
    borderTopColor: color,
  };

  const getFontSize = () => {
    if (size === "small") return "12px";
    if (size === "medium") return "18px";
    if (size === "large") return "24px";
    return "18px"; // Default font size
  };

  const textStyle = {
    fontSize: getFontSize(),
  };

  return (
    <>
      {overlay && (
        <div className="loading-spinner-overlay">
          <div className="loading-spinner-container">
            <div className={spinnerClassName} style={spinnerStyle}></div>
            {text && <span className="loading-spinner-text" style={textStyle}>{text}</span>}
          </div>
        </div>
      )}
      {!overlay && (
        <div className="loading-spinner-container">
          <div className={spinnerClassName} style={spinnerStyle}></div>
          {text && <span className="loading-spinner-text" style={textStyle}>{text}</span>}
        </div>
      )}
    </>
  );
};

LoadingSpinner.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),
  overlay: PropTypes.bool,
  color: PropTypes.string,
  text: PropTypes.string,
};

LoadingSpinner.defaultProps = {
  size: "medium",
  overlay: true,
  color: "#FF8D24", // Default color
};

export default LoadingSpinner;
