import { Route, Routes } from "react-router-dom";
import CreateServiceForm from "../../components/create-service-form/create-service-form.component";
import ServicesList from "../../components/service-list/service-list.component";
import EditService from "../../components/edit-service/edit-serivce.component";

const Services = () =>{
    return(
    <Routes>
        <Route index element={<ServicesList/>} />
        <Route path="create" element={<CreateServiceForm/>} />
        <Route path="edit/:serviceId" element={<EditService/>} />
    </Routes>
    )
}

export default Services;