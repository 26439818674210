import CustomButton from "../button/button.component";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { neturalBlack } from "../../constants/color";

const Admin = () => {
  const navigate = useNavigate();

  const actionList =[
    {
      id:1,
      actionLabel:"Categories",
      navigation:"categories"
    },
    {
      id:2,
      actionLabel:"Coupons",
      navigation:"coupons"
    }
  ];

  return (
    <div className="main-div-right-section">
      <div className="second-section-style">
        <Row>
          <Col
            lg="10"
            md="8"
            sm="6"
            xs="6"
            style={{
              paddingLeft: "0px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <label style={{ fontSize: "22px", fontWeight: "bold" }}>
              Admin
            </label>
          </Col>
          {/* <Col lg="2" md="4" sm="6" xs="6">
            <CustomButton
              title={"Categories"}
              customStyle={{ marginTop: "0px", padding: "8px" }}
              handleButtonClick={() => {
                navigate("categories");
              }}
            />
          </Col>
          <Col lg="2" md="4" sm="6" xs="6">
            <CustomButton
              title={"Coupon"}
              customStyle={{ marginTop: "0px", padding: "8px" }}
              handleButtonClick={() => {
                navigate("categories");
              }}
            />
          </Col> */}
          <div style={{ marginTop: "3%" }}>
            <label style={{ fontSize: "22px", fontWeight: "bold" }}>
              Action list
            </label>
          </div>
          <Col
            xl={{ span: 12 }}
            lg={{ span: 12}}
            md={{ span: 12}}
            style={{
              marginTop: "3%",
              display: "flex",
              border: "solid 1px #545454",
            }}
          >
          <Col
            xl="6"
            lg="6"
            md="6"
            style={{
              borderRight: "solid 1px #545454",
              paddingTop: "1%",
              paddingBottom: "1%",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <label
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  color: neturalBlack,
                }}
              >
                ACTION_NAME
              </label>
            </div>
          </Col>
          <Col
            xl="6"
            lg="6"
            md="6"
            style={{
              paddingTop: "1%",
              paddingBottom: "1%",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <label
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  color: neturalBlack,
                }}
              >
                ACTION_BUTTON
              </label>
            </div>
          </Col>
        </Col>

          {actionList.map((list, index) => {
            return (
              <Col
                xl="12"
                lg="12"
                md="12"
                style={{
                  display: "flex",
                  borderBottom: "solid 1px #545454",
                  borderLeft: "solid 1px #545454",
                  borderRight: "solid 1px #545454",
                }}
                key={index}
              >
                <Col
                  xl="6"
                  lg="6"
                  md="6"
                  style={{
                    borderRight: "solid 1px #545454",
                    paddingBottom: "1%",
                    paddingTop: "1%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      height:"100%",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems:"center"
                    }}
                  >
                    <label
                      style={{
                        textAlign:"center",
                        fontSize: "16px",
                        fontWeight:"bold",
                        color: neturalBlack,
                      }}
                    >
                      {list.actionLabel}
                    </label>
                  </div>
                </Col>
                <Col
                  xl="6"
                  lg="6"
                  md="6"
                  style={{
                    paddingBottom: "1%",
                    paddingTop: "1%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                    }}
                  >
                   <CustomButton
                    title={list.actionLabel}
                    customStyle={{ marginTop: "0px", padding: "8px", width:"50%" }}
                    handleButtonClick={() => {
                    navigate(list.navigation);
                    }}
                  />
                  </div>
                </Col>
              </Col>
            );
          })}
          {/* <div style={{ marginTop: "3%" }}>
            <label style={{ fontSize: "22px", fontWeight: "bold" }}>
              Coupon list
            </label>
          </div>
          <Col
            xl="12"
            lg="12"
            md="12"
            style={{
              marginTop: "3%",
              display: "flex",
              border: "solid 1px #545454",
            }}
          >
            <Col
              xl="4"
              lg="4"
              md="4"
              style={{
                borderRight: "solid 1px #545454",
                paddingTop: "1%",
                paddingBottom: "1%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <label
                  style={{
                    fontSize: "16px",
                    fontWeight: "500",
                    color: neturalBlack,
                  }}
                >
                  Customer name
                </label>
              </div>
            </Col>
            <Col
              xl="2"
              lg="2"
              md="2"
              style={{
                borderRight: "solid 1px #545454",
                paddingTop: "1%",
                paddingBottom: "1%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <label
                  style={{
                    fontSize: "16px",
                    fontWeight: "500",
                    color: neturalBlack,
                  }}
                >
                  Coupon code
                </label>
              </div>
            </Col>
            <Col
              xl="2"
              lg="2"
              md="2"
              style={{
                borderRight: "solid 1px #545454",
                paddingTop: "1%",
                paddingBottom: "1%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <label
                  style={{
                    fontSize: "16px",
                    fontWeight: "500",
                    color: neturalBlack,
                  }}
                >
                  Coupon discount
                </label>
              </div>
            </Col>
            <Col
              xl="2"
              lg="2"
              md="2"
              style={{
                borderRight: "solid 1px #545454",
                paddingTop: "1%",
                paddingBottom: "1%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <label
                  style={{
                    fontSize: "16px",
                    fontWeight: "500",
                    color: neturalBlack,
                  }}
                >
                  Amount
                </label>
              </div>
            </Col>
            <Col
              xl="2"
              lg="2"
              md="2"
              style={{ paddingTop: "1%", paddingBottom: "1%" }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <label
                  style={{
                    fontSize: "16px",
                    fontWeight: "500",
                    color: neturalBlack,
                  }}
                >
                  Purchase date
                </label>
              </div>
            </Col>
          </Col>
          {couponList.map((list, index) => {
            return (
              <Col
                xl="12"
                lg="12"
                md="12"
                style={{
                  display: "flex",
                  borderBottom: "solid 1px #545454",
                  borderLeft: "solid 1px #545454",
                  borderRight: "solid 1px #545454",
                }}
                key={index}
              >
                <Col
                  xl="4"
                  lg="4"
                  md="4"
                  style={{
                    borderRight: "solid 1px #545454",
                    paddingBottom: "1%",
                    paddingTop: "1%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                    }}
                  >
                    <label
                      style={{
                        fontSize: "16px",
                        color: neturalBlack,
                      }}
                    >
                      {list.name}
                    </label>
                  </div>
                </Col>
                <Col
                  xl="2"
                  lg="2"
                  md="2"
                  style={{
                    borderRight: "solid 1px #545454",
                    paddingBottom: "1%",
                    paddingTop: "1%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                    }}
                  >
                    <label
                      style={{
                        fontSize: "16px",
                        color: neturalBlack,
                      }}
                    >
                      {list.couponCode}
                    </label>
                  </div>
                </Col>
                <Col
                  xl="2"
                  lg="2"
                  md="2"
                  style={{
                    borderRight: "solid 1px #545454",
                    paddingBottom: "1%",
                    paddingTop: "1%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                    }}
                  >
                    <label
                      style={{
                        fontSize: "16px",
                        color: neturalBlack,
                      }}
                    >
                      {list.couponDiscount}
                      {list.type === "percentage" ? " %" : " $"}
                    </label>
                  </div>
                </Col>
                <Col
                  xl="2"
                  lg="2"
                  md="2"
                  style={{
                    borderRight: "solid 1px #545454",
                    paddingBottom: "1%",
                    paddingTop: "1%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                    }}
                  >
                    <label
                      style={{
                        fontSize: "16px",
                        color: neturalBlack,
                      }}
                    >
                      {list.discountedAmount} $
                    </label>
                  </div>
                </Col>
                <Col
                  xl="2"
                  lg="2"
                  md="2"
                  style={{
                    paddingBottom: "1%",
                    paddingTop: "1%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                    }}
                  >
                    <label
                      style={{
                        fontSize: "16px",
                        color: neturalBlack,
                      }}
                    >
                      {list.orderDate}
                    </label>
                  </div>
                </Col>
              </Col>
            );
          })} */}
        </Row>
      </div>
    </div>
  );
};
export default Admin;
