import { BsPlus, BsTrash3 } from "react-icons/bs";
import { primaryOrange } from "../../constants/color";
import ProductVariantDropDownComponent from "../product-variant-dropdown/product-variant-dropdown.component";
import { Col, Form, InputGroup } from "react-bootstrap";
import { Fragment } from "react";

const ProductVariantEditInput = ({
  variants,
  editIndex,
  isEdit,
  variant,
  handleSelectChange,
  handleInputChange,
  handleDeleteOption,
  handleAddOption,
}) => {
  return (
    <Fragment>
      <ProductVariantDropDownComponent
        variants={variants}
        editIndex={editIndex}
        handleSelectChange={handleSelectChange}
        isEdit={isEdit}
      />
      {variant.variantOptions.map((value, index) => (
        <div style={{ display: "flex", marginTop: "3%" }} key={index}>
          <Col md="12" lg="12" style={{ color: "#A6A6A6" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Form.Group
                controlId="validationCustom01"
                style={{ color: "#A6A6A6", width: "70%" }}
              >
                <Form.Label>Option {index + 1}</Form.Label>
                <InputGroup>
                  <Form.Control
                    required
                    type="text"
                    placeholder="Enter a value"
                    value={value}
                    onChange={(e) =>
                      handleInputChange(e, variant.variantId, index)
                    }
                    minLength={4}
                    maxLength={40}
                    isInvalid={value.trim() === ""}
                  />
                  {index !== 0 ? (
                    <BsTrash3
                      size={25}
                      color="#7D7D7D"
                      onClick={() =>
                        handleDeleteOption(variant.variantId, index)
                      }
                    />
                  ) : null}
                </InputGroup>
                {/* Display validation error message */}
                {value.trim() === "" && (
                  <label
                    style={{
                      fontSize: "13px",
                      marginLeft: "1%",
                      color: "#dc3545",
                      fontWeight: "400",
                    }}
                  >
                    Option cannot be empty.
                  </label>
                )}
              </Form.Group>
            </div>
          </Col>
        </div>
      ))}
      {variant.variantOptions.length < 5 && (
        <div style={{ display: "flex", marginTop: "3%" }}>
          <Col
            md="6"
            lg="6"
            style={{
              color: "#A6A6A6",
              display: "flex",
              alignItems: "center",
            }}
            onClick={() => handleAddOption(variant.variantId)}
          >
            <BsPlus color={primaryOrange} size={25} />
            <label
              style={{
                fontSize: "14px",
                fontWeight: "bold",
                color: primaryOrange,
              }}
            >
              Add option
            </label>
          </Col>
        </div>
      )}
    </Fragment>
  );
};

export default ProductVariantEditInput;
