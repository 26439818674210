import React from 'react';
import { Navigate } from 'react-router-dom';

const AuthRoute = ({ element: Element, ...props }) => {
  let isAuthenticated = localStorage.getItem("isAuthenticated");
  return isAuthenticated ? (
   <Navigate to="/services" replace />
  ) : (
    <Element {...props} />  
  );
};

export default AuthRoute;
