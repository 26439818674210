import { Form, Row, Col } from "react-bootstrap";
import { useEffect, useState } from "react";
import { format } from "date-fns";
import IllustrationImage from "../../components/shared/illustration-image/illustration-image.component";
import FormFooterButtons from "../form-footer-buttons/form-footer-buttons.component";
import SectionTitle from "../../components/section-title/section-title.component";

const ServiceGeneralInfoForm = ({ handleNextClick, setGeneralInfo, formValue }) => {
  const [validated, setValidated] = useState(false);

  const currentDate = new Date();
  const formatedDate = format(currentDate, "yyyy-MM-dd");

  const [name, setName] = useState("");
  const [serviceStartDate, setServiceStartDate] = useState("");
  const [serviceEndDate, setServiceEndDate] = useState("");
  const [serviceDescription, setServiceDescription] = useState("");

  useEffect(() => {
    if (formValue.name && formValue.name !== "") {
      setName(formValue.name);
      setServiceStartDate(formValue.serviceStartDate);
      setServiceEndDate(formValue.serviceEndDate);
      setServiceDescription(formValue.serviceDescription);
    }
  }, []);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);

    if (form.checkValidity() === true) {
      setGeneralInfo(
        name,
        serviceStartDate,
        serviceEndDate,
        serviceDescription
      );
      handleNextClick();
    }
  };

  return (
    <div>
      <div style={{ marginTop: "5%", marginBottom:"3%" }}>
        <SectionTitle title={"General Information"} subtitle={"Fill all fields below"} />
      </div>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Row className="mb-3">
          <Col md="6" lg="6" style={{marginLeft:"3%"}}>
            <Col md="12" lg="12">
              <Form.Group
                controlId="validationCustom01"
                style={{ color: "#A6A6A6" }}
              >
                <Form.Label>Name</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Name"
                  value={name}
                  onChange={(value) => {
                    if (value.target.value.trim().length !== 0) {
                      setName(value.target.value);
                    }else {
                      setName("")
                    }
                  }}
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a valid name.
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <div style={{ display: "flex", marginTop: "20px" }}>
              <Col md="6" style={{ color: "#A6A6A6", paddingRight: "10px" }}>
                <Form.Group controlId="validationCustom01">
                  <Form.Label>Start service date {formatedDate}</Form.Label>
                  <Form.Control
                    required
                    type="date"
                    min={formatedDate}
                    pattern="yyy/mm/dd"
                    placeholder="yyyy/mm/dd"
                    value={serviceStartDate}
                    onChange={(value) => {
                      setServiceStartDate(value.target.value);
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide a valid start service date.
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>

              <Col md="6" style={{ color: "#A6A6A6" }}>
                <Form.Group controlId="validationCustom01">
                  <Form.Label>End service date</Form.Label>
                  <Form.Control
                    required
                    type="date"
                    min={serviceStartDate}
                    pattern="yyy/mm/dd"
                    placeholder="yyyy/mm/dd"
                    value={serviceEndDate}
                    onChange={(value) => {
                      setServiceEndDate(value.target.value);
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide a valid end service date.
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </div>
            <Col md="12" style={{ color: "#A6A6A6", marginTop: "20px" }}>
              <Form.Group controlId="validationCustom01">
                <Form.Label>Service description</Form.Label>
                <Form.Control
                  required
                  as="textarea"
                  rows={7}
                  maxLength={1000}
                  placeholder="Service description"
                  value={serviceDescription}
                  onChange={(value) => {
                    if (value.target.value.trim().length !== 0) {
                      setServiceDescription(value.target.value);
                    }else {
                      setServiceDescription("")
                    }
                  }}
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a valid service description.
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <FormFooterButtons nextButtonText={"Continue"} />
          </Col>
          <Col
            md="5"
            lg="5"
            style={{
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
            }}
          >
           <IllustrationImage/>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default ServiceGeneralInfoForm;
