import { Fragment } from "react";
import { netural400, neturalBlack } from "../../constants/color";

const BookingDetails = ({ orderInfo, address }) => {
  const formatDate = (inputDate) => {
    const options = {
      //   weekday: "short",
      month: "long",
      day: "2-digit",
      year: "numeric",
    };

    const date = new Date(inputDate);
    return date.toLocaleDateString("en-US", options);
  };
  return (
    <div
      style={{
        marginTop: "5%",
        paddingBottom: "4%",
        borderBottom: "1px solid #dadada",
      }}
    >
      <Fragment>
        <label
          style={{
            fontSize: "20px",
            fontWeight: "600",
            color: neturalBlack,
          }}
        >
          Booking details
        </label>
      </Fragment>
      <div
        style={{
          marginTop: "3%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <label
          style={{
            fontSize: "16px",
            fontWeight: "500",
            color: neturalBlack,
          }}
        >
          Boking ID
        </label>
        <label style={{ fontSize: "16px", color: netural400 }}>
          #{orderInfo.orderId}
        </label>
      </div>

      <div
        style={{
          marginTop: "1%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <label
          style={{
            fontSize: "16px",
            fontWeight: "500",
            color: neturalBlack,
          }}
        >
          Purchase date
        </label>
        <label style={{ fontSize: "16px", color: netural400 }}>
          {formatDate(orderInfo.orderDate)}
        </label>
      </div>

      <div
        style={{
          marginTop: "1%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <label
          style={{
            fontSize: "16px",
            fontWeight: "500",
            color: neturalBlack,
          }}
        >
          Service date
        </label>
        <label style={{ fontSize: "16px", color: netural400 }}>
          {orderInfo.scheduleDate}
        </label>
      </div>

      <div
        style={{
          marginTop: "1%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <label
          style={{
            fontSize: "16px",
            fontWeight: "500",
            color: neturalBlack,
          }}
        >
          Service method
        </label>
        <label style={{ fontSize: "16px", color: netural400 }}>
          {orderInfo.serviceDuration} - {orderInfo.serviceMethod}
        </label>
      </div>

      <div
        style={{
          marginTop: "1%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <label
          style={{
            fontSize: "16px",
            fontWeight: "500",
            color: neturalBlack,
          }}
        >
          Service address
        </label>
        <label
          style={{
            fontSize: "16px",
            color: netural400,
            width: "30%",
            textAlign: "right",
          }}
        >
          {address.streetName} <br></br> {address.cityName}{" "}
          {address.stateAndZipCode} <br></br> {address.country}
        </label>
      </div>
    </div>
  );
};

export default BookingDetails;
