export const setSales = (salesData) => {
  return {
    type: "SET_SALES",
    payload: salesData,
  };
};

export const clearSales = () => {
  return {
    type: "CLEAR_SALES",
  };
};
