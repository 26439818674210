import { Form, Row, Col } from "react-bootstrap";
import { useState, useEffect } from "react";
import IllustrationImage from "../shared/illustration-image/illustration-image.component";
import FormFooterButtons from "../../components/form-footer-buttons/form-footer-buttons.component";
import SectionTitle from "../section-title/section-title.component";

const ServicePricingForm = ({
  handleBackClick,
  handleNextClick,
  setServicePricing,
  formValue,
}) => {
  const [validated, setValidated] = useState(false);
  const [servicePrice, setServicePrice] = useState("");
  const [serviceCurrency, setServiceCurrency] = useState("USD");
  const [serviceDiscount, setServiceDiscount] = useState("");

  useEffect(() => {
    if (formValue.servicePrice && formValue.servicePrice !== "") {
      setServicePrice(formValue.servicePrice);
      setServiceCurrency(formValue.serviceCurrency);
      setServiceDiscount(formValue.serviceDiscount);
    }
  }, []);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);

    if (form.checkValidity() === true) {
      setServicePricing(servicePrice, serviceCurrency, serviceDiscount);
      handleNextClick();
    }
  };

  return (
    <div>
     <div style={{ marginTop: "5%", marginBottom: "3%" }}>
        <SectionTitle title={"Price Information"} subtitle={"Fill all fields below"} />
      </div>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Row className="mb-3">
          <Col md="6" lg="6" style={{marginLeft:"3%"}}>
            <Col md="12" lg="12">
              <Form.Group
                controlId="validationCustom01"
                style={{ color: "#A6A6A6" }}
              >
                <Form.Label>Price per service</Form.Label>
                <Form.Control
                  required
                  type="number"
                  placeholder="Price per service"
                  style={{ borderRadius: "12px" }}
                  value={servicePrice}
                  onChange={(value) => {
                    if(value.target.value.match("^[0-9 ]") != null) {
                      setServicePrice(value.target.value);
                    }else {
                      setServicePrice("")
                    }
                  }}
                  step={"0.01"}
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a valid Price.
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <div style={{ display: "flex", marginTop: "20px" }}>
              <Col md="12" lg="12">
                <Form.Group
                  controlId="validationCustom01"
                  style={{ color: "#A6A6A6" }}
                >
                  <Form.Label>Currency</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    style={{ borderRadius: "12px" }}
                    onChange={(value) => {
                      setServiceCurrency(value.target.value)
                    }}
                  >
                    {/* <option selected={serviceCurrency === "CAD" ? true : false} value="CAD">CAD Canadian Dollar</option> */}
                    <option selected={serviceCurrency === "USD" ? true : false} value="USD">USD</option>
                    {/* <option selected={serviceCurrency === "INR" ? true : false} value="INR">INR</option> */}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    Please provide a valid currency.
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </div>
            <div style={{ display: "flex", marginTop: "20px" }}>
              <Col md="12" lg="12">
                <Form.Group
                  controlId="validationCustom01"
                  style={{ color: "#A6A6A6" }}
                >
                  <Form.Label>Discount</Form.Label>
                  <Form.Control
                    required
                    type="number"
                    placeholder="Discount"
                    style={{ borderRadius: "12px" }}
                    value={serviceDiscount}
                    onChange={(event) => {
                      const newValue = event.target.value;
                      if (newValue === "" || (newValue >= 0 && newValue <= 100)) {
                        setServiceDiscount(newValue);
                      }
                    }}
                    step={"0.01"}
                  />
                 <Form.Control.Feedback type="invalid">
                  {serviceDiscount < 0 || serviceDiscount > 100
                    ? "Discount must be between 0 and 100"
                    : "Please provide a valid Discount."}
                </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </div>
           <FormFooterButtons displayPreviousButton={true} previousButtonText={"Back"} handlePreviouButtonClick={handleBackClick} nextButtonText={"Continue"}/>
          </Col>
          <Col
            md="5"
            lg="5"
            style={{
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
            }}
          >
            <IllustrationImage/>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default ServicePricingForm;
