import { gql } from "@apollo/client";

export const LOGIN = gql`
  mutation login($phone: String!, $notificationToken: String, $type: String) {
    login(phone: $phone, notificationToken: $notificationToken, type: $type) {
      _id
      notificationToken
      name
      username
      country
      avatarURL
      businessAccount
      subscription {
        subscriptionType
        subscription_id
        subscriptionDiscount
        stripeSubscriptionId
        subscriptionEnd
        subscriptionEndDate
        downgraded
        rank
        profile
        duration
      }
      admin
      firstLogin
    }
  }
`;

export const LOGIN_CHECK = gql`
  query portalLoginCheck($phone: String!) {
    portalLoginCheck(phone: $phone) {
      completed
      message
    }
  }
`;
