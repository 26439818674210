import "bootstrap/dist/css/bootstrap.css";
import Collapse from "react-bootstrap/Collapse";
import { Card, Col, Row, Form } from "react-bootstrap";
import { React, useState, useEffect, Fragment } from "react";
import {
  netural400,
  neturalBlack,
  primaryGrey,
  primaryOrange,
} from "../../constants/color";
import { useLazyQuery, useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IconContext } from "react-icons";
import { IoOptionsOutline, IoList } from "react-icons/io5";
import { BiWrench } from "react-icons/bi";
import LoadingSpinner from "../shared/loading-spinner/loading-spinner.component";
import { useParams } from "react-router-dom";
import HeaderSection from "../header/header.component";
import { useNavigate } from "react-router-dom";
import {
  CATEGORY_SEARCH,
  EDIT_PRODUCT,
  FETCH_PRODUCT_FOR_EDIT,
  FETCH_SUBCATEGORIES_BY_CATEGORY,
} from "../../services/graphql/product";
import SelectImages from "../select-images/select-images.component";
import CustomButton from "../button/button.component";
import SectionTitle from "../section-title/section-title.component";
import ProductCombinationHeader from "../product-combination-header/product-combination-header.component";
import ProductCombinationCard from "../product-combination-card/product-combination-card.component";
import ProductVariantCardDesign from "../product-variant-card-design/product-variant-card-design.component";
import ProductVariantDropDownComponent from "../product-variant-dropdown/product-variant-dropdown.component";
import ProductVariantCreateInput from "../product-variant-create-input/product-variant-create-input.component";
import { BsPlus } from "react-icons/bs";
import ProductVariantEditInput from "../product-variant-edit-input/product-variant-edit-input.component";
import { variantList } from "../../constants/constant";
import Multiselect from "multiselect-react-dropdown";

const EditProduct = () => {
  const { productId } = useParams();
  const navigate = useNavigate();
  const [openProductDetail, setOpenProductDetail] = useState(false);
  const [openProductVariant, setOpenProductVariant] = useState(false);
  const [openProductVariantCombination, setOpenProductVariantCombination] =
    useState(false);
  const [product, setProduct] = useState({});
  const [productTitle, setProductTitle] = useState("");
  const [isValidproductTitle, setIsValidProductTitle] = useState(false);

  const [productDescription, setProductDescription] = useState("");
  const [isValidproductDescription, setIsValidProductDescription] =
    useState(false);

  const [productDetail, setProductDetail] = useState("");
  const [isValidproductDetail, setIsValidProductDetail] = useState("");

  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubcategories, setSelectedSubcategories] = useState([]);
  const [categoriesList, setCategoriesList] = useState([]);
  const [subcategoriesList, setSubcategoriesList] = useState([]);

  const [variants, setVariants] = useState([]);
  const [variantCombinations, setVariantCombinations] = useState([]);

  const [productPrice, setProductPrice] = useState("");
  const [isValidproductPrice, setIsValidProductPrice] = useState(false);

  const [productRestricedUser, setProductRestricedUser] = useState("0");
  const [productDiscount, setProductDiscount] = useState("");
  const [isValidproductDiscount, setIsValidProductDiscount] = useState(false);

  const [productQuantity, setProductQuantity] = useState("");
  const [isValidproductQuantity, setIsValidProductQuantity] = useState(false);

  const [productFinalPrice, setProductFinalPrice] = useState("00.00");
  const [productSKU, setProductSKU] = useState("");
  const [isValidproductSKU, setIsValidProductSKU] = useState(false);

  const [images, setImages] = useState([]);
  const [isImagesValid, setIsImagesValid] = useState(false);

  const [isFetchingProduct, setIsFetchingProduct] = useState(true);

  // variables for variants
  const [isAddingOptions, setIsAddingOptions] = useState(false);
  const [isValidate, setIsValidate] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editIndex, setEditIndex] = useState(0);
  const [addVariant, setAddVariant] = useState(true);
  const [displayLength, setDisplayLength] = useState(0);
  const [displayVariantCard, setIsDisplayVariantCard] = useState(false);
  const [validationMessages, setValidationMessages] = useState([]);
  const [isVariantUpdated, setIsVariantUpdated] = useState(false);
  const [isSubcategories, setIsSubcategories] = useState(false);

  useEffect(() => {
    getProductData(productId);
  }, [productId]);

  useEffect(() => {
    if (variants.length === 0) {
      setVariantCombinations([]);
    }
  }, [variants]);

  useEffect(() => {
    if (productPrice || productDiscount) {
      const discountPrice = (productPrice * productDiscount) / 100;
      let total = productPrice - discountPrice;
      setProductFinalPrice(total.toFixed(2));
    } else {
      setProductFinalPrice("0.00");
    }
  }, [productPrice, productDiscount]);

  const getFormatedProductRestriction = (userRestriction) => {
    switch (userRestriction) {
      case "1":
        return "Once";
      case "2":
        return "Twice";
      case "3":
        return "Thrice";
      case "4":
        return "Four times";
      case "5":
        return "Five times";
      default:
        return "Select any one if required";
    }
  };

  useEffect(() => {
    categorySearch({
      variables: {
        query: "",
      },
    });
  }, []);

  const [categorySearch, { loading: loadingCategoryList }] = useLazyQuery(
    CATEGORY_SEARCH,
    {
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        if (data && data.categorySearch) {
          let listOfCategory = data.categorySearch;
          setCategoriesList(listOfCategory);
        } else {
        }
      },
    }
  );

  const [fetchSubCategoriesByCategoryId] = useLazyQuery(
    FETCH_SUBCATEGORIES_BY_CATEGORY,
    {
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        // console.log("Value---------------",data.fetchSubCategoriesByCategoryId)
        if (data && data.fetchSubCategoriesByCategoryId.length > 0) {
          let listOfSubCategory = data.fetchSubCategoriesByCategoryId;
          setSubcategoriesList(listOfSubCategory);
          setIsSubcategories(true);
        } else {
          setIsSubcategories(false);
        }
      },
    }
  );

  const [fetchProductForEdit] = useLazyQuery(FETCH_PRODUCT_FOR_EDIT, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      let fetchProduct = data.fetchProductForEdit;
      if (data && fetchProduct) {
        console.log(fetchProduct);
        const {
          title,
          description,
          productDetails,
          categories,
          quantity,
          quantityLimitation,
          price,
          discount,
          sku,
          finalTotal,
          variants,
          variantCombinations,
          medias,
        } = fetchProduct;
        // console.log("Value------------------",fetchProduct)
        const dbCategory = [
          {
            _id: categories[0]._id,
            name: categories[0].name,
            subCategories:
              categories && categories[0].subCategories.length > 0
                ? categories[0].subCategories
                : [],
          },
        ];
        setProduct(fetchProduct);
        setProductTitle(title);
        setProductDescription(description);
        setProductDetail(productDetails);
        setSelectedCategory(dbCategory);
        setSelectedSubcategories(categories[0].subCategories);
        if (categories && categories.length > 0) {
          fetchSubCategoriesByCategoryId({
            variables: {
              categoryId: categories[0]._id,
            },
          });
        }

        setProductQuantity(quantity);
        setProductRestricedUser(
          getFormatedProductRestriction(quantityLimitation)
        );
        setProductPrice(price);
        setProductDiscount(discount);
        setProductSKU(sku);
        setProductFinalPrice(finalTotal);
        setVariants(
          variants.map((variant) => {
            delete variant.__typename;
            return variant;
          })
        );
        setVariantCombinations(
          variantCombinations.map((variantCombination) => {
            delete variantCombination.__typename;
            return variantCombination;
          })
        );

        setIsDisplayVariantCard(true);
        setIsValidate(true);
        setDisplayLength(variants.length);
        setAddVariant(false);

        if (medias?.length > 0) {
          setImages(medias.map(({ __typename, ...rest }) => rest));
        }
      }
      setIsFetchingProduct(false);
    },
    onError: (error) => {
      toast.error(error.message, {
        style: { width: "450px" },
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored ",
      });
      setIsFetchingProduct(false);
      // NAVIAGATE TO PAGE NOT FOUND ROUTE,
      navigate("/products");
    },
  });

  const getProductData = async (productId) => {
    await fetchProductForEdit({
      variables: {
        productId,
      },
    });
  };

  const handleOnChange = async (selectedList, type) => {
    if (type === "category") {
      if (selectedList && selectedList[0].subCategories.length > 0) {
        let selectedItemList = [
          {
            _id: selectedList[0]._id,
            name: selectedList[0].name,
          },
        ];
        setSelectedCategory(selectedItemList);
        await fetchSubCategoriesByCategoryId({
          variables: {
            categoryId: selectedList[0]._id,
          },
        });
        setIsSubcategories(true);
      } else {
        let selectedItemList = [
          {
            _id: selectedList[0]._id,
            name: selectedList[0].name,
          },
        ];
        setSelectedCategory(selectedItemList);
        setIsSubcategories(false);
      }
    } else {
      let removedList = [
        {
          _id: selectedCategory[0]._id,
          name: selectedCategory[0].name,
          subCategories: selectedList,
        },
      ];
      setSelectedCategory(removedList);
      setSelectedSubcategories(selectedList);
    }
  };

  const handleOnRemove = (selectedList, type) => {
    if (type === "category") {
      if (selectedList.length === 0) {
        setSelectedCategory([]);
        setIsSubcategories(false);
        // Clear the selected sub-category list when the category is removed
        setSelectedSubcategories([]);
      } else {
        setSelectedCategory(selectedList);
        setIsSubcategories(true);
      }
    } else {
      let removedList = [
        {
          _id: selectedCategory[0]._id,
          name: selectedCategory[0].name,
          subCategories: selectedList,
        },
      ];
      setSelectedCategory(removedList);
      setSelectedSubcategories(selectedList); // Update the selected sub-categories list
    }
  };

  const [editProduct] = useMutation(EDIT_PRODUCT, {
    onCompleted: (data) => {
      setOpenProductDetail(!openProductDetail);
      toast.success(data.editProduct.message, {
        style: { width: "450px" },
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored ",
      });
      navigate("/products");
    },
    onError: (error) => {
    //  console.log(error, "ERROR==============");
      toast.error(error.message, {
        style: { width: "450px" },
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored ",
      });
    },
  });

  const handleSubmit = async () => {
    if (
      (productTitle && productTitle === "") ||
      productTitle.length < 4 ||
      productTitle.length > 100
    ) {
      if (!openProductDetail) invertProductDetailsSection();
      setIsValidProductTitle(true);
    } else if (selectedCategory && selectedCategory.length === 0) {
      // errors.selectedCategory = "Please select any one category";
    } else if (
      (productDescription && productDescription === "") ||
      productDescription.length < 50 ||
      productDescription.length > 3000
    ) {
      if (!openProductDetail) invertProductDetailsSection();
      setIsValidProductDescription(true);
    } else if (
      (productDetail && productDetail === "") ||
      productDetail.length < 50 ||
      productDetail.length > 3000
    ) {
      if (!openProductDetail) invertProductDetailsSection();
      setIsValidProductDetail(true);
    } else if (
      ((productQuantity && productQuantity === "") ||
        productQuantity === "0") &&
      variants?.length === 0
    ) {
      if (!openProductDetail) invertProductDetailsSection();
      setIsValidProductQuantity(true);
    } else if (productPrice && productPrice === "" && variants?.length === 0) {
      if (!openProductDetail) invertProductDetailsSection();
      setIsValidProductPrice(true);
    } else if (
      productDiscount &&
      productDiscount === "" &&
      variants?.length === 0
    ) {
      if (!openProductDetail) invertProductDetailsSection();
      setIsValidProductDiscount(true);
    } else if (productSKU && productSKU === "" && variants?.length === 0) {
      if (!openProductDetail) invertProductDetailsSection();
      setIsValidProductSKU(true);
    } else if (images && images.length === 0) {
      if (!openProductDetail) invertProductDetailsSection();
      setIsImagesValid(true);
    } else {
      let medias = [];
      images.map((imageValue, index) => {
        if (imageValue.height) {
          medias.push({
            ...imageValue,
            key: index,
          });
        } else {
          let media = {
            height: 720,
            width: 1080,
            mediaType: "photo",
            key: index,
            uri: "file://",
            file: imageValue.file,
          };
          medias.push(media);
        }
      });

      // Validate the data
      const isInvalid = variantCombinations.some(
        (variantCombination, index) => {
          const messages = [];

          if (!variantCombination.price.match(/^[0-9 ]/)) {
            messages.push("Price is required");
          }

          if (!variantCombination.discount.match(/^[0-9 ]/)) {
            messages.push("Discount is required");
          }

          if (!variantCombination.quantity.match(/^[0-9 ]/)) {
            messages.push("Quantity is required");
          }

          if (variantCombination.sku === "") {
            messages.push("SKU cannot be empty");
          } else if (variantCombination.sku.length < 3) {
            messages.push("SKU must be at least 3 characters long");
          }

          // Add more conditions for other fields if needed
          // ...

          if (variantCombination.quantityLimit === "") {
            messages.push("Quantity Limit cannot be empty");
          }

          // Update validation messages for the current variant
          setValidationMessages((prevMessages) => {
            const newMessages = [...prevMessages];
            newMessages[index] = messages.join(", ");
            return newMessages;
          });

          return messages.length > 0;
        }
      );

      if (isInvalid) {
        // If there are validation errors, do not proceed with saving data
        return;
      }
      let formattedVariantCombination = [];
      if (variants.length > 0 && variantCombinations.length > 0) {
        formattedVariantCombination = variantCombinations.map((combination) => {
          return {
            combination: combination.combination,
            sku: combination.sku,
            price: Number(parseFloat(combination.price).toFixed(2)),
            finalTotal: Number(parseFloat(combination.finalTotal).toFixed(2)),
            discount: Number(parseFloat(combination.discount).toFixed(2)),
            quantity: parseInt(combination.quantity),
            quantityLimit: combination.quantityLimitation,
          };
        });
      }
      let finalCategoryList = {
        _id: selectedCategory[0]._id,
        name: selectedCategory[0].name,
        subCategories:
          selectedCategory[0].subCategories &&
          selectedCategory[0].subCategories.length > 0
            ? selectedCategory[0].subCategories
            : [],
      };
      if (finalCategoryList.subCategories.length > 0) {
        await finalCategoryList.subCategories.map((category) => {
          delete category.__typename;
          delete category.isChecked;
        });
      }
      await editProduct({
        variables: {
          categories: [finalCategoryList],
          editProductInput: {
            productId: product._id,
            title: productTitle,
            medias: medias,
            description: productDescription,
            sku: productSKU,
            price: productPrice,
            discount: productDiscount,
            serviceType: "Personal",
            productDetails: productDetail,
            quantity: productQuantity,
            variants,
            variantCombinations: formattedVariantCombination,
          },
        },
      });
    }
  };

  const handleSelectChange = (event) => {
    setIsValidate(false);
    setIsAddingOptions(true);
    setVariants((prevVariants) => {
      // Check if the selected variant is being edited
      if (isEdit) {
        return prevVariants.map((variant, index) =>
          index === editIndex
            ? {
                ...variant,
                variantId: variantList.find(
                  (option) => option.name === event.target.value
                ).id,
                variantType: event.target.value,
              }
            : variant
        );
      } else {
        // Check if the maximum limit of 2 variants has been reached
        if (displayLength <= 1) {
          // Check if the variant at currentIndex exists
          const existingVariant = prevVariants[displayLength];
          console.log(existingVariant, "SADASDADASDAS");
          if (existingVariant) {
            // Variant at currentIndex exists, update its variantId and variantType
            return prevVariants.map((variant, index) =>
              index === displayLength
                ? {
                    ...variant,
                    variantId: variantList.find(
                      (option) => option.name === event.target.value
                    ).id,
                    variantType: event.target.value,
                  }
                : variant
            );
          } else {
            // Variant at currentIndex doesn't exist, add a new variant to the specified index
            return [
              ...prevVariants,
              {
                variantId: variantList.find(
                  (option) => option.name === event.target.value
                ).id,
                variantType: event.target.value,
                variantOptions: [""],
              },
            ];
          }
        } else {
          return prevVariants;
        }
      }
    });
  };

  const handleInputChange = (event, variantId, index) => {
    setVariants((prevVariants) =>
      prevVariants.map((variant) =>
        variant.variantId === variantId
          ? {
              ...variant,
              variantOptions: variant.variantOptions.map((option, i) =>
                i === index ? event.target.value : option
              ),
            }
          : variant
      )
    );
  };

  const handleDeleteVariant = (variantId) => {
    setEditIndex(0);
    setIsValidate(false);
    setIsVariantUpdated(true);
    setDisplayLength((prevLength) => prevLength - 1);
    setVariants((prevVariants) =>
      prevVariants.filter((variant) => variant.variantId !== variantId)
    );
  };

  const handleDeleteOption = (variantId, index) => {
    setVariants((prevVariants) =>
      prevVariants.map((variant) =>
        variant.variantId === variantId
          ? {
              ...variant,
              variantOptions: variant.variantOptions.filter(
                (_, i) => i !== index
              ),
            }
          : variant
      )
    );
  };

  const handleAddOption = (variantId) => {
    setVariants((prevVariants) =>
      prevVariants.map((variant) =>
        variant.variantId === variantId
          ? {
              ...variant,
              variantOptions: [...variant.variantOptions, ""],
            }
          : variant
      )
    );
  };

  // Function to generate combinations
  function generateCombinations(
    variant,
    index = 0,
    currentCombination = "",
    combinations = []
  ) {
    if (index === variant.length) {
      // If we've reached the end of the variants array, add the current combination to the result
      let variantCombination = {
        combination: currentCombination,
        price: "",
        discount: "",
        quantity: "",
        quantityLimit: 0,
        sku: "",
      };
      combinations.push(variantCombination);
      return;
    }

    // Iterate over the current variant's options
    for (let i = 0; i < variants[index].variantOptions.length; i++) {
      // Add the current option to the combination
      const separator = currentCombination.length === 0 ? "" : "/";
      const updatedCombination =
        currentCombination + separator + variants[index].variantOptions[i];

      // Recursively call the function for the next index
      generateCombinations(
        variants,
        index + 1,
        updatedCombination,
        combinations
      );
    }
  }

  const updateVariantCombinations = () => {
    let combinations = [];
    generateCombinations(variants, 0, "", combinations);
    setVariantCombinations(combinations);
  };

  const handleSaveData = () => {
    // Check for empty option input fields
    const hasEmptyOptions = variants.some((variant) =>
      variant.variantOptions.some((option) => option.trim() === "")
    );

    if (hasEmptyOptions || variants.length === 0) {
      // Handle the case where there are empty option input fields
      return;
    }
    setIsValidate(true);
    setIsEdit(false);
    setAddVariant(false);
    setIsAddingOptions(false);
    setIsVariantUpdated(false);

    updateVariantCombinations();

    if (!isEdit) {
      setDisplayLength((prevLength) => prevLength + 1);
    }
    if (isValidate) {
      setVariants(variants);
      setOpenProductVariant(!openProductVariant);
      setOpenProductVariantCombination(!openProductVariantCombination);
    } else {
      setIsDisplayVariantCard(true);
    }
  };

  const handleEdit = (variantNo) => {
    setIsEdit(true);
    setEditIndex(variantNo);
    setIsValidate(false);
  };

  const onChange = (imageList) => {
    if (imageList.length > 0) {
      setIsImagesValid(false);
    } else {
      setIsImagesValid(true);
    }
    setImages(imageList);
  };

  const VariantDisplay = ({ variants }) => {
    if (variants.length === 0) {
      return null; // or any default message you want to display
    }
    const variantTypes = variants
      .map((variant) => variant.variantType)
      .join(", ");
    return (
      <label
        style={{
          fontWeight: "400",
          fontSize: "14px",
          color: netural400,
        }}
      >
        {variantTypes}
      </label>
    );
  };

  const invertProductDetailsSection = () => {
    setOpenProductDetail(!openProductDetail);
    setOpenProductVariant(false);
    setOpenProductVariantCombination(false);
  };

  const invertProductCombinationSection = () => {
    if (isVariantUpdated) {
      updateVariantCombinations();
      setIsVariantUpdated(false);
    }
    setOpenProductVariantCombination(!openProductVariantCombination);
    setOpenProductDetail(false);
    setOpenProductVariant(false);
  };

  const invertProductVariantSection = () => {
    setOpenProductVariant(!openProductVariant);
    setOpenProductDetail(false);
    setOpenProductVariantCombination(false);
  };

  const updateValue = (index, field, value) => {
    setVariantCombinations((prevData) => {
      const newData = [...prevData];
      newData[index][field] = value;
      return newData;
    });
  };
  return (
    <div className="main-div-right-section">
      <div>
        <HeaderSection currentTab={"Product"} />
        {isFetchingProduct ? (
          <div style={{ marginTop: "5%" }}>
            <LoadingSpinner size="medium" overlay={false} />
          </div>
        ) : (
          <div className="second-section-style">
            <SectionTitle title={productTitle} />
            <Card
              style={{
                width: "100%",
                borderTopLeftRadius: "12px",
                borderTopRightRadius: "12px",
              }}
              aria-expanded={openProductDetail}
              aria-controls="collapseID"
              className="shadow"
            >
              <div>
                <Row
                  onClick={() => {
                    if (!openProductDetail) {
                      invertProductDetailsSection();
                    }
                  }}
                >
                  <Col
                    lg="1"
                    md="1"
                    sm="1"
                    xs="1"
                    className="mt-3 mb-3"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    onClick={() => {
                      if (openProductDetail) {
                        invertProductDetailsSection();
                      }
                    }}
                  >
                    <IconContext.Provider value={{ size: 24, color: "black" }}>
                      <IoOptionsOutline />
                    </IconContext.Provider>
                  </Col>
                  <Col
                    lg="8"
                    md="8"
                    sm="8"
                    xs="8"
                    className="mt-3 mb-3"
                    style={{ flexDirection: "column", display: "flex" }}
                    onClick={() => {
                      if (openProductDetail) {
                        invertProductDetailsSection();
                      }
                    }}
                  >
                    <label
                      style={{
                        fontWeight: "500",
                        fontSize: "16px",
                        color: neturalBlack,
                      }}
                    >
                      Product details
                    </label>
                    <label
                      style={{
                        fontWeight: "400",
                        fontSize: "14px",
                        color: netural400,
                      }}
                    >
                      {productTitle}
                    </label>
                  </Col>
                  {openProductDetail ? (
                    <Fragment>
                      <Col
                        lg="1"
                        md="1"
                        sm="1"
                        xs="1"
                        className="mt-3 mb-3"
                        style={{ flexDirection: "column", display: "flex" }}
                      >
                        <CustomButton
                          buttonType="secondary"
                          title={"Cancel"}
                          handleButtonClick={invertProductDetailsSection}
                        />
                      </Col>
                      <Col
                        lg="2"
                        md="2"
                        sm="2"
                        xs="2"
                        className="mt-3 mb-3"
                        style={{ flexDirection: "column", display: "flex" }}
                      >
                        <CustomButton
                          title={"Save and close"}
                          customStyle={{ marginTop: "0px", padding: "8px" }}
                          handleButtonClick={handleSubmit}
                        />
                      </Col>
                    </Fragment>
                  ) : null}
                </Row>
              </div>
            </Card>
            <Collapse in={openProductDetail}>
              <div id="collapseID">
                <Card
                  style={{
                    width: "100%",
                    borderBottomLeftRadius: "12px",
                    borderBottomRightRadius: "12px",
                  }}
                >
                  <div style={{ marginTop: "3%" }}>
                    <SectionTitle
                      title={"General Information"}
                      titleStyle={{ marginLeft: "3%" }}
                    />
                    <div
                      style={{
                        marginLeft: "2%",
                      }}
                    >
                      <Form noValidate validated={false}>
                        <Row className="mb-3">
                          <Col xl="6" md="6" lg="6">
                            <div style={{ display: "flex", marginTop: "20px" }}>
                              <Col
                                xl="12"
                                md="12"
                                lg="12"
                                style={{
                                  color: "#A6A6A6",
                                  paddingRight: "10px",
                                }}
                              >
                                <Form.Group
                                  controlId="validationCustom01"
                                  style={{ color: "#A6A6A6" }}
                                >
                                  <Form.Label>Product title</Form.Label>
                                  <Form.Control
                                    required
                                    type="text"
                                    placeholder="Product title"
                                    value={productTitle}
                                    onChange={(value) => {
                                      setIsValidProductTitle(false);
                                      if (
                                        value.target.value.trim().length !== 0
                                      ) {
                                        setProductTitle(value.target.value);
                                      } else {
                                        setProductTitle("");
                                      }
                                    }}
                                    minLength={4}
                                    maxLength={100}
                                  />
                                  {isValidproductTitle ? (
                                    <label
                                      style={{
                                        fontSize: ".875em",
                                        color: "#dc3545",
                                        display: "flex",
                                        justifyContent: "center",
                                        marginTop: "2%",
                                      }}
                                    >
                                      Please provide a valid productTitle.
                                    </label>
                                  ) : null}
                                </Form.Group>
                              </Col>
                            </div>
                            <div style={{ marginTop: "3%", display: "flex" }}>
                              <Col
                                xl="6"
                                md="6"
                                lg="6"
                                style={{
                                  color: "#A6A6A6",
                                  paddingRight: "10px",
                                }}
                              >
                                <Form.Group
                                  controlId="validationCustom02"
                                  style={{ color: "#A6A6A6" }}
                                >
                                  <Form.Label>Category</Form.Label>
                                  <Multiselect
                                    options={categoriesList} // Options to display in the dropdown
                                    // selectedValues={this.state.selectedValue} // Preselected value to persist in dropdown
                                    onSelect={(selectedList, selectedItem) => {
                                      handleOnChange(selectedList, "category");
                                    }} // Function will trigger on select event
                                    onRemove={(selectedList) => {
                                      handleOnRemove(selectedList, "category");
                                    }} // Function will trigger on remove event
                                    displayValue="name" // Property name to display in the dropdown options
                                    showCheckbox
                                    selectionLimit={1}
                                    selectedValues={selectedCategory}
                                    closeOnSelect={true}
                                    style={{
                                      chips: {
                                        background: primaryOrange,
                                      },
                                    }}
                                  />
                                  {/* {errorFields.selectedCategory && ( */}
                                  <label
                                    style={{
                                      fontSize: "0.875rem",
                                      color: "#dc3545",
                                    }}
                                  >
                                    {/* {errorFields.selectedCategory} */}
                                  </label>
                                  {/* )} */}
                                </Form.Group>
                              </Col>
                              <Col
                                xl="6"
                                md="6"
                                lg="6"
                                style={{
                                  color: "#A6A6A6",
                                  paddingLeft: "10px",
                                }}
                              >
                                <Form.Group
                                  controlId="validationCustom02"
                                  style={{ color: "#A6A6A6" }}
                                >
                                  <Form.Label>Sub-category</Form.Label>
                                  <Multiselect
                                    options={subcategoriesList} // Options to display in the dropdown
                                    // selectedValues={this.state.selectedValue} // Preselected value to persist in dropdown
                                    onSelect={(selectedList) => {
                                      handleOnChange(
                                        selectedList,
                                        "subCategory"
                                      );
                                    }} // Function will trigger on select event
                                    onRemove={(selectedList) => {
                                      handleOnRemove(
                                        selectedList,
                                        "subCategory"
                                      );
                                    }} // Function will trigger on remove event
                                    displayValue="name" // Property name to display in the dropdown options
                                    showCheckbox
                                    selectionLimit={3}
                                    selectedValues={selectedSubcategories}
                                    style={{
                                      chips: {
                                        background: primaryOrange,
                                      },
                                    }}
                                    disable={!isSubcategories}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    Please provide a valid currency.
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </Col>
                            </div>
                            <div style={{ marginTop: "20px" }}>
                              <Col md="12" style={{ color: "#A6A6A6" }}>
                                <Form.Group controlId="validationCustom01">
                                  <Form.Label>Product description</Form.Label>
                                  <Form.Control
                                    required
                                    as="textarea"
                                    rows={5}
                                    placeholder="Product description"
                                    value={productDescription}
                                    onChange={(value) => {
                                      setIsValidProductDescription(false);
                                      if (
                                        value.target.value.trim().length !== 0
                                      ) {
                                        setProductDescription(
                                          value.target.value
                                        );
                                      } else {
                                        setProductDescription("");
                                      }
                                    }}
                                    minLength={50}
                                    maxLength={3000}
                                  />
                                  {isValidproductDescription ? (
                                    <label
                                      style={{
                                        fontSize: ".875em",
                                        color: "#dc3545",
                                        display: "flex",
                                        justifyContent: "center",
                                        marginTop: "2%",
                                      }}
                                    >
                                      Please provide a valid Product
                                      description.
                                    </label>
                                  ) : null}
                                </Form.Group>
                              </Col>
                              <Col md="12" style={{ color: "#A6A6A6" }}>
                                <Form.Group controlId="validationCustom01">
                                  <Form.Label>Product detail</Form.Label>
                                  <Form.Control
                                    required
                                    as="textarea"
                                    rows={5}
                                    placeholder="Product detail"
                                    value={productDetail}
                                    onChange={(value) => {
                                      setIsValidProductDetail(false);
                                      if (
                                        value.target.value.trim().length !== 0
                                      ) {
                                        setProductDetail(value.target.value);
                                      } else {
                                        setProductDetail("");
                                      }
                                    }}
                                    minLength={50}
                                    maxLength={3000}
                                  />
                                  {isValidproductDetail ? (
                                    <label
                                      style={{
                                        fontSize: ".875em",
                                        color: "#dc3545",
                                        display: "flex",
                                        justifyContent: "center",
                                        marginTop: "2%",
                                      }}
                                    >
                                      Please provide a valid Product detail.
                                    </label>
                                  ) : null}
                                </Form.Group>
                              </Col>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </div>
                  </div>
                  <div
                    style={{ height: "1px", backgroundColor: primaryGrey }}
                  ></div>
                  {variants.length === 0 ? (
                    <Fragment>
                      <div style={{ marginTop: "3%", marginBottom: "3%" }}>
                        <SectionTitle
                          title={"Pricing"}
                          titleStyle={{ marginLeft: "3%" }}
                        />
                        <div
                          style={{
                            marginLeft: "2%",
                          }}
                        >
                          <Form noValidate validated={false}>
                            <Row className="mb-3">
                              <Col md="6" lg="6">
                                <div
                                  style={{ display: "flex", marginTop: "20px" }}
                                >
                                  <Col
                                    md="6"
                                    lg="6"
                                    style={{
                                      color: "#A6A6A6",
                                      paddingRight: "10px",
                                    }}
                                  >
                                    <Form.Group
                                      controlId="validationCustom01"
                                      style={{ color: "#A6A6A6" }}
                                    >
                                      <Form.Label>Quantity</Form.Label>
                                      <Form.Control
                                        required
                                        type="text"
                                        inputMode="numeric"
                                        placeholder="Quantity"
                                        style={{ borderRadius: "12px" }}
                                        value={productQuantity}
                                        onChange={(event) => {
                                          setIsValidProductQuantity(false);
                                          const newValue = event.target.value;
                                          const regex = /^[0-9\b]+$/;
                                          if (
                                            (newValue === "" ||
                                              regex.test(newValue)) &&
                                            newValue.length < 7
                                          ) {
                                            setProductQuantity(newValue);
                                          } else {
                                            setProductQuantity(productQuantity);
                                          }
                                        }}
                                        step={"0.01"}
                                      />
                                      {isValidproductQuantity ? (
                                        <label
                                          style={{
                                            fontSize: ".875em",
                                            color: "#dc3545",
                                            display: "flex",
                                            justifyContent: "center",
                                            marginTop: "2%",
                                          }}
                                        >
                                          Please provide a valid Quantity.
                                        </label>
                                      ) : null}
                                    </Form.Group>
                                  </Col>
                                  <Col
                                    md="6"
                                    lg="6"
                                    style={{
                                      color: "#A6A6A6",
                                      paddingLeft: "10px",
                                    }}
                                  >
                                    <Form.Group
                                      controlId="validationCustom01"
                                      style={{ color: "#A6A6A6" }}
                                    >
                                      <Form.Label>
                                        Units restricted per user
                                      </Form.Label>
                                      <Form.Select
                                        aria-label="Default select example"
                                        disabled
                                        style={{ borderRadius: "12px" }}
                                      >
                                        <option
                                          selected
                                          value={productRestricedUser}
                                          disabled
                                        >
                                          {productRestricedUser}
                                        </option>
                                      </Form.Select>
                                    </Form.Group>
                                  </Col>
                                </div>
                                <div
                                  style={{ display: "flex", marginTop: "20px" }}
                                >
                                  <Col
                                    md="6"
                                    lg="6"
                                    style={{
                                      color: "#A6A6A6",
                                      paddingRight: "10px",
                                    }}
                                  >
                                    <Form.Group
                                      controlId="validationCustom01"
                                      style={{ color: "#A6A6A6" }}
                                    >
                                      <Form.Label>Price</Form.Label>
                                      <Form.Control
                                        required
                                        type="text"
                                        inputMode="numeric"
                                        placeholder="55"
                                        style={{ borderRadius: "12px" }}
                                        value={productPrice}
                                        onChange={(value) => {
                                          const newValue =
                                            value.target.value.trim();
                                          // Check if the input is empty and handle backspace/delete key
                                          if (
                                            newValue === "" ||
                                            newValue === "."
                                          ) {
                                            setProductPrice("");
                                          } else if (
                                            /^[0-9]+(\.[0-9]*)?$/.test(newValue)
                                          ) {
                                            setProductPrice(newValue);
                                          } else {
                                            setProductPrice(productPrice);
                                          }
                                        }}
                                        step={"0.01"}
                                        minLength={4}
                                        maxLength={7}
                                      />
                                      <Form.Label
                                        style={{
                                          fontSize: 12,
                                          color: "#7d7d7d",
                                          marginLeft: "4%",
                                        }}
                                      >
                                        Example: C$50.00
                                      </Form.Label>
                                      {isValidproductPrice ? (
                                        <label
                                          style={{
                                            fontSize: ".875em",
                                            color: "#dc3545",
                                            display: "flex",
                                            justifyContent: "center",
                                            marginTop: "2%",
                                          }}
                                        >
                                          Please provide a valid Price.
                                        </label>
                                      ) : null}
                                    </Form.Group>
                                  </Col>
                                  <Col
                                    md="6"
                                    lg="6"
                                    style={{
                                      color: "#A6A6A6",
                                      paddingLeft: "10px",
                                    }}
                                  >
                                    <Form.Group
                                      controlId="validationCustom01"
                                      style={{ color: "#A6A6A6" }}
                                    >
                                      <Form.Label>Discount</Form.Label>
                                      <Form.Control
                                        required
                                        type="text"
                                        inputMode="numeric"
                                        placeholder="Discount"
                                        style={{ borderRadius: "12px" }}
                                        value={productDiscount}
                                        onChange={(event) => {
                                          const newValue =
                                            event.target.value.trim();
                                          // Check if the input is empty and handle backspace/delete key
                                          if (
                                            newValue === "" ||
                                            newValue === "."
                                          ) {
                                            setProductDiscount("");
                                          } else if (
                                            /^[0-9]+(\.[0-9]*)?$/.test(
                                              newValue
                                            ) &&
                                            parseFloat(newValue) >= 0 &&
                                            parseFloat(newValue) <= 100
                                          ) {
                                            setProductDiscount(newValue);
                                          } else {
                                            setProductDiscount(productDiscount);
                                          }
                                        }}
                                        step="0.01"
                                        minLength={1}
                                        maxLength={5}
                                      />
                                      <Form.Label
                                        style={{
                                          fontSize: 12,
                                          color: "#7d7d7d",
                                          marginLeft: "4%",
                                        }}
                                      >
                                        Example: 50 %
                                      </Form.Label>
                                      {isValidproductDiscount ? (
                                        <label
                                          style={{
                                            fontSize: ".875em",
                                            color: "#dc3545",
                                            display: "flex",
                                            justifyContent: "center",
                                            marginTop: "2%",
                                          }}
                                        >
                                          Please provide a valid discount.
                                        </label>
                                      ) : null}
                                    </Form.Group>
                                  </Col>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    marginTop: "20px",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Col
                                    md="6"
                                    lg="6"
                                    style={{
                                      color: "#A6A6A6",
                                      paddingRight: "10px",
                                    }}
                                  >
                                    <Form.Group
                                      controlId="validationCustom01"
                                      style={{ color: "#A6A6A6" }}
                                    >
                                      <Form.Label>SKU</Form.Label>
                                      <Form.Control
                                        required
                                        type="text"
                                        placeholder="SKU"
                                        style={{ borderRadius: "12px" }}
                                        value={productSKU}
                                        onChange={(event) => {
                                          const newValue = event.target.value;

                                          // Use a regular expression to check if the entered value contains only digits and characters
                                          const regex = /^[a-zA-Z0-9]*$/;
                                          if (
                                            regex.test(newValue) ||
                                            newValue === ""
                                          ) {
                                            setProductSKU(newValue);
                                          } else {
                                            setProductSKU(productSKU);
                                          }
                                        }}
                                        step={"0.01"}
                                        minLength={3}
                                        maxLength={15}
                                      />
                                      {isValidproductSKU ? (
                                        <label
                                          style={{
                                            fontSize: ".875em",
                                            color: "#dc3545",
                                            display: "flex",
                                            justifyContent: "center",
                                            marginTop: "2%",
                                          }}
                                        >
                                          Please provide a valid SKU.
                                        </label>
                                      ) : null}
                                    </Form.Group>
                                  </Col>
                                </div>
                                <div
                                  style={{ display: "flex", marginTop: "20px" }}
                                >
                                  <Col
                                    md="6"
                                    lg="6"
                                    style={{
                                      color: "#A6A6A6",
                                      paddingRight: "10px",
                                      display: "flex",
                                    }}
                                  >
                                    <Col md="4" lg="4">
                                      <Form.Group
                                        controlId="validationCustom01"
                                        style={{
                                          color: "#A6A6A6",
                                          display: "flex",
                                        }}
                                      >
                                        <Form.Label
                                          style={{
                                            fontSize: 16,
                                            fontWeight: "bold",
                                            color: neturalBlack,
                                          }}
                                        >
                                          Final price
                                        </Form.Label>
                                      </Form.Group>
                                    </Col>
                                    <Col
                                      md="8"
                                      lg="8"
                                      style={{
                                        display: "flex",
                                        justifyContent: "right",
                                      }}
                                    >
                                      <Form.Group
                                        controlId="validationCustom01"
                                        style={{
                                          color: "#A6A6A6",
                                          display: "flex",
                                        }}
                                      >
                                        <Form.Label
                                          style={{
                                            fontSize: 16,
                                            fontWeight: "bold",
                                            color: neturalBlack,
                                          }}
                                        >
                                          $ {productFinalPrice}
                                        </Form.Label>
                                      </Form.Group>
                                    </Col>
                                  </Col>
                                </div>
                              </Col>
                            </Row>
                          </Form>
                        </div>
                      </div>
                      <div
                        style={{ height: "1px", backgroundColor: primaryGrey }}
                      ></div>
                    </Fragment>
                  ) : null}
                  <div style={{ marginTop: "3%", marginBottom: "3%" }}>
                    <SectionTitle
                      title={"Product images"}
                      subtitle={"Upload up to 7 images"}
                      titleStyle={{ marginLeft: "3%" }}
                    />
                    <div
                      style={{
                        marginLeft: "2%",
                      }}
                    >
                      <Row className="mb-3">
                        <Col md="12" lg="6" sm="12" xs="12">
                          <Col md="12" lg="12" sm="12" xs="12">
                            <SelectImages
                              images={images}
                              onChange={onChange}
                              isGeneralError={isImagesValid}
                            />
                          </Col>
                        </Col>
                      </Row>
                    </div>
                  </div>
                  <div
                    style={{ height: "1px", backgroundColor: primaryGrey }}
                  ></div>
                  <div>
                    <Row style={{ justifyContent: "end" }}>
                      <Col
                        lg="1"
                        md="1"
                        sm="1"
                        xs="1"
                        className="mt-3 mb-3"
                        style={{ flexDirection: "column", display: "flex" }}
                      >
                        <CustomButton
                          buttonType="secondary"
                          title={"Cancel"}
                          handleButtonClick={invertProductDetailsSection}
                        />
                      </Col>
                      <Col
                        lg="2"
                        md="2"
                        sm="2"
                        xs="2"
                        className="mt-3 mb-3"
                        style={{ flexDirection: "column", display: "flex" }}
                      >
                        <CustomButton
                          title={"Save and close"}
                          customStyle={{ marginTop: "0px", padding: "8px" }}
                          handleButtonClick={handleSubmit}
                        />
                      </Col>
                    </Row>
                  </div>
                </Card>
              </div>
            </Collapse>
            <Card
              style={{
                width: "100%",
                borderTopLeftRadius: "12px",
                borderTopRightRadius: "12px",
                marginTop: "1%",
              }}
              aria-expanded={openProductVariant}
              aria-controls="collapseID"
              className="shadow"
            >
              <div>
                <Row
                  onClick={() => {
                    if (!openProductVariant) {
                      invertProductVariantSection();
                    }
                  }}
                >
                  <Col
                    lg="1"
                    md="1"
                    sm="1"
                    xs="1"
                    className="mt-3 mb-3"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    onClick={() => {
                      if (openProductVariant) {
                        invertProductVariantSection();
                      }
                    }}
                  >
                    <IconContext.Provider value={{ size: 24, color: "black" }}>
                      <IoList />
                    </IconContext.Provider>
                  </Col>
                  <Col
                    lg="8"
                    md="8"
                    sm="8"
                    xs="8"
                    className="mt-3 mb-3"
                    style={{ flexDirection: "column", display: "flex" }}
                    onClick={() => {
                      if (openProductVariant) {
                        invertProductVariantSection();
                      }
                    }}
                  >
                    <label
                      style={{
                        fontWeight: "500",
                        fontSize: "16px",
                        color: neturalBlack,
                      }}
                    >
                      Variants
                    </label>
                    <VariantDisplay variants={variants} />
                  </Col>
                  {openProductVariant ? (
                    <Fragment>
                      <Col
                        lg="1"
                        md="1"
                        sm="1"
                        xs="1"
                        className="mt-3 mb-3"
                        style={{ flexDirection: "column", display: "flex" }}
                      >
                        <CustomButton
                          buttonType="secondary"
                          title={"Cancel"}
                          handleButtonClick={invertProductVariantSection}
                        />
                      </Col>
                      <Col
                        lg="2"
                        md="2"
                        sm="2"
                        xs="2"
                        className="mt-3 mb-3"
                        style={{ flexDirection: "column", display: "flex" }}
                      >
                        <CustomButton
                          title={
                            (addVariant && isAddingOptions && !isEdit) || isEdit
                              ? "Save"
                              : "Next"
                          }
                          customStyle={{
                            marginTop: "0px",
                            padding: "8px",
                            fontWeight: "700",
                          }}
                          handleButtonClick={handleSaveData}
                        />
                      </Col>
                    </Fragment>
                  ) : null}
                </Row>
              </div>
            </Card>
            <Collapse in={openProductVariant}>
              <div id="collapseID">
                <Card
                  style={{
                    width: "100%",
                    borderBottomLeftRadius: "12px",
                    borderBottomRightRadius: "12px",
                  }}
                >
                  <div
                    style={{
                      marginTop: "3%",
                      marginLeft: "2%",
                      marginBottom: "3%",
                    }}
                  >
                    <Col md="6" lg="6">
                      {displayVariantCard
                        ? isEdit
                          ? variants
                              .filter(
                                (_, idx) =>
                                  editIndex !== idx && idx < displayLength
                              )
                              .map((variant, index) => (
                                <div key={index}>
                                  <ProductVariantCardDesign
                                    key={index}
                                    variant={variant}
                                    index={index}
                                    handleEdit={handleEdit}
                                    handleDeleteVariant={handleDeleteVariant}
                                    addVariant={addVariant}
                                    isEdit={isEdit}
                                  />
                                </div>
                              ))
                          : variants
                              .filter((_, idx) => idx < displayLength)
                              .map((variant, index) => (
                                // variant.variantOptions[0] !== "" ? (
                                // ) : null
                                <div key={index}>
                                  <ProductVariantCardDesign
                                    key={index}
                                    variant={variant}
                                    index={index}
                                    handleEdit={handleEdit}
                                    handleDeleteVariant={handleDeleteVariant}
                                    addVariant={addVariant}
                                    isEdit={isEdit}
                                  />
                                </div>
                              ))
                        : null}
                      {addVariant && (
                        <ProductVariantDropDownComponent
                          variants={variants}
                          editIndex={editIndex}
                          handleSelectChange={handleSelectChange}
                          isEdit={isEdit}
                        />
                      )}
                      {
                        addVariant && isAddingOptions && !isEdit && (
                          <ProductVariantCreateInput
                            variant={variants[variants.length - 1]}
                            handleInputChange={handleInputChange}
                            handleAddOption={handleAddOption}
                            handleDeleteOption={handleDeleteOption}
                          />
                        )
                        // renderInputFields(variants[variants.length - 1])
                      }
                      {
                        isEdit && (
                          <ProductVariantEditInput
                            variant={variants[editIndex]}
                            editIndex={editIndex}
                            isEdit={isEdit}
                            variants={variants}
                            handleSelectChange={handleSelectChange}
                            handleInputChange={handleInputChange}
                            handleDeleteOption={handleDeleteOption}
                            handleAddOption={handleAddOption}
                          />
                        )
                        // <div>{selectVariantDropDown(variants[editIndex])}</div>
                      }
                      {!addVariant && !isEdit && variants.length < 2 && (
                        <div
                          style={{
                            display: "flex",
                            marginTop: "3%",
                            marginBottom: "3%",
                          }}
                        >
                          <Col
                            md="6"
                            lg="6"
                            style={{
                              color: "#A6A6A6",
                              display: "flex",
                              alignItems: "center",
                            }}
                            onClick={() => {
                              setAddVariant(true);
                            }}
                          >
                            <BsPlus color={primaryOrange} size={25} />
                            <label
                              style={{
                                fontSize: "14px",
                                fontWeight: "bold",
                                color: primaryOrange,
                              }}
                            >
                              Add Variant
                            </label>
                          </Col>
                        </div>
                      )}
                    </Col>
                  </div>
                  <div
                    style={{ height: "1px", backgroundColor: primaryGrey }}
                  ></div>
                  <div>
                    <Row style={{ justifyContent: "end" }}>
                      <Col
                        lg="1"
                        md="1"
                        sm="1"
                        xs="1"
                        className="mt-3 mb-3"
                        style={{ flexDirection: "column", display: "flex" }}
                      >
                        <CustomButton
                          buttonType="secondary"
                          title={"Cancel"}
                          handleButtonClick={() => {
                            setIsDisplayVariantCard(true);
                            setIsValidate(true);
                            setDisplayLength(variants.length);
                            setAddVariant(false);
                            setVariants(variants);
                            setIsEdit(false);
                            setIsAddingOptions(false);
                            setOpenProductVariant(!openProductVariant);
                            setOpenProductDetail(false);
                            setOpenProductVariantCombination(false);
                          }}
                        />
                      </Col>
                      <Col
                        lg="2"
                        md="2"
                        sm="2"
                        xs="2"
                        className="mt-3 mb-3"
                        style={{ flexDirection: "column", display: "flex" }}
                      >
                        <CustomButton
                          title={
                            (addVariant && isAddingOptions && !isEdit) || isEdit
                              ? "Save"
                              : "Next"
                          }
                          customStyle={{
                            marginTop: "0px",
                            padding: "8px",
                            fontWeight: "700",
                          }}
                          handleButtonClick={handleSaveData}
                        />
                      </Col>
                    </Row>
                  </div>
                </Card>
              </div>
            </Collapse>
            {variants.length > 0 ? (
              <Fragment>
                <Card
                  style={{
                    width: "100%",
                    borderTopLeftRadius: "12px",
                    borderTopRightRadius: "12px",
                    marginTop: "1%",
                  }}
                  aria-expanded={openProductVariantCombination}
                  aria-controls="collapseID"
                  className="shadow"
                >
                  <Fragment>
                    <Row
                      onClick={() => {
                        if (!openProductVariantCombination) {
                          invertProductCombinationSection();
                        }
                      }}
                    >
                      <Col
                        lg="1"
                        md="1"
                        sm="1"
                        xs="1"
                        className="mt-3 mb-3"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        onClick={() => {
                          if (openProductVariantCombination) {
                            invertProductCombinationSection();
                          }
                        }}
                      >
                        <IconContext.Provider
                          value={{ size: 24, color: "black" }}
                        >
                          <BiWrench />
                        </IconContext.Provider>
                      </Col>
                      <Col
                        lg="8"
                        md="8"
                        sm="8"
                        xs="8"
                        className="mt-3 mb-3"
                        style={{ flexDirection: "column", display: "flex" }}
                        onClick={() => {
                          if (openProductVariantCombination) {
                            invertProductCombinationSection();
                          }
                        }}
                      >
                        <label
                          style={{
                            fontWeight: "500",
                            fontSize: "16px",
                            color: neturalBlack,
                          }}
                        >
                          Variants' details
                        </label>
                        <label
                          style={{
                            fontWeight: "400",
                            fontSize: "14px",
                            color: netural400,
                          }}
                        >
                          Price, Discount, Quantity, Max units p/ user, SKU
                        </label>
                      </Col>
                      {openProductVariantCombination ? (
                        <Fragment>
                          <Col
                            lg="1"
                            md="1"
                            sm="1"
                            xs="1"
                            className="mt-3 mb-3"
                            style={{ flexDirection: "column", display: "flex" }}
                          >
                            <CustomButton
                              buttonType="secondary"
                              title={"Cancel"}
                              handleButtonClick={
                                invertProductCombinationSection
                              }
                            />
                          </Col>
                          <Col
                            lg="2"
                            md="2"
                            sm="2"
                            xs="2"
                            className="mt-3 mb-3"
                            style={{ flexDirection: "column", display: "flex" }}
                          >
                            <CustomButton
                              title={"Save and close"}
                              customStyle={{ marginTop: "0px", padding: "8px" }}
                              handleButtonClick={handleSubmit}
                            />
                          </Col>
                        </Fragment>
                      ) : null}
                    </Row>
                  </Fragment>
                </Card>
                <Collapse in={openProductVariantCombination}>
                  <div id="collapseID">
                    <Card
                      style={{
                        width: "100%",
                        borderBottomLeftRadius: "12px",
                        borderBottomRightRadius: "12px",
                      }}
                    >
                      <div style={{ marginTop: "3%", marginLeft: "2%" }}>
                        <ProductCombinationHeader />
                        {variantCombinations.length > 0
                          ? variantCombinations.map(
                              (variantCombination, index) => (
                                <Fragment key={index}>
                                  <ProductCombinationCard
                                    variantCombination={variantCombination}
                                    key={index}
                                    index={index}
                                    isEdit={true}
                                    updateValue={updateValue}
                                  />
                                  <div
                                    style={{
                                      color: "red",
                                      marginBottom: "1%",
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                  >
                                    {/* Display validation messages for the current variant */}
                                    {validationMessages[index]}
                                  </div>
                                </Fragment>
                              )
                            )
                          : null}
                      </div>
                      <div
                        style={{ height: "1px", backgroundColor: primaryGrey }}
                      ></div>
                      <div>
                        <Row style={{ justifyContent: "end" }}>
                          <Col
                            lg="1"
                            md="1"
                            sm="1"
                            xs="1"
                            className="mt-3 mb-3"
                            style={{ flexDirection: "column", display: "flex" }}
                          >
                            <CustomButton
                              buttonType="secondary"
                              title={"Cancel"}
                              handleButtonClick={
                                invertProductCombinationSection
                              }
                            />
                          </Col>
                          <Col
                            lg="2"
                            md="2"
                            sm="2"
                            xs="2"
                            className="mt-3 mb-3"
                            style={{ flexDirection: "column", display: "flex" }}
                          >
                            <CustomButton
                              title={"Save and close"}
                              customStyle={{ marginTop: "0px", padding: "8px" }}
                              handleButtonClick={handleSubmit}
                            />
                          </Col>
                        </Row>
                      </div>
                    </Card>
                  </div>
                </Collapse>
              </Fragment>
            ) : null}
          </div>
        )}
      </div>
    </div>
  );
};

export default EditProduct;
