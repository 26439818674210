import { Fragment } from "react";
import SectionTitle from "../section-title/section-title.component";
import FormFooterButtons from "../form-footer-buttons/form-footer-buttons.component";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import ProductCombinationHeader from "../product-combination-header/product-combination-header.component";
import ProductCombinationCard from "../product-combination-card/product-combination-card.component";

const ProductVariantsDetail = ({
  handleBackClick,
  handleNextClick,
  setVariantCombination,
  formValue,
}) => {
  const [combinationOfVariants, setCombinationOfVariants] = useState(
    formValue.setVariantCombination
  );
  const [validationMessages, setValidationMessages] = useState([]);

  const updateValue = (index, field, value) => {
    setCombinationOfVariants((prevData) => {
      const newData = [...prevData];
      newData[index][field] = value;
      return newData;
    });

    // Clear the validation message for the field
    setValidationMessages((prevMessages) => {
      const newMessages = [...prevMessages];
      newMessages[index] = "";
      return newMessages;
    });
  };

  const handleSaveData = () => {
    // Validate the data
    const isInvalid = combinationOfVariants.some(
      (variantCombination, index) => {
        const messages = [];

        if (!variantCombination.price.match(/^[0-9 ]/)) {
          messages.push("Price is required");
        }

        if (!variantCombination.discount.match(/^[0-9 ]/)) {
          messages.push("Discount is required");
        }

        if (!variantCombination.quantity.match(/^[0-9 ]/)) {
          messages.push("Quantity is required");
        }

        if (variantCombination.sku === "") {
          messages.push("SKU cannot be empty");
        } else if (variantCombination.sku.length < 3) {
          messages.push("SKU must be at least 3 characters long");
        }

        // Add more conditions for other fields if needed
        // ...

        if (variantCombination.quantityLimit === "") {
          messages.push("Quantity Limit cannot be empty");
        }

        // Update validation messages for the current variant
        setValidationMessages((prevMessages) => {
          const newMessages = [...prevMessages];
          newMessages[index] = messages.join(", ");
          return newMessages;
        });

        return messages.length > 0;
      }
    );

    if (isInvalid) {
      // If there are validation errors, do not proceed with saving data
      return;
    }

    setVariantCombination(combinationOfVariants);
    handleNextClick();
  };

  return (
    <Fragment>
      <div style={{ marginTop: "5%", marginBottom: "3%" }}>
        <SectionTitle
          title={"Variants"}
          subtitle={"Edit the information of each variant"}
        />
      </div>
      <Row className="mb-3" style={{ marginLeft: "10%" }}>
        <Col md="11" lg="11">
          <div
            style={{
              width: "100%",
              borderRadius: "12px",
              padding: "32px",
              border: "1px solid #dadada",
            }}
          >
            <ProductCombinationHeader />
            {/* Value of combination */}
            {combinationOfVariants.map((variantCombination, index) => (
              <Fragment>
                <ProductCombinationCard
                  variantCombination={variantCombination}
                  key={index}
                  index={index}
                  updateValue={updateValue}
                />
                <div style={{ color: "red",marginBottom: '1%', display: 'flex', justifyContent: 'center' }}>
                  {/* Display validation messages for the current variant */}
                  {validationMessages[index]}
                </div>
              </Fragment>
            ))}
          </div>
          <Col md="6" lg="6">
            <FormFooterButtons
              displayPreviousButton={true}
              previousButtonText={"Back"}
              handlePreviouButtonClick={handleBackClick}
              handleNextButtonClick={handleSaveData}
              nextButtonText={"Continue"}
            />
          </Col>
        </Col>
      </Row>
    </Fragment>
  );
};

export default ProductVariantsDetail;
