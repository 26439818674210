import { Fragment } from "react";
import { Col, Form } from "react-bootstrap";
import { netural400, primaryGrey } from "../../constants/color";
import { variantList } from "../../constants/constant";

const ProductVariantDropDownComponent = ({
  variants,
  editIndex,
  handleSelectChange,
  isEdit
}) => {
  const selectedVariantType = isEdit
    ? variants[editIndex]?.variantType
    : variants[variants.length - 1]?.variantType || "Default";
  return (
    <Fragment>
      <div style={{ display: "flex" }}>
        <Col md="8" lg="8" style={{ color: "#A6A6A6" }}>
          <Form.Group
            controlId="validationCustom02"
            style={{ color: "#A6A6A6" }}
          >
            <Form.Select
              aria-label="Default select example"
              style={{
                borderRadius: "12px",
                padding: "3%",
                borderColor: primaryGrey,
                color: netural400,
              }}
              onChange={handleSelectChange}
              value={selectedVariantType}
            >
              <option value="Default" disabled>
                {variants.length < 1 ? "Select variant" : "Select variant 2"}
              </option>
              {variantList.map((option) => (
                <option
                  key={option.id}
                  value={option.name}
                  disabled={variants.some(
                    (variant) => variant.variantType === option.name
                  )}
                >
                  {option.name}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Col>
      </div>
    </Fragment>
  );
};

export default ProductVariantDropDownComponent;
