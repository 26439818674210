import React, { Fragment, useState, useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { BsPlus } from "react-icons/bs";
import {
  primaryOrange,
} from "../../constants/color";
import SectionTitle from "../section-title/section-title.component";
import IllustrationImage from "../shared/illustration-image/illustration-image.component";
import FormFooterButtons from "../form-footer-buttons/form-footer-buttons.component";
import ProductVariantCardDesign from "../product-variant-card-design/product-variant-card-design.component";
import ProductVariantDropDownComponent from "../product-variant-dropdown/product-variant-dropdown.component";
import ProductVariantCreateInput from "../product-variant-create-input/product-variant-create-input.component";
import ProductVariantEditInput from "../product-variant-edit-input/product-variant-edit-input.component";
import { variantList } from "../../constants/constant";
const ProductVariants = ({
  handleBackClick,
  handleNextClick,
  setSelectedVariants,
  setVariantCombination,
  formValue,
}) => {
  const [variants, setVariants] = useState(
    formValue.variants.length !== 0 ? formValue.variants : []
  );
  const [isAddingOptions, setIsAddingOptions] = useState(false);
  const [isValidate, setIsValidate] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editIndex, setEditIndex] = useState(0);
  const [addVariant, setAddVariant] = useState(true);
  const [displayLength, setDisplayLength] = useState(0); //also using to for managing variants
  const [displayVariantCard, setIsDisplayVariantCard] = useState(false);

  useEffect(() => {
    if (formValue.variants && formValue.variants.length !== 0) {
      setIsDisplayVariantCard(true);
      setIsValidate(true);
      setDisplayLength(formValue.variants.length);
      setAddVariant(false);
      setVariants(formValue.variants);
    }
  }, []);
  const handleSelectChange = (event) => {
    setIsValidate(false);
    setIsAddingOptions(true);
    setVariants((prevVariants) => {
      // Check if the selected variant is being edited
      if (isEdit) {
        return prevVariants.map((variant, index) =>
          index === editIndex
            ? {
                ...variant,
                variantId: variantList.find(
                  (option) => option.name === event.target.value
                ).id,
                variantType: event.target.value,
              }
            : variant
        );
      } else {
        // Check if the maximum limit of 2 variants has been reached
        if (displayLength <= 1) {
           // Check if the variant at currentIndex exists
        const existingVariant = prevVariants[displayLength];
        if (existingVariant) {  
            // Variant at currentIndex exists, update its variantId and variantType
            return prevVariants.map((variant, index) =>
              index === displayLength
                ? {
                    ...variant,
                    variantId: variantList.find(
                      (option) => option.name === event.target.value
                    ).id,
                    variantType: event.target.value,
                  }
                : variant
            );
          } else {
            // Variant at currentIndex doesn't exist, add a new variant to the specified index
            return [
              ...prevVariants,
              {
                variantId: variantList.find(
                  (option) => option.name === event.target.value
                ).id,
                variantType: event.target.value,
                variantOptions: [""],
              },
            ];
          }     
      } else {
          return prevVariants;
        }
      }
    });
  };
  const handleInputChange = (event, variantId, index) => {
    setVariants((prevVariants) =>
      prevVariants.map((variant) =>
        variant.variantId === variantId
          ? {
              ...variant,
              variantOptions: variant.variantOptions.map((option, i) =>
                i === index ? event.target.value : option
              ),
            }
          : variant
      )
    );
  };
  const handleDeleteVariant = (variantId) => {
    setEditIndex(0);
    setIsValidate(false);
    setDisplayLength((prevLength) => prevLength - 1);
    setVariants((prevVariants) =>
      prevVariants.filter((variant) => variant.variantId !== variantId)
    );
  };
  const handleDeleteOption = (variantId, index) => {
    setVariants((prevVariants) =>
      prevVariants.map((variant) =>
        variant.variantId === variantId
          ? {
              ...variant,
              variantOptions: variant.variantOptions.filter(
                (_, i) => i !== index
              ),
            }
          : variant
      )
    );
  };
  const handleAddOption = (variantId) => {
    setVariants((prevVariants) =>
      prevVariants.map((variant) =>
        variant.variantId === variantId
          ? {
              ...variant,
              variantOptions: [...variant.variantOptions, ""],
            }
          : variant
      )
    );
  };

  // Function to generate combinations
  function generateCombinations(
    variant,
    index = 0,
    currentCombination = "",
    combinations = []
  ) {
    if (index === variant.length) {
      // If we've reached the end of the variants array, add the current combination to the result
      let variantCombination = {
        combination: currentCombination,
        price: "",
        discount: "",
        quantity: "",
        quantityLimit: 0,
        sku: "",
      };
      combinations.push(variantCombination);
      return;
    }

    // Iterate over the current variant's options
    for (let i = 0; i < variants[index].variantOptions.length; i++) {
      // Add the current option to the combination
      const separator = currentCombination.length === 0 ? "" : "/";
      const updatedCombination =
        currentCombination + separator + variants[index].variantOptions[i];

      // Recursively call the function for the next index
      generateCombinations(
        variants,
        index + 1,
        updatedCombination,
        combinations
      );
    }
  }

  const handleSaveData = () => {
    // Check for empty option input fields
    const hasEmptyOptions = variants.some((variant) =>
      variant.variantOptions.some((option) => option.trim() === "")
    );

    if (hasEmptyOptions || variants.length === 0) {
      // Handle the case where there are empty option input fields
      return;
    }
    setIsValidate(true);
    setIsEdit(false);
    setAddVariant(false);
    setIsAddingOptions(false);

    let combinations = [];
    generateCombinations(variants, 0, "", combinations);
    setVariantCombination(combinations);

    if (!isEdit) {
      setDisplayLength((prevLength) => prevLength + 1);
    }
    if (isValidate) {
      setSelectedVariants(variants);
      handleNextClick();
    } else {
      setIsDisplayVariantCard(true);
    }
  };
  const handleEdit = (variantNo) => {
    setIsEdit(true);
    setEditIndex(variantNo);
    setIsValidate(false);
  };

  return (
    <Fragment>
      <div style={{ marginTop: "5%", marginBottom: "3%" }}>
        <SectionTitle title={"Variants"} subtitle={"Select up to 2 variants"} />
      </div>
      <Form noValidate validated={false} onSubmit={() => {}}>
        <Row className="mb-3" style={{ marginLeft: "10%" }}>
          <Col md="6" lg="6">
            {displayVariantCard
              ? isEdit
                ? variants
                    .filter(
                      (_, idx) => editIndex !== idx && idx < displayLength
                    )
                    .map((variant, index) => (
                      <div key={index}>
                        <ProductVariantCardDesign
                          key={index}
                          variant={variant}
                          index={index}
                          handleEdit={handleEdit}
                          handleDeleteVariant={handleDeleteVariant}
                          addVariant={addVariant}
                          isEdit={isEdit}
                        />
                      </div>
                    ))
                : variants
                    .filter((_, idx) => idx < displayLength)
                    .map((variant, index) => (
                      // variant.variantOptions[0] !== "" ? (
                      // ) : null
                      <div key={index}>
                        <ProductVariantCardDesign
                          key={index}
                          variant={variant}
                          index={index}
                          handleEdit={handleEdit}
                          handleDeleteVariant={handleDeleteVariant}
                          addVariant={addVariant}
                          isEdit={isEdit}
                        />
                      </div>
                    ))
              : null}
            {addVariant && (
              <ProductVariantDropDownComponent
                variants={variants}
                editIndex={editIndex}
                handleSelectChange={handleSelectChange}
                isEdit={isEdit}
              />
            )}
            {
              addVariant && isAddingOptions && !isEdit && (
                <ProductVariantCreateInput
                  variant={variants[variants.length - 1]}
                  handleInputChange={handleInputChange}
                  handleAddOption={handleAddOption}
                  handleDeleteOption={handleDeleteOption}
                />
              )
              // renderInputFields(variants[variants.length - 1])
            }
            {
              isEdit && (
                <ProductVariantEditInput
                  variant={variants[editIndex]}
                  editIndex={editIndex}
                  isEdit={isEdit}
                  variants={variants}
                  handleSelectChange={handleSelectChange}
                  handleInputChange={handleInputChange}
                  handleDeleteOption={handleDeleteOption}
                  handleAddOption={handleAddOption}
                />
              )
              // <div>{selectVariantDropDown(variants[editIndex])}</div>
            }
            {!addVariant && !isEdit && variants.length < 2 && (
              <div style={{ display: "flex", marginTop: "3%" }}>
                <Col
                  md="6"
                  lg="6"
                  style={{
                    color: "#A6A6A6",
                    display: "flex",
                    alignItems: "center",
                  }}
                  onClick={() => {
                    setAddVariant(true);
                  }}
                >
                  <BsPlus color={primaryOrange} size={25} />
                  <label
                    style={{
                      fontSize: "14px",
                      fontWeight: "bold",
                      color: primaryOrange,
                    }}
                  >
                    Add Variant
                  </label>
                </Col>
              </div>
            )}
            <FormFooterButtons
              displayPreviousButton={true}
              previousButtonText={isValidate ? "Back" : "Cancel"}
              handlePreviouButtonClick={handleBackClick}
              handleNextButtonClick={handleSaveData}
              nextButtonText={isValidate ? "Continue" : "Done"}
            />
          </Col>
          <Col
            md="5"
            lg="5"
            style={{
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
            }}
          >
            <IllustrationImage />
          </Col>
        </Row>
      </Form>
    </Fragment>
  );
};
export default ProductVariants;
