import { Fragment } from "react";
import { Col, Form, InputGroup } from "react-bootstrap";
import { primaryOrange } from "../../constants/color";
import { BsPlus, BsTrash3 } from "react-icons/bs";

const ProductVariantCreateInput = ({
  variant,
  handleInputChange,
  handleAddOption,
  handleDeleteOption,
}) => {
  return (
    <Fragment>
      {variant?.variantOptions?.map((value, index) => (
        <div style={{ display: "flex", marginTop: "3%" }} key={index}>
          <Col md="12" lg="12" style={{ color: "#A6A6A6" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Form.Group
                controlId="validationCustom01"
                style={{ color: "#A6A6A6", width: "70%" }}
              >
                <Form.Label>Option {index + 1}</Form.Label>
                <InputGroup>
                  <Form.Control
                    required
                    type="text"
                    placeholder="Enter a value"
                    value={value}
                    onChange={(e) =>
                      handleInputChange(e, variant.variantId, index)
                    }
                    minLength={4}
                    maxLength={40}
                  />
                  {index !== 0 ? (
                    <BsTrash3
                      size={25}
                      color="#7D7D7D"
                      onClick={() =>
                        handleDeleteOption(variant.variantId, index)
                      }
                    />
                  ) : null}
                </InputGroup>
                {/* Display validation error message */}
                {value.trim() === "" && (
                  <label
                    style={{
                      fontSize: "13px",
                      marginLeft: "1%",
                      color: "#dc3545",
                      fontWeight: "400",
                    }}
                  >
                    Option cannot be empty.
                  </label>
                )}
              </Form.Group>
            </div>
          </Col>
        </div>
      ))}
      {variant?.variantOptions?.length < 5 && (
        <div style={{ display: "flex", marginTop: "3%" }}>
          <Col
            md="6"
            lg="6"
            style={{
              color: "#A6A6A6",
              display: "flex",
              alignItems: "center",
            }}
            onClick={() => handleAddOption(variant.variantId)}
          >
            <BsPlus color={primaryOrange} size={25} />
            <label
              style={{
                fontSize: "14px",
                fontWeight: "bold",
                color: primaryOrange,
              }}
            >
              Add option
            </label>
          </Col>
        </div>
      )}
    </Fragment>
  );
};

export default ProductVariantCreateInput;
