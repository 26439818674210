import { Link } from "react-router-dom";
import { IconContext } from "react-icons";
import { IoAddOutline } from "react-icons/io5";

const CustomLinkButtonWithIcon = ({linkToNavigate, title})=>{
    return(
        <Link
            to={linkToNavigate}
            style={{
              backgroundColor: "#E97101",
              width: "100%",
              borderRadius: "12px",
              display: "flex",
              justifyContent: "center",
              padding: 10,
              textDecoration: "none",
              color: "white",
            }}
          >
            <IconContext.Provider value={{ size: 20, color: "white" }}>
              <IoAddOutline />
            </IconContext.Provider>
            <label
              style={{
                fontSize: "14px",
                fontWeight: "bold",
                color: "white",
                display: "flex",
                marginLeft: 10,
              }}
            >
              {title}
            </label>
          </Link>
    )
}

export default CustomLinkButtonWithIcon;