import { gql } from "@apollo/client";

export const FETCH_USED_PROMO_CODE_USER_LIST = gql`
query fetchUsedPromoCodeUserList {
  fetchUsedPromoCodeUserList {
    totalCount
    usedPromoCodeUserList {
      customerName
      discountedAmount
      discountedPercentage
      promoCode
      type
      purchaseDate
      transactionId
    }
  }
}
`;
