import { Col, Image, Row } from "react-bootstrap";
import { neturalBlack } from "../../constants/color";
import CustomLinkButtonWithIcon from "../../components/link-button-with-icon/link-button-with-icon.component";

const EmptyServiceList = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "89%",
      }}
    >
      <Row style={{ display: "flex", justifyContent: "center" }}>
        <Col
          lg="12"
          md="12"
          sm="12"
          xs="12"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <Image
            src="./images/EmptyService.png"
            alt="No Service"
            style={{
              objectFit: "contain",
              width: "246.59px",
              height: "216.72px",
            }}
          />
        </Col>
        <Col
          lg="12"
          md="12"
          sm="12"
          xs="12"
          style={{ display: "flex", justifyContent: "center", marginTop: 10 }}
        >
          <label
            style={{ fontSize: "24px", fontWeight: "700", color: neturalBlack }}
          >
            No services listed yet
          </label>
        </Col>
        <Col lg="4" md="4" sm="4" xs="4"></Col>
        <Col
          lg="4"
          md="4"
          sm="4"
          xs="4"
          style={{ display: "flex", justifyContent: "center", marginTop: 10 }}
        >
          <label style={{ fontSize: "16px", textAlign: "center" }}>
            You don’t have any services listed. Start now by creating your first
            service.
          </label>
        </Col>
        <Col lg="4" md="4" sm="4" xs="4"></Col>
        <Col lg="4" md="4" sm="4" xs="4">
          <CustomLinkButtonWithIcon linkToNavigate={"create"} title={"Add Service"} />
        </Col>
      </Row>
    </div>
  );
}

export default EmptyServiceList;
