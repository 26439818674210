import { Col } from "react-bootstrap";
import { Fragment } from "react";
import { useState } from "react";
import BookingModalPopup from "../modal-popup/booking-modal-popup.component";

const BookingListCard = ({ data, bookingListColors, index }) => {
  const { timeSlot, serviceTitle, bookingId } = data;
  const [modalShow, setModalShow] = useState(false);
  const [clickedService, setClickedService] = useState("");

  return (
    <Fragment>
      <Col lg={3} md={3} sm={3} xs={3} style={{ marginLeft: "1%", marginTop: index !== 0 ? "2%" : "0%", }}>{index+1}</Col>
      <Col
        lg={3}
        md={3}
        sm={3}
        xs={3}
        style={{
          marginLeft: "1%",
          marginTop: index !== 0 ? "2%" : "0%",
        }}
      >
        <label style={{ fontWeight: "400", fontSize: "16px" }}>
          {timeSlot}
        </label>
      </Col>
      <Col
        lg={5}
        md={5}
        sm={5}
        xs={5}
        style={{
          marginLeft: "1%",
          display: "flex",
          alignItems: "center",
          marginTop: index !== 0 ? "2%" : "0%",
        }}
      >
        <div
          style={{
            padding: "1.8%",
            backgroundColor: bookingListColors[bookingId],
            borderRadius: "50%",
          }}
        ></div>
        <label
          style={{
            fontWeight: "400",
            fontSize: "16px",
            marginLeft: "3%",
          }}
          onClick={() => {
            setClickedService(serviceTitle);
            setModalShow(true);
          }}
        >
          {serviceTitle}
        </label>
      </Col>
      {modalShow ?
      <BookingModalPopup
        show={modalShow}
        onHide={() => {
          setModalShow(false);
        }}
        onSubmit={async () => {
          setModalShow(false);
          // await deletePortalProduct({
          //   variables: {
          //     productId: selectedProductId,
          //   },
          // });
        }}
        color={bookingListColors[bookingId]}
        title={clickedService}
        bookingid={bookingId}
      />:null}
    </Fragment>
  );
};

export default BookingListCard;
