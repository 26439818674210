import { Fragment } from "react";
import SectionTitle from "../section-title/section-title.component";
import { Col, Form, Row } from "react-bootstrap";
import FormFooterButtons from "../form-footer-buttons/form-footer-buttons.component";
import IllustrationImage from "../shared/illustration-image/illustration-image.component";
import { useState } from "react";
import { netural400, neturalBlack } from "../../constants/color";
import { useEffect } from "react";

const ProductVariantsQuestion = ({
  handleBackClick,
  handleNextClick,
  setProductVariantQuestion,
  formValue,
}) => {
  const [selectedVariants, setSelectedVariants] = useState(false);

  useEffect(() => {
    if (formValue.productVariantQuestion) {
      setSelectedVariants(formValue.productVariantQuestion);
    }
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setProductVariantQuestion(selectedVariants);

    handleNextClick();
  };
  return (
    <Fragment>
      <div style={{ marginTop: "5%", marginBottom: "3%" }}>
        <SectionTitle
          title={"Does your product have variants?"}
          subtitle={"E.g.: colour, size, materia, flavour."}
        />
      </div>
      <Form noValidate onSubmit={handleSubmit}>
        <Row className="mb-3" style={{ marginLeft: "10%" }}>
          <Col md="6" lg="6" style={{ marginLeft: "3%" }}>
            {["radio"].map((type) => (
              <div
                key={`inline-${type}`}
                className="mb-3"
                style={{ display: "grid" }}
              >
                <div
                  style={{ display: "flex", alignItems: "center" }}
                  onClick={() => setSelectedVariants(!selectedVariants)}
                >
                  <Form.Check
                    inline
                    label=""
                    name="group1"
                    type={type}
                    id={`inline-${type}-1`}
                    style={{ fontSize: "18px" }}
                    checked={selectedVariants}
                  />
                  <div style={{ display: "grid" }}>
                    <label style={{ fontSize: "18px", color: neturalBlack }}>
                      Yes. The product will have variants
                    </label>
                    <label style={{ fontSize: "16px", color: netural400 }}>
                      E.g.: colour, size, materia, flavour.
                    </label>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    marginTop: "3%",
                    alignItems: "center",
                  }}
                  onClick={() => setSelectedVariants(!selectedVariants)}
                >
                  <Form.Check
                    inline
                    label=""
                    name="group1"
                    type={type}
                    id={`inline-${type}-2`}
                    style={{ fontSize: "18px" }}
                    checked={!selectedVariants}
                  />
                  <div style={{ display: "grid" }}>
                    <label style={{ fontSize: "18px", color: neturalBlack }}>
                      No. The product will not have variants
                    </label>
                    <label style={{ fontSize: "16px", color: netural400 }}>
                      Standard product with no variants.
                    </label>
                  </div>
                </div>
              </div>
            ))}
            <FormFooterButtons
              displayPreviousButton={true}
              previousButtonText={"Back"}
              handlePreviouButtonClick={handleBackClick}
              nextButtonText={"Continue"}
            />
          </Col>
          <Col
            md="5"
            lg="5"
            style={{
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
            }}
          >
            <IllustrationImage />
          </Col>
        </Row>
      </Form>
    </Fragment>
  );
};

export default ProductVariantsQuestion;
