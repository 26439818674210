import { useLazyQuery } from "@apollo/client";
import { React, useEffect, useState } from "react";
import { Col, Row, Image, Tab, ListGroup } from "react-bootstrap";
import {
  DELETE_PORTAL_SERVICE,
  FETCH_SERVICE_LIST,
} from "../../services/graphql/services";
import { useDispatch, useSelector } from "react-redux";
import LoadingSpinner from "../shared/loading-spinner/loading-spinner.component";
import { netural400 } from "../../constants/color";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ModalPopup from "../modal-popup/modal-popup.component";
import EmptyServiceList from "../empty-service-list/empty-servie-list.component";
import { IconContext } from "react-icons";
import {  IoTrashOutline } from "react-icons/io5";
import { FaRegEdit } from "react-icons/fa";
import HeaderSection from "../header/header.component";
import { setBusinesProfile } from "../../actions/businessProfileActions";
import { GET_BUSINESS_PROFILE } from "../../services/graphql/businessAccount";
import { Link } from "react-router-dom";
import CustomLinkButtonWithIcon from "../link-button-with-icon/link-button-with-icon.component";
import { Fragment } from "react";

const ServicesList = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const [isLoading, setIsLoading] = useState(true);
  // console.log(user, "form the redux");
  useEffect(() => {
    if (user) {
      getBusinessInformation(user._id);
    }
  }, [user]);

  const [businessProfileData] = useLazyQuery(GET_BUSINESS_PROFILE, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
    //  console.log("the BusinessProfile : ", data.businessProfileData);
      dispatch(setBusinesProfile(data.businessProfileData));
      setIsLoading(false);
      setBusienssProfile(data.businessProfileData);
      getServiceList(data.businessProfileData._id);
    },
  });
  const getBusinessInformation = async (userId) => {
   // console.log(userId);
    await businessProfileData({
      variables: {
        id: userId,
      },
    });
  };
  // const businesprofile = useSelector((state) => state.businesprofile);
  const [businessProfile, setBusienssProfile] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [displayDeleteServiceErrorModal, setDisplayDeleteServiceErrorModal] =
    useState(false);
  const [selectedServiceId, setSelectedServiceId] = useState("");
  const [services, setServices] = useState([]);
  const [displayEmptyScreen, setDisplayEmptyScreen] = useState(false);
  const [fetchBusinessServiceList, { loading: loadingServicesFetch }] =
    useLazyQuery(FETCH_SERVICE_LIST, {
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        if (
          data.fetchBusinessServiceList &&
          data.fetchBusinessServiceList.success
        ) {
          let services = data.fetchBusinessServiceList.services;
          if (services.length > 0)
            setServices(data.fetchBusinessServiceList.services);
          else setDisplayEmptyScreen(true);
        }
      },
      onError: (error) => {
        toast.error(error.message, {
          style: { width: "450px" },
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored ",
        });
      },
    });
  const getServiceList = async (businessId) => {
    await fetchBusinessServiceList({
      variables: {
        businessId,
      },
    });
  };

  const onSuccessDelete = () => {
    fetchBusinessServiceList({
      variables: {
        businessId: businessProfile._id,
      },
    });
  };

  const [deletePortalService] = useMutation(DELETE_PORTAL_SERVICE, {
    onCompleted: (data) => {
      if (data.deletePortalService.success) {
        toast.success(data.deletePortalService.message, {
          style: { width: "450px" },
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored ",
        });
        onSuccessDelete();
        // setSelectedTab("Services")
      } else {
        setDisplayDeleteServiceErrorModal(true);
        setModalShow(false);
      }
    },
    onError: (error) => {
      // console.log(error, "ERROR==============");
      toast.error(error.message, {
        style: { width: "450px" },
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored ",
      });
    },
  });

  const handleDeleteClick = (serviceId) => {
    setModalShow(true);
    setSelectedServiceId(serviceId);
  };

  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <div className="main-div-right-section">
      <Fragment>
        {/* Page header section with searcbh and profile */}
        <HeaderSection currentTab={"Service"} />
        {!loadingServicesFetch && displayEmptyScreen ? (
          <EmptyServiceList />
        ) : (
          <div className="second-section-style">
            <div>
              <Row>
                <Col
                  lg="10"
                  md="8"
                  sm="6"
                  xs="6"
                  style={{
                    paddingLeft: "0px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <label style={{ fontSize: "22px", fontWeight: "bold" }}>
                    Services
                  </label>
                </Col>
                <Col lg="2" md="4" sm="6" xs="6" style={{ paddingLeft: "0px" }}>
                  <CustomLinkButtonWithIcon
                    title={"Add Service"}
                    linkToNavigate={"create"}
                  />
                </Col>
              </Row>
              <Tab.Container>
                <div style={{ marginTop: "3%" }}>
                  {loadingServicesFetch ? (
                    <LoadingSpinner size="medium" overlay={false} />
                  ) : services && services.length > 0 ? (
                    <Row>
                      <Col
                        lg="12"
                        md="12"
                        sm="12"
                        xs="12"
                        style={{ paddingLeft: "0px" }}
                      >
                        <ListGroup style={{ borderRadius: 12 }}>
                          {services.map((service, index) => (
                            <ListGroup.Item key={index}>
                              <Row>
                                <Col
                                  lg="1"
                                  md="2"
                                  sm="2"
                                  xs="2"
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <Image
                                    src={`${process.env.REACT_APP_HOST_URL}${service.photoUri}`}
                                    alt="Service Image"
                                    style={{
                                      width: "78px",
                                      height: "78px",
                                      borderRadius: "12px",
                                    }}
                                  />
                                </Col>
                                <Col
                                  lg="10"
                                  md="8"
                                  sm="6"
                                  xs="6"
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <p
                                    style={{
                                      marginBottom: "0px",
                                      fontSize: "16px",
                                      fontWeight: 500,
                                    }}
                                  >
                                    {service.title} <br />
                                    <p
                                      style={{
                                        marginBottom: "0px",
                                        fontSize: "14px",
                                        fontWeight: "normal",
                                        color: netural400,
                                      }}
                                    >
                                      {service.duration.hours > 1
                                        ? `${service.duration.hours} hours `
                                        : service.duration.hours === 1
                                        ? `${service.duration.hours} hour `
                                        : ""}
                                      {service.duration.minutes > 1
                                        ? `${service.duration.minutes} minutes`
                                        : ""}
                                    </p>
                                    {service.expired ? (
                                      <p
                                        style={{
                                          marginBottom: "0px",
                                          fontSize: "12px",
                                          fontWeight: "normal",
                                          color: netural400,
                                        }}
                                      >
                                        Status: Expired
                                      </p>
                                    ) : null}
                                  </p>
                                </Col>
                                <Col
                                  lg="1"
                                  md="1"
                                  sm="2"
                                  xs="2"
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  {/* {service.expired ? null : ( */}
                                    <Link
                                      to={`edit/${service.serviceId}`}
                                      // onClick={() => {
                                      //   handleEditClick(service.serviceId);
                                      // }}
                                    >
                                      <IconContext.Provider
                                        value={{ size: 24, color: "black" }}
                                      >
                                        <FaRegEdit />
                                      </IconContext.Provider>
                                    </Link>
                                  {/* )} */}
                                  <div style={{ marginLeft: "15%" }}></div>
                                  <div
                                    onClick={() => {
                                      handleDeleteClick(service.serviceId);
                                    }}
                                  >
                                    <IconContext.Provider
                                      value={{ size: 24, color: "black" }}
                                    >
                                      <IoTrashOutline />
                                    </IconContext.Provider>
                                  </div>
                                </Col>
                              </Row>
                            </ListGroup.Item>
                          ))}
                        </ListGroup>
                      </Col>
                    </Row>
                  ) : null}
                </div>
              </Tab.Container>
            </div>
            <ModalPopup
              show={modalShow}
              onHide={() => {
                setModalShow(false);
              }}
              onCancelPress={() => {
                setModalShow(false);
              }}
              onSubmit={async () => {
                setModalShow(false);
                await deletePortalService({
                  variables: {
                    serviceId: selectedServiceId,
                  },
                });
              }}
              buttonType={"Delete"}
              title={"Delete Service"}
              body={"Are you sure you want to delete this service? "}
              body2={
                "Important: Deleting this service will also delete all associated bookings from Calendar. This cannot be undone. "
              }
              submitButton={"Delete Service"}
            />
            <ModalPopup
              show={displayDeleteServiceErrorModal}
              onHide={() => {
                setDisplayDeleteServiceErrorModal(false);
              }}
              title={"Action Denied: Delete Service"}
              body={"This service has currently pending bookings scheduled. "}
              body2={
                "You can delete this service, after completing all pending bookings."
              }
              okayButtonText={"Okay"}
              onOkayPress={() => {
                setDisplayDeleteServiceErrorModal(false);
              }}
            />
          </div>
        )}
      </Fragment>
    </div>
  );
};

export default ServicesList;
