import { gql } from "@apollo/client";

export const INSERT_SUBCATEGORY = gql`
  mutation addSubCategoryByCategoryId($categoryId:String, $subCategory:InputSubcategory){
    addSubCategoryByCategoryId(categoryId:$categoryId,subCategory:$subCategory ){
        success
        subCategories{
            _id
            name
        }
    }
  }
`

export const CATEGORY_SEARCH = gql`
  query categorySearch($query: String) {
    categorySearch(query: $query) {
      _id
      name
      subCategories {
        _id
        name
      }
      subCategoryCount
    }
  }
`;

export const DELETE_SUBCATEGORY = gql`
  mutation deleteSubCategory($categoryId: String!, $subcategoryId: String!) {
    deleteSubCategory(categoryId: $categoryId, subcategoryId: $subcategoryId) {
      message
      success
    }
  }
`;


