import { Image } from "react-bootstrap";

const IllustrationImage = () => {
  return (
    <div>
      <Image
        src="../images/Illustration.png"
        alt="Illustration"
        style={{ width: "90%" }}
      />
    </div>
  );
};

export default IllustrationImage;
