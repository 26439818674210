import { Col } from "react-bootstrap";
import { neturalBlack } from "../../constants/color";

const ProductCombinationHeader = () =>{
    return(
        <div style={{ display: "flex", width: "100%", marginBottom: "2%" }}>
        <Col lg={2} md={2} sm={2} xs={2}>
          <label
            style={{
              fontSize: "16px",
              fontWeight: "500",
              color: neturalBlack,
            }}
          >
            Variant
          </label>
        </Col>
        <Col lg={2} md={2} sm={2} xs={2}>
          <label
            style={{
              fontSize: "16px",
              fontWeight: "500",
              color: neturalBlack,
            }}
          >
            Price ($)
          </label>
        </Col>
        <Col lg={2} md={2} sm={2} xs={2}>
          <label
            style={{
              fontSize: "16px",
              fontWeight: "500",
              color: neturalBlack,
            }}
          >
            Discount (%)
          </label>
        </Col>
        <Col lg={2} md={2} sm={2} xs={2}>
          <label
            style={{
              fontSize: "16px",
              fontWeight: "500",
              color: neturalBlack,
            }}
          >
            Quantity
          </label>
        </Col>
        <Col lg={2} md={2} sm={2} xs={2}>
          <label
            style={{
              fontSize: "16px",
              fontWeight: "500",
              color: neturalBlack,
            }}
          >
            Max. units p/user
          </label>
        </Col>
        <Col lg={2} md={2} sm={2} xs={2}>
          <label
            style={{
              fontSize: "16px",
              fontWeight: "500",
              color: neturalBlack,
            }}
          >
            SKU
          </label>
        </Col>
      </div>
    )
}

export default ProductCombinationHeader;