import { Col, Image, Row } from "react-bootstrap";
import CustomLinkButton from "../link-button/link-button.component";

const ItemCreatedConfirmation = ({title,message, linkLabel, linkTo}) =>{
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "89%",
      }}
    >
      <Row style={{ display: "flex", justifyContent: "center" }}>
        <Col
          lg="12"
          md="12"
          sm="12"
          xs="12"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <Image
            src="../images/conformation.png"
            alt="Conformation"
            style={{ objectFit: "contain" }}
          />
        </Col>
        <Col
          lg="12"
          md="12"
          sm="12"
          xs="12"
          style={{ display: "flex", justifyContent: "center", marginTop: 10 }}
        >
          <label style={{ fontSize: "20px", fontWeight: "500" }}>
           {title}
          </label>
        </Col>
        <Col lg="4" md="4" sm="4" xs="4"></Col>
        <Col
          lg="4"
          md="4"
          sm="4"
          xs="4"
          style={{ display: "flex", justifyContent: "center", marginTop: 10 }}
        >
          <label
            style={{ fontSize: "16px", fontWeight: "400", textAlign: "center" }}
          >
            {message}
          </label>
        </Col>
        <Col lg="4" md="4" sm="4" xs="4"></Col>
        <Col lg="4" md="4" sm="4" xs="4">
          <CustomLinkButton linkToNavigate={linkTo} title={linkLabel} />
        </Col>
      </Row>
    </div>
  );
}

export default ItemCreatedConfirmation;
