import { Route, Routes } from "react-router-dom";
import ProductList from "../../components/product-list/product-list.component";
import CreateProductForm from "../../components/create-product-form/create-product-form.component";
import EditProduct from "../../components/edit-product/edit-product.component";

const Products = () => {
  return (
    <Routes>
      <Route index element={<ProductList />} />
      <Route path="create" element={<CreateProductForm />} />
      <Route path="edit/:productId" element={<EditProduct />} />
    </Routes>
  );
};

export default Products;
