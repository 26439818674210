import { useState } from "react";
import "../category-list/category-list.styles.css";
import AddSubcategoryModal from "../create-subcategory-form/create-subcategory-form.component";
import { IoTrashOutline } from "react-icons/io5";
import ModalPopup from "../modal-popup/modal-popup.component";

const CategoryList = (props) =>{
  const {categories, setIsLoading, handleRemoveSubCategory} = props;
  const [modalShow, setModalShow] = useState(false)
  const  [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const  [selectedSubCategoryId, setSelectedSubCategoryId] = useState(null);
  const handleCloseAction = (action) =>{
    if(action !== "" && action === "success"){
      setIsLoading(true);
    }
    setShowAddSubcategoryModal(false);
  }

  const handleDeleteSubcategory = (categoryId, subcategoryId) =>{
    setModalShow(true);
    setSelectedCategoryId(categoryId)
    setSelectedSubCategoryId(subcategoryId)
  }
  const [showAddSubcategoryModal, setShowAddSubcategoryModal] =useState(false);
  const [selectedCategory, setSelectedCategory]= useState(null);
    const handleAddCategoryClick = (selectedCategory) =>{
       setSelectedCategory(selectedCategory)
       setShowAddSubcategoryModal(true);
    }
    return (
      <div>
        <table className="category-table">
          <thead>
            <tr>
              <th>Category Name</th>
              <th>Subcategories</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {categories.map((category) => (
              <tr key={category.id}>
                <td>{category.name}</td>
                <td>
                  <ul>
                    {category?.subCategories?.map((subcategory) => (
                      <li key={subcategory._id} style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <span>{subcategory.name}</span>
                        <IoTrashOutline onClick={()=>{handleDeleteSubcategory(category._id,subcategory._id)}} />
                      </li>
                    ))}
                  </ul>
                </td>
                <td>
                  <button onClick={() => handleAddCategoryClick(category)}>Add Subcategory</button>
                </td>
              </tr>
            ))}
          </tbody>

        </table>
        {showAddSubcategoryModal && 
          <AddSubcategoryModal selectedCategory={selectedCategory} show={showAddSubcategoryModal} handleClose={handleCloseAction}/>
        }
        {modalShow &&
          <ModalPopup
          show={modalShow}
          onHide={() => {
            setModalShow(false);
          }}
          onCancelPress={() => {
            setModalShow(false);
          }}
          onSubmit={async () => {
            await handleRemoveSubCategory(selectedCategoryId, selectedSubCategoryId);
            setModalShow(false);
            setIsLoading(true);
          }}
          buttonType={"Delete"}
          title={"Delete Subcategory"}
          body={"Are you sure you want to delete this subcategory? "}
          body2={"This cannot be undone."}
          submitButton={"Delete Subcategory"}
        />
        }
      </div>
      )
}

export default CategoryList;