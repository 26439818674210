import { Row, Col } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { CREATE_PORTAL_SERVICE } from "../../services/graphql/services";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoadingSpinner from "../../components/shared/loading-spinner/loading-spinner.component";
import IllustrationImage from "../../components/shared/illustration-image/illustration-image.component";
import SelectImages from "../../components/select-images/select-images.component";
import FormFooterButtons from "../form-footer-buttons/form-footer-buttons.component";

const ServiceImagesForm = ({
  handleBackClick,
  handleNextClick,
  setServiceImage,
  formValue,
}) =>{
  const [images, setImages] = useState([]);
  const businesprofile = useSelector((state) => state.businesprofile);
  //General error
  const [isGeneralError, setIsGeneralError] = useState(false);
  useEffect(() => {
    if (formValue.serviceImageList && formValue.serviceImageList.length > 0) {
      setImages(formValue.serviceImageList);
    }
  }, []);
  const [createServiceInput, { loading }] = useMutation(CREATE_PORTAL_SERVICE, {
    onCompleted: (data) => {
      toast.success(data.createPortalService.message, {
        style: { width: "450px" },
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored ",
      });
      handleNextClick();
    },
    onError: (error) => {
     // console.log(error, "ERROR==============");
      toast.error(error.message, {
        style: { width: "450px" },
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored ",
      });
    },
  });
  const onChange = (imageList) => {
    if(imageList.length > 0){
      setIsGeneralError(false);
    }else{
      setIsGeneralError(true)
    }
    setImages(imageList);
  };

  const handleSubmit = async () => {
    if (images.length === 0) {
      setIsGeneralError(true);
    } else {
      setServiceImage(images);
      let medias = [];
      let serviceTimeInMin =
        parseInt(formValue.serviceTimeHour) * 60 +
        parseInt(formValue.serviceTimeMin);
      images.map((imageValue) => {
        medias.push(imageValue.file);
      });
      let finalData = {
        title: formValue.name,
        userId: businesprofile?.user_id ? businesprofile.user_id : "",
        businessId: businesprofile?._id ? businesprofile._id : "",
        providerName: businesprofile?.name ? businesprofile.name : "",
        description: formValue.serviceDescription,
        startDate: formValue.serviceStartDate,
        endDate: formValue.serviceEndDate,
        serviceType: formValue.servicePlace,
        serviceInPerson: formValue.serviceInPerson,
        serviceOnline: formValue.serviceOnline,
        price: parseFloat(parseFloat(formValue.servicePrice).toFixed(2)),
        currency: formValue.serviceCurrency,
        discount: parseFloat(parseFloat(formValue.serviceDiscount).toFixed(2)),
        location: [
          {
            cityName: formValue.serviceCity[0].name,
            stateName: formValue.serviceState[0].name,
            countryName: formValue.serviceCountry[0].name,
          },
        ],
        capacity: formValue.serviceNoClient,
        maxCapacity: formValue.serviceMaxClient,
        duration: serviceTimeInMin,
        breakTime: {
          startTime: formValue.serviceBreakFrom,
          endTime: formValue.serviceBreakTo,
        },
        schedule: [
          {
            dayOfWeek: "Sunday",
            startTime: formValue.serviceOperatingSundayFrom,
            endTime: formValue.serviceOperatingSundayTo,
            available: formValue.serviceOperatingSunday,
          },
          {
            dayOfWeek: "Monday",
            startTime: formValue.serviceOperatingMondayFrom,
            endTime: formValue.serviceOperatingMondayTo,
            available: formValue.serviceOperatingMonday,
          },
          {
            dayOfWeek: "Tuesday",
            startTime: formValue.serviceOperatingTuesdayFrom,
            endTime: formValue.serviceOperatingTuesdayTo,
            available: formValue.serviceOperatingTuesday,
          },
          {
            dayOfWeek: "Wednesday",
            startTime: formValue.serviceOperatingWednesdayFrom,
            endTime: formValue.serviceOperatingWednesdayTo,
            available: formValue.serviceOperatingWednesday,
          },
          {
            dayOfWeek: "Thursday",
            startTime: formValue.serviceOperatingThursdayFrom,
            endTime: formValue.serviceOperatingThursdayTo,
            available: formValue.serviceOperatingThursday,
          },
          {
            dayOfWeek: "Friday",
            startTime: formValue.serviceOperatingFridayFrom,
            endTime: formValue.serviceOperatingFridayTo,
            available: formValue.serviceOperatingFriday,
          },
          {
            dayOfWeek: "Saturday",
            startTime: formValue.serviceOperatingSaturdayFrom,
            endTime: formValue.serviceOperatingSaturdayTo,
            available: formValue.serviceOperatingSaturday,
          },
        ],
        medias,
      };
     // console.log("createdServiceInput----", finalData);
      await createServiceInput({
        variables: {
          createServiceInput: finalData,
        },
      });
    }
  };

  const handleBack = () => {
    if (images.length !== 0) {
      setServiceImage(images);
    }
    handleBackClick();
  };

  return (
    <div>
      <div>
        <div style={{ marginTop: "5%" }}>
          <label
            style={{ fontSize: "22px", fontWeight: "700", color: "#1B1C1E" }}
          >
            Service images
          </label>
        </div>
        <div style={{ marginBottom: "5%" }}>
          <label
            style={{ fontSize: "16px", fontWeight: "400", color: "#545454" }}
          >
            Upload up to 7 images
          </label>
        </div>
      </div>
      <div>
        <Row className="mb-3">
          <Col md="12" lg="6" sm="12" xs="12">
            <Col md="12" lg="12" sm="12" xs="12">
              <SelectImages images={images} onChange={onChange} isGeneralError={isGeneralError} />
              {loading ? (
                <LoadingSpinner text={"Uploading..."} />
              ) : (
                <Row style={{ display: "flex", marginBottom: "3%" }}>
                  <FormFooterButtons 
                    displayPreviousButton={true}
                    previousButtonText={"Back"}
                    handlePreviouButtonClick={handleBack}
                    nextButtonText={"Submit"}
                    handleNextButtonClick={handleSubmit} 
                  />
                </Row>
              )}
            </Col>
          </Col>
          <Col
            md="12"
            lg="6"
            sm="12"
            xs="12"
            style={{
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
            }}
          >
           <IllustrationImage/>
          </Col>
        </Row>
      </div>
    </div>
  );
}
export default ServiceImagesForm;
