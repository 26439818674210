import { gql } from "@apollo/client";
//this is for fetching business profile whole data
export const GET_BUSINESS_PROFILE = gql`
  query businessProfileData($id: String!) {
    businessProfileData(id: $id) {
      _id
      user_id
      name
      email
      phone
      logo
      location
      team
      description
      subscriptionName
      commission
    }
  }
`;
