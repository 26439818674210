import { Col, Row } from "react-bootstrap";
import BookingListCard from "../booking-list-card/booking-list-card.component";
import BookingListEmpty from "../booking-list-empty/booking-list-empty.component";
import BookingListHeader from "../booking-list-header/booking-list-header.component";
import LoadingSpinner from "../shared/loading-spinner/loading-spinner.component";

const BookingList = ({ data,bookingListColors, selecetdDate, loading }) => {
  return (
    <div
      style={{
        width: "100%",
      }}
    >
      <div
        style={{
          display: "grid",
          alignContent: "center",
          height: "9vh",
          marginTop: "0.1%",
          marginLeft: '2%',
          backgroundColor: "white",
          borderRadius: '12px'
        }}
      >
        <Row
          style={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
          }}
        >
          <BookingListHeader />
        </Row>
      </div>
      {loading ?
      <div style={{marginTop:"4%"}}>
        <LoadingSpinner overlay={false}/> 
      </div>
      :
      <div style={{ marginTop: "1.6%" }}>
        <div
          style={{
            display: "grid",
            alignContent: "center",
            backgroundColor: 'white',
            marginLeft: '2%',
            borderRadius: '12px',
            padding: '2%'
          }}
        >
          <Row
            style={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center", 
            }}
          >
            {/* <Col lg={3} md={3} sm={3} xs={3} style={{ marginLeft: "1%" }}>
              <label style={{ fontWeight: "700", fontSize: "16px" }}>
                {selecetdDate.getDate()}{" "}
                <label style={{ fontWeight: "400", fontSize: "16px" }}>
                  {selecetdDate.toLocaleString("default", { month: "long" })}{" "}
                  {selecetdDate.toLocaleDateString("default", {
                    weekday: "long",
                  })}
                </label>
              </label>
            </Col> */}
            {data.length ? (
              data.map((data, index) => (
                <BookingListCard data={data} bookingListColors={bookingListColors} key={index} index={index} />
              ))
            ) : (
              <BookingListEmpty />
            )}
          </Row>
        </div>
      </div>
      }
    </div>
  );
};

export default BookingList;
