import Modal from "react-bootstrap/Modal";
import { netural400, netural50 } from "../../constants/color";
import { neturalBlack } from "../../constants/color";
import { Col, Row } from "react-bootstrap";
import {
  BsCart3,
  BsClock,
  BsCurrencyDollar,
  BsPerson,
  BsPersonFill,
} from "react-icons/bs";
import { IoLocationOutline, IoCalendarClearOutline } from "react-icons/io5";
import { Fragment, useEffect, useState } from "react";
import { FETCH_BOOOKING_DETAILS_BY_ID } from "../../services/graphql/booking";
import { useLazyQuery } from "@apollo/client";
import { toast } from "react-toastify";
import LoadingSpinner from "../shared/loading-spinner/loading-spinner.component";

const BookingModalPopup = (props) => {
  const { color, title, bookingid } = props;
  const [serviceDetails, setServiceDetails] = useState(null);
  const [guestDetails, setGuestDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [fetchBookingDetailsById] = useLazyQuery(FETCH_BOOOKING_DETAILS_BY_ID, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      if (data && data.fetchBookingDetailsById) {
        const { serviceDetails, guestDetails } = data.fetchBookingDetailsById;
        setServiceDetails(serviceDetails);
        setGuestDetails(guestDetails);
      }
      setIsLoading(false);
    },
    onError: (error) => {
      setIsLoading(false);
      if (error.message !== "Booking not found!") {
        toast.error(error.message, {
          style: { width: "450px" },
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored ",
        });
      }
    },
  });
  useEffect(() => {
    const fetchBookingDetails = async () => {
      await fetchBookingDetailsById({
        variables: {
          bookingId: bookingid,
        },
      });
    };
    fetchBookingDetails();
  }, [bookingid]);
  const formatDuration = (duration) => {
    const { hours, minutes } = duration;
    let durationStr = "";
    if (hours) {
      durationStr += `${hours} hour${hours > 1 ? "s" : ""} `;
    }
    if (duration?.minutes) {
      durationStr += `${minutes} minute${minutes > 1 ? "s" : ""}`;
    }
    return durationStr.trim();
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header
        closeButton
        style={{
          borderBottom: "0px solid rgb(0, 0, 0, 0.1)",
          paddingLeft: "3%",
          paddingRight: "3%",
          backgroundColor: "white",
          borderRadius: "12px",
        }}
      >
        <Modal.Title
          id="contained-modal-title-vcenter"
          style={{
            padding: "1.2%",
            backgroundColor: color,
            borderRadius: "50%",
            marginRight: "2%",
          }}
        ></Modal.Title>
        <Modal.Title id="contained-modal-title-vcenter">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ display: "flex", backgroundColor: netural50 }}>
        {isLoading ? (
          <div style={{ width: "100%" }}>
            <LoadingSpinner overlay={false} />
          </div>
        ) : !isLoading && !serviceDetails && !guestDetails ? (
          <div
            style={{
              backgroundColor: "white",
              padding: "2%",
              borderRadius: "12px",
              width: "100%",
              marginTop: "2%",
            }}
          >
            <h6 style={{ textAlign: "center" }}>
              Could not find information for this booking
            </h6>
          </div>
        ) : (
          <Fragment>
            <div
              style={{
                width: "50%",
                margin: "2%",
                backgroundColor: "white",
                borderRadius: "12px",
                paddingBottom: "3%",
              }}
            >
              <Row>
                <Col
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  style={{ marginTop: "4%" }}
                >
                  <div style={{ display: "flex" }}>
                    <IoCalendarClearOutline
                      size={25}
                      style={{ marginTop: "1.5%", marginRight: "4%" }}
                    />
                    <div>
                      <label
                        style={{
                          fontSize: "16px",
                          fontWeight: "500",
                          width: "100%",
                        }}
                      >
                        Date
                      </label>
                      <div style={{ alignItems: "center" }}>
                        <div>
                          <label
                            style={{
                              fontSize: "16px",
                              color: netural400,
                            }}
                          >
                            {`${serviceDetails.date}`}
                          </label>
                        </div>
                        {/* <BsDot size={21} /> */}
                        <div>
                          <label
                            style={{
                              fontSize: "16px",
                              color: netural400,
                            }}
                          >
                            {`${serviceDetails.time}`}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>

                <Col
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  style={{ marginTop: "8%" }}
                >
                  <div style={{ display: "flex" }}>
                    <BsClock
                      size={25}
                      style={{ marginTop: "1.5%", marginRight: "4%" }}
                    />
                    <div>
                      <label
                        style={{
                          fontSize: "16px",
                          fontWeight: "500",
                          width: "100%",
                        }}
                      >
                        Time
                      </label>
                      <label
                        style={{
                          fontSize: "16px",
                          width: "100%",
                          color: netural400,
                        }}
                      >
                        {formatDuration(serviceDetails.duration)}
                      </label>
                    </div>
                  </div>
                </Col>

                <Col
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  style={{ marginTop: "8%" }}
                >
                  <div style={{ display: "flex" }}>
                    <BsCart3
                      size={25}
                      style={{ marginTop: "1.5%", marginRight: "4%" }}
                    />
                    <div style={{ width: "100%" }}>
                      <label
                        style={{
                          fontSize: "16px",
                          fontWeight: "500",
                          width: "100%",
                        }}
                      >
                        Service type
                      </label>
                      <div style={{ display: "flex" }}>
                        <label
                          style={{
                            fontSize: "16px",
                            color: netural400,
                          }}
                        >
                          {serviceDetails.serviceType}
                        </label>
                      </div>
                    </div>
                  </div>
                </Col>

                <Col
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  style={{ marginTop: "8%" }}
                >
                  <div style={{ display: "flex" }}>
                    <IoLocationOutline
                      size={25}
                      style={{ marginTop: "1.5%", marginRight: "4%" }}
                    />
                    <div>
                      <label
                        style={{
                          fontSize: "16px",
                          fontWeight: "500",
                          width: "100%",
                        }}
                      >
                        Location
                      </label>
                      <label
                        style={{
                          fontSize: "16px",
                          width: "100%",
                          color: netural400,
                        }}
                      >
                        {serviceDetails.location}
                      </label>
                    </div>
                  </div>
                </Col>

                <Col
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  style={{ marginTop: "8%" }}
                >
                  <div style={{ display: "flex" }}>
                    <BsCurrencyDollar
                      size={25}
                      style={{ marginTop: "1.5%", marginRight: "4%" }}
                    />
                    <div style={{ width: "100%" }}>
                      <label
                        style={{
                          fontSize: "16px",
                          fontWeight: "500",
                          width: "100%",
                        }}
                      >
                        Price
                      </label>
                      <div style={{ display: "flex" }}>
                        <label
                          style={{
                            fontSize: "16px",
                            color: netural400,
                            fontWeight: "500",
                            // marginLeft: '1.5%',
                            // backgroundColor: 'green'
                          }}
                        >
                          {serviceDetails.discountedPrice}
                        </label>
                        {serviceDetails.price === 0 ||
                        serviceDetails.price !==
                          serviceDetails.discountedPrice ? (
                          <Fragment>
                            <label
                              style={{
                                fontSize: "16px",
                                color: netural400,
                                textDecoration: "line-through",
                                marginLeft: "2%",
                              }}
                            >
                              {serviceDetails.price}
                            </label>
                          </Fragment>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </Col>

                <Col
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  style={{ marginTop: "8%" }}
                >
                  <div style={{ display: "flex" }}>
                    <BsPerson
                      size={25}
                      style={{ marginTop: "1.5%", marginRight: "4%" }}
                    />
                    <div>
                      <label
                        style={{
                          fontSize: "16px",
                          fontWeight: "500",
                          width: "100%",
                        }}
                      >
                        {`Guests (${serviceDetails.remainingSpots} spots remaining)`}
                      </label>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <label
                          style={{
                            fontSize: "16px",
                            color: netural400,
                          }}
                        >
                          {` ${serviceDetails.totalSpotsBooked} spots`}
                        </label>
                        <label
                          style={{
                            fontSize: "16px",
                            color: netural400,
                            marginLeft: "2%",
                            marginRight: "2%",
                          }}
                        >
                          /
                        </label>
                        <label
                          style={{
                            fontSize: "16px",
                            color: netural400,
                          }}
                        >
                          {`  ${serviceDetails.capacity} spots`}
                        </label>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div
              style={{
                width: "50%",
                margin: "2%",
                maxHeight: "65vh",
                overflowY: "auto",
                backgroundColor: "white",
                borderRadius: "12px",
                paddingBottom: "3%",
              }}
            >
              <Row>
                <Col
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  style={{ marginTop: "4%" }}
                >
                  <label
                    style={{
                      marginBottom: "8%",
                      fontWeight: "700",
                      fontSize: "18px",
                    }}
                  >
                    Guests Information
                  </label>
                  <div style={{ display: "flex" }}>
                    <BsPersonFill
                      style={{
                        marginTop: "1.5%",
                        marginRight: "4%",
                      }}
                      size={25}
                    />
                    <div>
                      <label
                        style={{
                          fontSize: "16px",
                          fontWeight: "500",
                          width: "100%",
                        }}
                      >
                        {`${guestDetails.primaryGuest} - ${guestDetails.bookedSpots} spots`}
                      </label>
                      <label
                        style={{
                          fontSize: "16px",
                          width: "100%",
                          color: netural400,
                        }}
                      >
                        {guestDetails.email}
                      </label>
                      <label
                        style={{
                          fontSize: "16px",
                          width: "100%",
                          color: netural400,
                        }}
                      >
                        {guestDetails.phoneNumber}
                      </label>
                      {guestDetails.secondaryGuestList.length
                        ? guestDetails.secondaryGuestList.map((name, index) => {
                            return (
                              <Fragment key={index}>
                                <div
                                  style={{
                                    display: "flex",
                                    width: "100%",
                                    marginTop: "2%",
                                  }}
                                >
                                  <BsPersonFill
                                    style={{
                                      marginTop: "1.5%",
                                      marginRight: "4%",
                                    }}
                                    size={25}
                                  />
                                  <label
                                    style={{
                                      fontSize: "16px",
                                      fontWeight: "500",
                                      width: "100%",
                                      color: neturalBlack,
                                    }}
                                  >
                                    {name}
                                  </label>
                                </div>
                              </Fragment>
                            );
                          })
                        : null}
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Fragment>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default BookingModalPopup;
