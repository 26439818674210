import { Col, Image, Row } from "react-bootstrap";
import { netural400, neturalBlack } from "../../constants/color";
import { Fragment } from "react";
import { BsPersonFill } from "react-icons/bs";

const SalesServiceLsit = ({ media, spots, inviteesName }) => {
  return (
    <Fragment>
      <div
        style={{
          width: "100%",
          backgroundColor: "white",
          borderRadius: "12px",
          boxShadow: "1px 1px 10px 0px #DADADA",
          marginBottom: "10%",
        }}
      >
        {media.map((itemList) => {
          return (
            <div
              style={{
                padding: "3%",
              }}
            >
              <Row>
                <Col
                  lg="2"
                  md="2"
                  sm="2"
                  xs="2"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Image
                    src={`${process.env.REACT_APP_HOST_URL}${itemList.uri}`}
                    // src="../../images/profile.jpg"
                    alt="Service Image"
                    style={{
                      width: "80px",
                      height: "80px",
                      borderRadius: "12px",
                    }}
                  />
                </Col>
                <Col
                  lg="10"
                  md="10"
                  sm="10"
                  xs="10"
                  style={{
                    display: "grid",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                    }}
                  >
                    <label
                      style={{
                        fontSize: "16px",
                        fontWeight: "500",
                        color: neturalBlack,
                      }}
                    >
                      {itemList.title}
                    </label>
                    <label
                      style={{
                        fontSize: "16px",
                        fontWeight: "500",
                        color: neturalBlack,
                      }}
                    >
                      $ {itemList.price}
                    </label>
                  </div>
                  <label
                    style={{
                      fontSize: "14px",
                      color: netural400,
                    }}
                  >
                    Spots: {spots}
                  </label>
                </Col>
              </Row>
            </div>
          );
        })}
      </div>
      <div style={{ marginTop: "15%" }}>
        <div style={{ borderTop: "1px solid #dadada" }}>
          <div style={{ width: "100%", marginTop: "3%" }}>
            <label
              style={{
                fontSize: "20px",
                fontWeight: "600",
                color: neturalBlack,
              }}
            >
              Invitees ({spots})
            </label>
          </div>
          <div style={{ width: "100%", marginTop: "2%" }}>
            {inviteesName.map((name) => {
              return (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <BsPersonFill />
                  <label
                    style={{
                      fontSize: "16px",
                      fontWeight: "500",
                      color: neturalBlack,
                      marginLeft: "2%",
                    }}
                  >
                    {name}
                  </label>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default SalesServiceLsit;
