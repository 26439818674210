import { Fragment } from "react";
import ImageUploading from "react-images-uploading";
import { IconContext } from "react-icons";
import { BiImageAdd } from "react-icons/bi";
import { Col, Row } from "react-bootstrap";

const SelectImages = ({images, onChange, isGeneralError}) =>{
    const MAX_IMAGE_SELECT = 7;
    const ACCEPT_IMAGE_TYPES = ['jpg','jpeg','png'];
    return (
        <Fragment>
        <ImageUploading
          multiple
          value={images}
          onChange={onChange}
          acceptType={ACCEPT_IMAGE_TYPES}
          maxNumber={MAX_IMAGE_SELECT}
          dataURLKey="data_url"
        >
          {({
            imageList,
            onImageUpload,
            onImageRemove,
            dragProps
          }) => (
            <Fragment>
              <Row {...dragProps}>
                {imageList.map((image, index) => (
                  <Col
                    key={index}
                    md="4"
                    lg="4"
                    sm="6"
                    xs="6"
                    style={{ marginBottom: "3%" }}
                  >
                    <div className="image-item">
                      <div>
                        <i
                          className="fa fa-times"
                          style={{
                            marginTop: 5,
                            color: "#E97101",
                            display: "flex",
                            justifyContent: "end",
                            width: "15.8vh",
                          }}
                          onClick={() => onImageRemove(index)}
                        />
                        <img
                          src={image["data_url"]
                          ? image["data_url"]
                          : process.env.REACT_APP_HOST_URL +
                            image.uri}
                          alt=""
                          style={{
                            borderRadius: "12px",
                            objectFit: "fill",
                            height: "15.8vh",
                            width: "15.8vh",
                          }}
                        />
                      </div>
                    </div>
                  </Col>
                ))}
                {imageList.length < MAX_IMAGE_SELECT && (
                  <Col
                    md="4"
                    lg="4"
                    sm="6"
                    xs="6"
                    style={{
                      marginBottom: "3%",
                      display: "flex",
                      alignItems: "end",
                    }}
                  >
                    <div
                      style={{
                        border: "1px dashed #A6A6A6",
                        borderStyle: "dotted",
                        borderRadius: "12px",
                        height: "15.8vh",
                        width: "15.8vh",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                      onClick={onImageUpload}
                    >
                      <IconContext.Provider
                        value={{ size: 40, color: "black" }}
                      >
                        <BiImageAdd />
                      </IconContext.Provider>
                      <label
                        style={{
                          color: "#545454",
                          fontSize: "12px",
                          fontWeight: "400",
                        }}
                      >
                        Drop files here or click to upload.
                      </label>
                    </div>
                  </Col>
                )}
              </Row>
            </Fragment>
          )}
        </ImageUploading>
        {isGeneralError ? (
          <label
            style={{
              fontSize: ".875em",
              color: "#dc3545",
              display: "flex",
              justifyContent: "center",
              marginTop: "2%",
            }}
          >
            Please select atleast one image.
          </label>
        ) : null}
      </Fragment>
    )
}

export default SelectImages;