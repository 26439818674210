import { Col, Row } from "react-bootstrap";

const SalesListHeader = ({ type }) => {
  return (
    <div style={{ marginTop: "3%", marginLeft: "1%", marginBottom: "2%" }}>
      <Row>
        <Col lg={1} md={1} sm={1} xs={1}>
          <label style={{ fontSize: "16px", fontWeight: "600" }}>Sr no</label>
        </Col>
        <Col lg={3} md={3} sm={3} xs={3}>
          <label style={{ fontSize: "16px", fontWeight: "600" }}>
            {type === "Product" ? "Order ID" : "Booking ID"}
          </label>
        </Col>
        <Col lg={3} md={3} sm={3} xs={3}>
          <label style={{ fontSize: "16px", fontWeight: "600" }}>
            {type === "Product" ? "Order date" : "Purchase date"}
          </label>
        </Col>
        <Col lg={3} md={3} sm={3} xs={3}>
          <label style={{ fontSize: "16px", fontWeight: "600" }}>Client</label>
        </Col>
        <Col lg={2} md={2} sm={2} xs={2}>
          <label style={{ fontSize: "16px", fontWeight: "600" }}>Action</label>
        </Col>
      </Row>
    </div>
  );
};

export default SalesListHeader;
