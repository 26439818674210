export const setUser = (userData) => {
  return {
    type: "SET_USER",
    payload: userData,
  };
};

export const clearUser = () => {
  // Clear user data from sessionStorage
  localStorage.removeItem("persist:root");
  return {
    type: "CLEAR_USER",
  };
};
