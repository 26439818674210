const initialState = {};

const salesReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_SALES":
      return action.payload;
    case "CLEAR_SALES":
      return null;
    default:
      return state;
  }
};

export default salesReducer;
