import HeaderSection from "../header/header.component";
import { React, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ProductPricingForm from "../../components/product-pricing-form/product-pricing-form.component";
import ProductImageForm from "../../components/product-images-form/product-images-form.component";
import ProductGeneralInfoForm from "../../components/product-general-info-form/product-general-info-form.component";
import ItemCreatedConfirmation from "../../components/item-created-confirmation/item-created-confirmation.component";
import ProductVariantsQuestion from "../product-variants-question/product-variants-question.component";
import ProductVariants from "../product-variants/product-variants.component";
import ProductVariantsDetail from "../product-variants-detail/product-variants-detail.component";

const CreateProductForm = () => {
  const [stepsCount, setSetpsCount] = useState(1);

  const [formData, setFormData] = useState({
    productTitle: "",
    selectedCategory: "",
    selectedSubcategories: "",
    productDetail: "",
    productDescription: "",

    productVariantQuestion: "",

    variants: [],

    setVariantCombination: [],

    productPrice: "",
    productRestricedUser: "",
    productDiscount: "",
    productQuantity: "",
    productSKU: "",

    serviceImageList: [],
    completeProcess: false,
  });

  const navigate = useNavigate();

  const handleContinueClick = () => {
    setSetpsCount(stepsCount + 1);
  };

  const handlePageBackClick = () => {
    setSetpsCount(stepsCount - 1);
  };

  const handleBrowserBackButton = () => {
    if (stepsCount > 1) {
      setSetpsCount(stepsCount - 1);
    } else {
      navigate(-1);
    }
    window.addEventListener("popstate", handleBrowserBackButton);
    return () => {
      window.removeEventListener("popstate", handleBrowserBackButton);
    };
  };

  const setGeneralInfo = (
    productTitle,
    selectedCategory,
    selectedSubcategories,
    productDescription,
    productDetail
  ) => {
    setFormData({
      ...formData,
      productTitle: productTitle,
      selectedCategory: selectedCategory,
      selectedSubcategories: selectedSubcategories,
      productDescription: productDescription,
      productDetail: productDetail,
    });
  };

  const setVariantQuestion = (productVariantQuestion) => {
    setFormData({
      ...formData,
      productVariantQuestion: productVariantQuestion,
    });
  };

  const setSelectedVariants = (variants) => {
    setFormData({
      ...formData,
      variants: variants,
    });
  };

  const setVariantCombination = (setVariantCombination) => {
    setFormData({
      ...formData,
      setVariantCombination,
    });
  };

  const setProductPrice = (
    productPrice,
    productRestricedUser,
    productDiscount,
    productQuantity,
    productSKU
  ) => {
    setFormData({
      ...formData,
      productPrice: productPrice,
      productRestricedUser: productRestricedUser,
      productDiscount: productDiscount,
      productQuantity: productQuantity,
      productSKU: productSKU,
    });
  };

  const setProductImage = (imageList, completeProcess) => {
    setFormData({
      ...formData,
      serviceImageList: imageList,
      completeProcess,
    });
  };

  const ProgressIndicator = () => {
    return (
      <Row>
        <Col md="12" lg="12" style={{ display: "flex" }}>
          {formSteps.map((_, index) => (
            <Col lg="2" md="2" key={index}>
              <div
                style={{
                  height: 3,
                  backgroundColor: stepsCount >= index + 1 ? "#FF8D24" : null,
                }}
              ></div>
            </Col>
          ))}
        </Col>
      </Row>
    );
  };

  const formSteps = [
    {
      component: ProductGeneralInfoForm,
      props: {
        handleNextClick: handleContinueClick,
        handleBackButton: handleBrowserBackButton,
        setGeneralInfo: setGeneralInfo,
        formValue: formData,
      },
    },
    {
      component: ProductVariantsQuestion,
      props: {
        handleNextClick: handleContinueClick,
        handleBackClick: handlePageBackClick,
        setProductVariantQuestion: setVariantQuestion,
        formValue: formData,
      },
    },
    {
      component: ProductVariants,
      props: {
        handleNextClick: handleContinueClick,
        handleBackClick: handlePageBackClick,
        setSelectedVariants: setSelectedVariants,
        setVariantCombination: setVariantCombination,
        formValue: formData,
      },
    },
    {
      component: ProductVariantsDetail,
      props: {
        handleNextClick: handleContinueClick,
        handleBackClick: handlePageBackClick,
        setVariantCombination: setVariantCombination,
        formValue: formData,
      },
    },
    {
      component: ProductPricingForm,
      props: {
        handleNextClick: handleContinueClick,
        handleBackClick: handlePageBackClick,
        setProductPricing: setProductPrice,
        formValue: formData,
      },
    },
    {
      component: ProductImageForm,
      props: {
        handleBackClick: handlePageBackClick,
        setProductImage: setProductImage,
        formValue: formData,
      },
    },
  ];
  if (formData.productVariantQuestion) {
    // Remove the object at a specific index (e.g., index 2)
    formSteps.splice(4, 1); // Remove one object starting at index 2
    // if (formSteps.length > 2) {
    // }
  } else {
    formSteps.splice(2, 2);
  }
  const CurrentStepForm = formSteps[stepsCount - 1];
  return (
    <div
      style={{
        backgroundColor: "white",
        // flexDirection: "row",
        // display: "flex",
        // height: "100vh",
        width: "100%",
        overflow: "auto",
      }}
    >
      <div style={{ backgroundColor: "white", width: "100%" }}>
        {/* Page header section with searcbh and profile */}
        <HeaderSection currentTab={"Products"} />
        <div style={{ height: "89%" }}>
          {!formData.completeProcess ? (
            <div style={{ paddingLeft: "2%" }}>
              <ProgressIndicator />
              <div>
                {CurrentStepForm ? (
                  <CurrentStepForm.component {...CurrentStepForm.props} />
                ) : null}
              </div>
            </div>
          ) : (
            <ItemCreatedConfirmation
              title={"Product created!"}
              message={
                "Congratulations! The product is now available on our app. Upgrade \n your subscription to allow users to purchase the product directly \n from the app."
              }
              linkLabel={"Back to Products"}
              linkTo={"/products"}
            />
          )}
        </div>
      </div>
    </div>
  );
};
export default CreateProductForm;
