import { Fragment } from "react";
import HeaderSection from "../header/header.component";
import { useNavigate, useParams } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { neturalBlack } from "../../constants/color";
import CustomerCard from "../customer-card/customer-card.component";
import PaymentDetail from "../payment-detail/payment-detail.component";
import OrderDetail from "../order-detail/order-detail.component";
import SalesProductList from "../sales-product-list/sales-product-list.component";
import { FETCH_ORDER_DETAILS } from "../../services/graphql/sales";
import { useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import { toast } from "react-toastify";
import { useState } from "react";
import LoadingSpinner from "../shared/loading-spinner/loading-spinner.component";
import { useSelector } from "react-redux";

const SalesProductDetail = () => {
  const { orderId } = useParams();
  const navigate = useNavigate();
  const businesprofile = useSelector((state) => state.businesprofile);
  const [userInfo, setUserInfo] = useState({});
  const [orderInfo, setOrderInfo] = useState({});
  const [paymentDetail, setPaymentDetail] = useState({});
  const [media, setMedia] = useState([]);
  let type = "";

  const [fetchOrderDetails, { loading: loadingOrderDetails }] = useLazyQuery(
    FETCH_ORDER_DETAILS,
    {
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        if (data && data.fetchOrderDetails) {
          let orderData = data.fetchOrderDetails;
          type = orderData.type;
          setUserInfo(orderData.userInfo);
          setOrderInfo(orderData.orderInfo);
          setPaymentDetail(orderData.paymentSummary);
          setMedia(orderData.paymentSummary.itemInfo);
        }
      },
      onError: (error) => {
        toast.error(error.message, {
          style: { width: "450px" },
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored ",
        });
        navigate("/sales")
      },
    }
  );

  useEffect(() => {
    fetchOrderDetails({
      variables: {
        transactionId: orderId,
        providerId: businesprofile._id
      },
    });
  }, []);

  return (
    <div
      className="main-div-right-section"
      style={{ backgroundColor: "white" }}
    >
      <Fragment>
        <HeaderSection currentTab={"Sales"} />
        <div className="second-section-style">
          {loadingOrderDetails ? (
            <div style={{ marginTop: "4%" }}>
              <LoadingSpinner overlay={false} />
            </div>
          ) : (
            <Fragment>
              <Row>
                <Col
                  lg="12"
                  md="12"
                  sm="12"
                  xs="12"
                  style={{
                    paddingLeft: "0px",
                    display: "flex",
                    alignItems: "center",
                    marginTop: "2%",
                  }}
                >
                  <label
                    style={{
                      fontSize: "22px",
                      fontWeight: "bold",
                      color: neturalBlack,
                    }}
                  >
                    Order ID #{orderId}
                  </label>
                </Col>
                <Col lg="5" md="5" sm="5" xs="5" style={{ paddingLeft: 0 }}>
                  {/* Customer section for sales */}
                  <CustomerCard userInfo={userInfo} />
                  {/* Order detail section */}
                  <OrderDetail orderInfo={orderInfo} />
                  {/* Payment summary section */}
                  <PaymentDetail paymentDetail={paymentDetail} type={type} />
                </Col>
                <Col lg="1" md="1" sm="1" xs="1"></Col>
                <Col lg="6" md="6" sm="6" xs="6" style={{ paddingLeft: 0 }}>
                  <SalesProductList media={media} />
                </Col>
              </Row>
            </Fragment>
          )}
        </div>
      </Fragment>
    </div>
  );
};

export default SalesProductDetail;
