import { Link } from "react-router-dom";

const CustomLinkButton = ({linkToNavigate, title}) =>{
    return(
        <Link
            to={linkToNavigate}
            style={{
              backgroundColor: "#E97101",
              width: "100%",
              borderRadius: "12px",
              display: "flex",
              justifyContent: "center",
              padding: 10,
              marginTop: "5%",
              textDecoration: "none",
              color: "white",
            }}
          >
            <label
              style={{
                fontSize: "14px",
                fontWeight: "bold",
                color: "white",
              }}
            >
              {title}
            </label>
          </Link>
    )
}

export default CustomLinkButton;