import { ReactComponent as NoSalesImage } from "../../assets/sales.svg";
import { netural400, neturalBlack } from "../../constants/color";

const EmptySalesList = ({type}) => {

    return(
        <div
          style={{
            display: "flex",
            flexDirection:"column",
            justifyContent: "center",
            alignItems: "center",
            padding: "2%",
          }}
        >
          <NoSalesImage/>
          <label
            style={{ fontSize: "18px", fontWeight: "600", color: neturalBlack }}
          >
            No Sale
          </label>
          <label
           style={{ fontSize: "16px", color: netural400 }}
           >
            {`You have not sold any ${type.toLowerCase()}s yet.`}
          </label>
        </div>
    )
}

export default EmptySalesList;