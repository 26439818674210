import { Fragment } from "react";
import HeaderSection from "../header/header.component";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import { BsSearch, BsXLg } from "react-icons/bs";
import SalesListCard from "../sales-list-card/sales-list-card.component";
import { useState } from "react";
import { useSelector } from "react-redux";
import { FETCH_SALES_LIST } from "../../services/graphql/sales";
import { useQuery } from "@apollo/client";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setSales } from "../../actions/salesAction";

const PAGE_SIZE_FOR_SALES_LIST = 15;

const SalesList = () => {
  const dispatch = useDispatch();
  const businesprofile = useSelector((state) => state.businesprofile);
  const lastSavedData = useSelector((state) => state.sales);
  const [search, setSearch] = useState(
    lastSavedData ? lastSavedData.search : ""
  );
  const [salesList, setSalesList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [type, setType] = useState(
    lastSavedData ? lastSavedData.type : "Product"
  );
  const totalPages = Math.ceil(totalCount / PAGE_SIZE_FOR_SALES_LIST);

  const [page, setPage] = useState(lastSavedData ? lastSavedData.page : 1);
  // console.log("Last----------------", businesprofile)

  // Fetch data from the GraphQL server
  const { loading, data } = useQuery(FETCH_SALES_LIST, {
    variables: {
      providerId: businesprofile._id,
      type,
      search,
      page,
      pageSize: PAGE_SIZE_FOR_SALES_LIST,
    },
  });


  useEffect(() => {
    if (type) {
      let lastData = {
        type,
        search,
        page,
      };
      dispatch(setSales(lastData));
    }
  }, [setType, type, search, page]);

  useEffect(() => {
    setPage(1);
  },[type])

  useEffect(() => {
    if (data) {
      const { fetchSalesList } = data;
      const { totalCount, salesList } = fetchSalesList;
      setTotalCount(totalCount);
      setSalesList(salesList);
    }
  }, [data]);

  return (
    <div className="main-div-right-section">
      <Fragment>
        <HeaderSection currentTab={"Sales"} />
        <div className="second-section-style">
          <Fragment>
            <Row>
              <Col
                lg="10"
                md="8"
                sm="6"
                xs="6"
                style={{
                  paddingLeft: "0px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <label style={{ fontSize: "22px", fontWeight: "bold" }}>
                  Sales
                </label>
              </Col>
            </Row>
            <div style={{ marginTop: "3%" }}>
              <Row>
                <Col lg="3" md="3" sm="2" xs="2" style={{ paddingLeft: "0px" }}>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <InputGroup style={totalCount === 0 && search === "" ?{backgroundColor: "lightgrey" }:{backgroundColor: "white" }}>
                      <BsSearch color="#7d7d7d" />
                      <Form.Control
                        type="text"
                        style={totalCount === 0 && search === ""?{backgroundColor: "lightgrey" }:{backgroundColor: "white" }}
                        disabled={totalCount === 0 && search === ""}
                        placeholder="Search by Client name"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                      />
                      {search ? (
                        <BsXLg
                          onClick={() => {
                            setSearch("");
                          }}
                          color="#7d7d7d"
                        />
                      ) : null}
                    </InputGroup>
                  </Form.Group>
                </Col>
              </Row>
            </div>
            <SalesListCard
              totalCount={totalCount}
              salesList={salesList}
              page={page}
              type={type}
              setType={setType}
              setPage={setPage}
              totalPages={totalPages}
              pageSize={PAGE_SIZE_FOR_SALES_LIST}
              loading={loading}
              search={search}
              dropDownValue={["Product","Service"]}
            />
          </Fragment>
        </div>
      </Fragment>
    </div>
  );
};

export default SalesList;
