export const setBusinesProfile = (businessProfile) => {
  return {
    type: "SET_BUSINESS_PROFILE",
    payload: businessProfile,
  };
};
export const clearBusinesProfile = () => {
  return {
    type: "CLEAR_BUSINESS_PROFILE",
  };
};
