import { React, useState, useEffect, useRef } from "react";
import { Col, FloatingLabel, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { auth } from "../../firebase";
import { signInWithPhoneNumber, RecaptchaVerifier } from "firebase/auth";
import { LOGIN } from "../../services/graphql/auth";
import { useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";
import { setUser } from "../../actions/userActions";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomButton from "../button/button.component";

const VerifyOTP = (props) => {
  const [otpCode, setOTPCode] = useState("");
  const [confirmationResult, setConfirmationResult] = useState("");
  const [isPhoneValid, setPhoneValid] = useState(false);
  const [isResendOTP, setIsResendOTP] = useState(false);
  const [resendEnabled, setResendEnabled] = useState(false);
  const [timer, setTimer] = useState(30);
  const timerIdRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [login, { loading, error, data }] = useMutation(LOGIN, {
    fetchPolicy: "no-cache",
  });

  const handleLogin = async (firebaseUser) => {
    await login({
      variables: {
        phone: `+${props.phoneNumber}`,
        type: "login",
      },
    });
  };

  useEffect(() => {
    if (!loading && data) {
      if (data.login) {
    //    console.log(data.login, "login query worked");
        dispatch(setUser(data.login));
        navigate("/services", {
          replace: true,
        });
      }
    }
  }, [loading, error, data]);
  const startTimer = () => {
    setResendEnabled(false);
    setTimer(30);
    clearTimeout(timerIdRef.current);
    timerIdRef.current = setTimeout(() => {
      timerIdRef.current = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    }, 1000);
  };
  const stopTimer = () => {
    clearInterval(timerIdRef.current);
  };

  useEffect(() => {
    if (timer === 0) {
      setResendEnabled(true);
      stopTimer();
    }
    if (timer === 30) {
      startTimer();
    }
  }, [timer]);

  const sendOTP = async () => {
    try {
      let appVerifier = new RecaptchaVerifier(
        "recaptcha-container",
        {
          size: "invisible",
          callback: (response) => {},
        },
        auth
      );
      signInWithPhoneNumber(auth, `+${props.phoneNumber}`, appVerifier)
        .then((confirmationResult) => {
          setConfirmationResult(confirmationResult);
          setIsResendOTP(true);
          setResendEnabled(false);
        })
        .catch((error) => {
         // console.log(error);
          setIsResendOTP(true);
          setResendEnabled(false);
        });
      setIsResendOTP(true);
      setResendEnabled(false);
      startTimer();
    } catch (error) {
      // Handle the error
      console.error("Failed to send OTP:", error);
    }
  };
  const handleVerifyClick = () => {
    if (isPhoneValid) {
      let verifyOTP = isResendOTP
        ? confirmationResult
        : props.confirmationResult;
     // console.log(verifyOTP, "verifyOTP");
      verifyOTP
        .confirm(otpCode)
        .then((result) => {
          // User signed in successfully.
          const user = result.user;
          handleLogin(user);
        })
        .catch((error) => {
          // User couldn't sign in (bad verification code?)
          toast.error("Invalid OTP Code,Please try again", {
            style: { width: "350px" },
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        });
    }
  };
  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundImage: 'url("./images/LoginBackground.png")',
        backgroundSize: "cover",
        overflow: "hidden",
      }}
    >
      <Row style={{ justifyContent: "center" }}>
        <Col lg="10" md="10" sm="10" xs="10" className="Auth-form">
          <div
            style={{
              marginLeft: "24px",
              marginRight: "24px",
              marginTop: "40px",
            }}
          >
            <label
              className="netural-black"
              style={{ fontSize: "24px", fontWeight: "bold" }}
            >
              Verify phone number
            </label>
            <label
              className="netural-grey"
              style={{ fontSize: "16px", marginTop: "5px" }}
            >
              Please enter the verification code sent to
            </label>
            <div>
              <label
                className="netural-grey"
                style={{
                  fontSize: "16px",
                  fontWeight: "bold",
                  marginTop: "5px",
                }}
              >
                {props.formattedNumber}
              </label>
              <label
                style={{
                  color: "#E97101",
                  fontWeight: "bold",
                  marginLeft: "5px",
                }}
                onClick={props.handleEditPhoneNumber}
              >
                Edit
              </label>
            </div>
            <div style={{ marginTop: "32px" }}>
              <FloatingLabel
                controlId="floatingInput"
                label="Enter OTP"
                onChange={(event) => {
                  let otpCode = event.target.value;
                  if (otpCode.length === 6) {
                    setPhoneValid(true);
                    setOTPCode(otpCode);
                  } else {
                    setPhoneValid(false);
                  }
                }}
              >
                <Form.Control type="otp" placeholder="XXXX" maxLength={6} />
              </FloatingLabel>
            </div>
            <div
              style={{
                marginTop: "24px",
                marginBottom: "40px",
              }}
            >
              <CustomButton
                title={"Verify and proceed"}
                buttonType={"validation"}
                isValid={isPhoneValid}
                handleButtonClick={handleVerifyClick}
                processing={loading}
                processingText={"Loading..."}
              />
              <div
                style={{
                  justifyContent: "center",
                  display: "flex",
                  marginTop: "16px",
                }}
              >
                {!isResendOTP ? (
                  resendEnabled ? (
                    <label>
                      Didn’t receive the code?{" "}
                      <label
                        style={{ color: "#E97101", fontWeight: "bold" }}
                        onClick={() => {
                          sendOTP();
                        }}
                      >
                        {" "}
                        Resend code
                      </label>
                    </label>
                  ) : (
                    <label>
                      Resend OTP in{" "}
                      <label style={{ color: "#E97101", fontWeight: "bold" }}>
                        ({timer} seconds)
                      </label>
                    </label>
                  )
                ) : (
                  <div>
                    <label>Didn’t receive the code? </label>
                    <label style={{ color: "#E97101", fontWeight: "bold" }}>
                      Please try again later.
                    </label>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <div id="recaptcha-container"></div>
    </div>
  );
};

export default VerifyOTP;