import { gql } from "@apollo/client";

export const FETCH_BOOKING_FOR_SELECTED_DATE = gql`
  query fetchBookingsForSelectedDate(
    $selectedDate: String!
    $businessId: String!
  ) {
    fetchBookingsForSelectedDate(
      selectedDate: $selectedDate
      businessId: $businessId
    ) {
      bookings {
        bookingId
        serviceId
        serviceTitle
        timeSlot
        error
      }
    }
  }
`;

export const FETCH_BOOOKING_DETAILS_BY_ID = gql`
query fetchBookingDetailsById($bookingId: String!) {
  fetchBookingDetailsById(bookingId: $bookingId) {
    guestDetails {
      bookedSpots
      email
      phoneNumber
      primaryGuest
      secondaryGuestList
    }
    serviceDetails {
      capacity
      date
      discountedPrice
      duration {
        hours
        minutes
      }
      location
      price
      remainingSpots
      serviceType
      time
      totalSpotsBooked
    }
  }
}`;
