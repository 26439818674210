import { Col, Image, Row } from "react-bootstrap";
import { netural400, neturalBlack } from "../../constants/color";

const SalesProductList = ({ media }) => {
  return (
    <div
      style={{
        width: "100%",
        backgroundColor: "white",
        borderRadius: "12px",
        boxShadow: "1px 1px 10px 0px #DADADA",
      }}
    >
      {media.map((itemList) => {
        // console.log(itemList,"ITEM LIST")
        return (
          <div
            style={{
              padding: "3%",
              borderBottom: "1px solid #dadada",
            }}
          >
            <Row>
              <Col
                lg="2"
                md="2"
                sm="2"
                xs="2"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Image
                  src={`${process.env.REACT_APP_HOST_URL}${itemList.uri}`}
                  // src="../../images/profile.jpg"
                  alt="Service Image"
                  style={{
                    width: "80px",
                    height: "80px",
                    borderRadius: "12px",
                  }}
                />
              </Col>
              <Col
                lg="10"
                md="10"
                sm="10"
                xs="10"
                style={{
                  display: "grid",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  <label
                    style={{
                      fontSize: "16px",
                      fontWeight: "500",
                      color: neturalBlack,
                    }}
                  >
                    {itemList.title}
                  </label>
                  <label
                    style={{
                      fontSize: "16px",
                      fontWeight: "500",
                      color: neturalBlack,
                    }}
                  >
                    $ {itemList.price}
                  </label>
                </div>
                {itemList.variantCombination != null &&
                  <label
                      style={{
                        fontSize: "14px",
                        color: netural400,
                      }}
                    >
                    {itemList.variantCombination}
                  </label>
                }
                <div>
                  <label
                    style={{
                      fontSize: "14px",
                      color: netural400,
                    }}
                  >
                    Qty: {itemList.quantity}
                  </label>
                </div>
              </Col>
            </Row>
          </div>
        );
      })}
    </div>
  );
};

export default SalesProductList;
