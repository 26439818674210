import React from 'react';
import {Navigate } from 'react-router-dom';
import { useSelector } from "react-redux";
import { isAdminUser } from '../reducers/user/user.selector';

const AdminProtected = ({ element: Element, ...props }) => {
  const  isAuthenticatedAndAdmin =  useSelector(isAdminUser);
  const isAuthenticated = localStorage.getItem("isAuthenticated");
  return isAuthenticated? isAuthenticatedAndAdmin ? (
    <Element {...props} />
  ) : ( 
    <Navigate to="/" replace />
  ): ( 
    <Navigate to="/login" replace />
  );
};

export default AdminProtected;
