import { Fragment } from "react";
import { Col } from "react-bootstrap";
import { netural50, neturalBlack } from "../../constants/color";
import { BsPencilSquare, BsTrash3 } from "react-icons/bs";

const ProductVariantCardDesign = ({
  variant,
  index,
  addVariant,
  isEdit,
  handleEdit,
  handleDeleteVariant,
}) => {
  const { variantType, variantOptions, variantId } = variant;
  return (
    <div style={{ display: "flex" }} key={index}>
      <Col md="8" lg="8" style={{ marginBottom: "5%" }}>
        <div
          style={{
            backgroundColor: netural50,
            borderRadius: "12px",
            padding: "3%",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              alignItems: "center",
              marginTop: "2%",
            }}
          >
            <Fragment>
              <label
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  color: neturalBlack,
                }}
              >
                {variantType}
              </label>
            </Fragment>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "12%",
              }}
            >
              <BsPencilSquare
                size={18}
                style={addVariant || isEdit ? { pointerEvents: "none" } : null}
                onClick={() => {
                  handleEdit(index);
                }}
              />
              <BsTrash3
                size={18}
                onClick={() => handleDeleteVariant(variantId)}
              />
            </div>
          </div>
          <div style={{ marginTop: "5%", marginBottom: "3%" }}>
            {variantOptions.map((data, optionIndex) => (
              <label
                key={optionIndex}
                style={{
                  fontSize: "16px",
                  fontWeight: "bold",
                  color: "white",
                  backgroundColor: "#7D7D7D",
                  borderRadius: "12px",
                  paddingLeft: "1.5%",
                  paddingRight: "1.5%",
                  paddingTop: "0.5%",
                  paddingBottom: "0.5%",
                  marginRight: "2%",
                }}
              >
                {data}
              </label>
            ))}
          </div>
        </div>
      </Col>
    </div>
  );
};

export default ProductVariantCardDesign;
