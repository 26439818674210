import { gql } from "@apollo/client";

export const CREATE_PRODUCT_SELLING_PLAN = gql`
mutation createProductsSellingPlan($createSellingPlanInput: createSellingPlanInput) {
    createProductsSellingPlan(createSellingPlanInput: $createSellingPlanInput) {
      id
      planName
      active
      products {
        productId
      }
      minimumCycle
      discount
      createdAt
      businessId
      frequencyType
      user_id
    }
  }`;