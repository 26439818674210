import { React, useState } from "react";
import { Form, Col, Row } from "react-bootstrap";
import { netural400 } from "../../constants/color";
import { useEffect } from "react";
import { IconContext } from "react-icons";
import { IoInformationCircleOutline } from "react-icons/io5";
import IllustrationImage from "../../components/shared/illustration-image/illustration-image.component";
import FormFooterButtons from "../form-footer-buttons/form-footer-buttons.component";
import SectionTitle from "../../components/section-title/section-title.component";

const ServiceDurationForm = ({
  handleBackClick,
  handleNextClick,
  setServiceDuration,
  formValue,
}) => {
  // Service Duration time
  const [serviceTimeHour, setServiceTimeHour] = useState("");
  const [serviceTimeHourValid, setServiceTimeHourValid] = useState(false);

  const [serviceTimeMin, setServiceTimeMin] = useState("");
  const [serviceTimeMinValid, setServiceTimeMinValid] = useState(false);

  // Break time
  const [breakTimeFrom, setBreakTimeFrom] = useState("00");
  const [breakTimeTo, setBreakTimeTo] = useState("00");
  const [isBreakTimeToValid, setIsBrekTimeToValid] = useState(false);

  // Operating days and hours
  // Sunday
  const [isSelectedSunday, setIsSelectedSunday] = useState(false);
  const [operatingSundayFrom, setOperatingSundayFrom] = useState("");
  const [operatingSundayTo, setOperatingSundayTo] = useState("");
  const [isOperatingSundayFromValid, setIsOperatingSundayFromValid] =
    useState(false);
  const [isOperatingSundayToValid, setIsOperatingSundayToValid] =
    useState(false);
  let slotsBeforeBreakSunday = [];
  let slotsAfterBreakSunday = [];

  // Monday
  const [isSelectedMonday, setIsSelectedMonday] = useState(false);
  const [operatingMondayFrom, setOperatingMondayFrom] = useState("");
  const [operatingMondayTo, setOperatingMondayTo] = useState("");
  const [isOperatingMondayFromValid, setIsOperatingMondayFromValid] =
    useState(false);
  const [isOperatingMondayToValid, setIsOperatingMondayToValid] =
    useState(false);
  let slotsBeforeBreakMonday = [];
  let slotsAfterBreakMonday = [];

  // Tuesday
  const [isSelectedTuesday, setIsSelectedTuesday] = useState(false);
  const [operatingTuesdayFrom, setOperatingTuesdayFrom] = useState("");
  const [operatingTuesdayTo, setOperatingTuesdayTo] = useState("");
  const [isOperatingTuesdayFromValid, setIsOperatingTuesdayFromValid] =
    useState(false);
  const [isOperatingTuesdayToValid, setIsOperatingTuesdayToValid] =
    useState(false);
  let slotsBeforeBreakTuesday = [];
  let slotsAfterBreakTuesday = [];

  // Wednesday
  const [isSelectedWednesday, setIsSelectedWednesday] = useState(false);
  const [operatingWednesdayFrom, setOperatingWednesdayFrom] = useState("");
  const [operatingWednesdayTo, setOperatingWednesdayTo] = useState("");
  const [isOperatingWednesdayFromValid, setIsOperatingWednesdayFromValid] =
    useState(false);
  const [isOperatingWednesdayToValid, setIsOperatingWednesdayToValid] =
    useState(false);
  let slotsBeforeBreakWednesday = [];
  let slotsAfterBreakWednesday = [];

  // Thursday
  const [isSelectedThursday, setIsSelectedThursday] = useState(false);
  const [operatingThursdayFrom, setOperatingThursdayFrom] = useState("");
  const [operatingThursdayTo, setOperatingThursdayTo] = useState("");
  const [isOperatingThursdayFromValid, setIsOperatingThursdayFromValid] =
    useState(false);
  const [isOperatingThursdayToValid, setIsOperatingThursdayToValid] =
    useState(false);
  let slotsBeforeBreakThursday = [];
  let slotsAfterBreakThursday = [];

  // Friday
  const [isSelectedFriday, setIsSelectedFriday] = useState(false);
  const [operatingFridayFrom, setOperatingFridayFrom] = useState("");
  const [operatingFridayTo, setOperatingFridayTo] = useState("");
  const [isOperatingFridayFromValid, setIsOperatingFridayFromValid] =
    useState(false);
  const [isOperatingFridayToValid, setIsOperatingFridayToValid] =
    useState(false);
  let slotsBeforeBreakFriday = [];
  let slotsAfterBreakFriday = [];

  // Saturday
  const [isSelectedSaturday, setIsSelectedSaturday] = useState(false);
  const [operatingSaturdayFrom, setOperatingSaturdayFrom] = useState("");
  const [operatingSaturdayTo, setOperatingSaturdayTo] = useState("");
  const [isOperatingSaturdayFromValid, setIsOperatingSaturdayFromValid] =
    useState(false);
  const [isOperatingSaturdayToValid, setIsOperatingSaturdayToValid] =
    useState(false);
  let slotsBeforeBreakSaturday = [];
  let slotsAfterBreakSaturday = [];

  //General error
  const [isGeneralError, setIsGeneralError] = useState(false);

  useEffect(() => {
    if (formValue.serviceTimeHour && formValue.serviceTimeHour !== "") {
      setServiceTimeHour(formValue.serviceTimeHour);
      setServiceTimeMin(formValue.serviceTimeMin);

      setBreakTimeFrom(formValue.serviceBreakFrom);
      setBreakTimeTo(formValue.serviceBreakTo);

      setIsSelectedSunday(formValue.serviceOperatingSunday);
      setOperatingSundayFrom(formValue.serviceOperatingSundayFrom);
      setOperatingSundayTo(formValue.serviceOperatingSundayTo);

      setIsSelectedMonday(formValue.serviceOperatingMonday);
      setOperatingMondayFrom(formValue.serviceOperatingMondayFrom);
      setOperatingMondayTo(formValue.serviceOperatingMondayTo);

      setIsSelectedTuesday(formValue.serviceOperatingTuesday);
      setOperatingTuesdayFrom(formValue.serviceOperatingTuesdayFrom);
      setOperatingTuesdayTo(formValue.serviceOperatingTuesdayTo);

      setIsSelectedWednesday(formValue.serviceOperatingWednesday);
      setOperatingWednesdayFrom(formValue.serviceOperatingWednesdayFrom);
      setOperatingWednesdayTo(formValue.serviceOperatingWednesdayTo);

      setIsSelectedThursday(formValue.serviceOperatingThursday);
      setOperatingThursdayFrom(formValue.serviceOperatingThursdayFrom);
      setOperatingThursdayTo(formValue.serviceOperatingThursdayTo);

      setIsSelectedFriday(formValue.serviceOperatingFriday);
      setOperatingFridayFrom(formValue.serviceOperatingFridayFrom);
      setOperatingFridayTo(formValue.serviceOperatingFridayTo);

      setIsSelectedSaturday(formValue.serviceOperatingSaturday);
      setOperatingSaturdayFrom(formValue.serviceOperatingSaturdayFrom);
      setOperatingSaturdayTo(formValue.serviceOperatingSaturdayTo);
    }
  }, []);

  const generateSlotArray = () => {
    let serviceTimeInMin =
      parseInt(serviceTimeHour) * 60 + parseInt(serviceTimeMin);
    if (
      breakTimeFrom === "00" ||
      parseInt(breakTimeTo) < parseInt(operatingSundayFrom) ||
      parseInt(breakTimeTo) < parseInt(operatingMondayFrom) ||
      parseInt(breakTimeTo) < parseInt(operatingTuesdayFrom) ||
      parseInt(breakTimeTo) < parseInt(operatingWednesdayFrom) ||
      parseInt(breakTimeTo) < parseInt(operatingThursdayFrom) ||
      parseInt(breakTimeTo) < parseInt(operatingFridayFrom) ||
      parseInt(breakTimeTo) < parseInt(operatingSaturdayTo)
    ) {
      let serviceTimeFromInMinsSunday =
        parseInt(operatingSundayTo) - parseInt(operatingSundayFrom);
      let TempslotsBeforeBreakSunday =
        parseInt(serviceTimeFromInMinsSunday * 60) / serviceTimeInMin; //2
      let elementBeforeSunday = Array.from(
        { length: TempslotsBeforeBreakSunday },
        (_, index) => index + 1
      );
      slotsBeforeBreakSunday = elementBeforeSunday;

      let serviceTimeFromInMinsMonday =
        parseInt(operatingMondayTo) - parseInt(operatingMondayFrom);
      let TempslotsBeforeBreakMonday =
        parseInt(serviceTimeFromInMinsMonday * 60) / serviceTimeInMin; //2
      let elementBeforeMonday = Array.from(
        { length: TempslotsBeforeBreakMonday },
        (_, index) => index + 1
      );
      slotsBeforeBreakMonday = elementBeforeMonday;

      let serviceTimeFromInMinsTuesday =
        parseInt(operatingTuesdayTo) - parseInt(operatingTuesdayFrom);
      let TempslotsBeforeBreakTuesday =
        parseInt(serviceTimeFromInMinsTuesday * 60) / serviceTimeInMin; //2
      let elementBeforeTuesday = Array.from(
        { length: TempslotsBeforeBreakTuesday },
        (_, index) => index + 1
      );
      slotsBeforeBreakTuesday = elementBeforeTuesday;

      let serviceTimeFromInMinsWednesday =
        parseInt(operatingWednesdayTo) - parseInt(operatingWednesdayFrom);
      let TempslotsBeforeBreakWednesday =
        parseInt(serviceTimeFromInMinsWednesday * 60) / serviceTimeInMin; //2
      let elementBeforeWednesday = Array.from(
        { length: TempslotsBeforeBreakWednesday },
        (_, index) => index + 1
      );
      slotsBeforeBreakWednesday = elementBeforeWednesday;

      let serviceTimeFromInMinsThursday =
        parseInt(operatingThursdayTo) - parseInt(operatingThursdayFrom);
      let TempslotsBeforeBreakThursday =
        parseInt(serviceTimeFromInMinsThursday * 60) / serviceTimeInMin; //2
      let elementBeforeThursday = Array.from(
        { length: TempslotsBeforeBreakThursday },
        (_, index) => index + 1
      );
      slotsBeforeBreakThursday = elementBeforeThursday;

      let serviceTimeFromInMinsFriday =
        parseInt(operatingFridayTo) - parseInt(operatingFridayFrom);
      let TempslotsBeforeBreakFriday =
        parseInt(serviceTimeFromInMinsFriday * 60) / serviceTimeInMin; //2
      let elementBeforeFriday = Array.from(
        { length: TempslotsBeforeBreakFriday },
        (_, index) => index + 1
      );
      slotsBeforeBreakFriday = elementBeforeFriday;

      let serviceTimeFromInMinsSaturday =
        parseInt(operatingSaturdayTo) - parseInt(operatingSaturdayFrom);
      let TempSlotsBeforeBreakSaturday =
        parseInt(serviceTimeFromInMinsSaturday * 60) / serviceTimeInMin; //2
      let elementBeforeSaturday = Array.from(
        { length: TempSlotsBeforeBreakSaturday },
        (_, index) => index + 1
      );
      slotsBeforeBreakSaturday = elementBeforeSaturday;
    } else {
      let serviceTimeFromInMinsSunday =
        parseInt(breakTimeFrom) - parseInt(operatingSundayFrom);
      let serviceTimeToInMinsSunday =
        parseInt(operatingSundayTo) - parseInt(breakTimeTo);
      let TempslotsBeforeBreakSunday =
        parseInt(serviceTimeFromInMinsSunday * 60) / serviceTimeInMin; //2
      let TempslotsAfterBreakSunday =
        parseInt(serviceTimeToInMinsSunday * 60) / serviceTimeInMin; //12
      let elementBeforeSunday = Array.from(
        { length: TempslotsBeforeBreakSunday },
        (_, index) => index + 1
      );
      let elementAfterSunday = Array.from(
        { length: TempslotsAfterBreakSunday },
        (_, index) => index + 1
      );
      slotsBeforeBreakSunday = elementBeforeSunday;
      slotsAfterBreakSunday = elementAfterSunday;

      let serviceTimeFromInMinsMonday =
        parseInt(breakTimeFrom) - parseInt(operatingMondayFrom);
      let serviceTimeToInMinsMonday =
        parseInt(operatingMondayTo) - parseInt(breakTimeTo);
      let TempslotsBeforeBreakMonday =
        parseInt(serviceTimeFromInMinsMonday * 60) / serviceTimeInMin; //2
      let TempslotsAfterBreakMonday =
        parseInt(serviceTimeToInMinsMonday * 60) / serviceTimeInMin; //12
      let elementBeforeMonday = Array.from(
        { length: TempslotsBeforeBreakMonday },
        (_, index) => index + 1
      );
      let elementAfterMonday = Array.from(
        { length: TempslotsAfterBreakMonday },
        (_, index) => index + 1
      );
      slotsBeforeBreakMonday = elementBeforeMonday;
      slotsAfterBreakMonday = elementAfterMonday;

      let serviceTimeFromInMinsTuesday =
        parseInt(breakTimeFrom) - parseInt(operatingTuesdayFrom);
      let serviceTimeToInMinsTuesday =
        parseInt(operatingTuesdayTo) - parseInt(breakTimeTo);
      let TempslotsBeforeBreakTuesday =
        parseInt(serviceTimeFromInMinsTuesday * 60) / serviceTimeInMin; //2
      let TempslotsAfterBreakTuesday =
        parseInt(serviceTimeToInMinsTuesday * 60) / serviceTimeInMin; //12
      let elementBeforeTuesday = Array.from(
        { length: TempslotsBeforeBreakTuesday },
        (_, index) => index + 1
      );
      let elementAfterTuesday = Array.from(
        { length: TempslotsAfterBreakTuesday },
        (_, index) => index + 1
      );
      slotsBeforeBreakTuesday = elementBeforeTuesday;
      slotsAfterBreakTuesday = elementAfterTuesday;

      let serviceTimeFromInMinsWednesday =
        parseInt(breakTimeFrom) - parseInt(operatingWednesdayFrom);
      let serviceTimeToInMinsWednesday =
        parseInt(operatingWednesdayTo) - parseInt(breakTimeTo);
      let TempslotsBeforeBreakWednesday =
        parseInt(serviceTimeFromInMinsWednesday * 60) / serviceTimeInMin; //2
      let TempslotsAfterBreakWednesday =
        parseInt(serviceTimeToInMinsWednesday * 60) / serviceTimeInMin; //12
      let elementBeforeWednesday = Array.from(
        { length: TempslotsBeforeBreakWednesday },
        (_, index) => index + 1
      );
      let elementAfterWednesday = Array.from(
        { length: TempslotsAfterBreakWednesday },
        (_, index) => index + 1
      );
      slotsBeforeBreakWednesday = elementBeforeWednesday;
      slotsAfterBreakWednesday = elementAfterWednesday;

      let serviceTimeFromInMinsThursday =
        parseInt(breakTimeFrom) - parseInt(operatingThursdayFrom);
      let serviceTimeToInMinsThursday =
        parseInt(operatingThursdayTo) - parseInt(breakTimeTo);
      let TempslotsBeforeBreakThursday =
        parseInt(serviceTimeFromInMinsThursday * 60) / serviceTimeInMin; //2
      let TempslotsAfterBreakThursday =
        parseInt(serviceTimeToInMinsThursday * 60) / serviceTimeInMin; //12
      let elementBeforeThursday = Array.from(
        { length: TempslotsBeforeBreakThursday },
        (_, index) => index + 1
      );
      let elementAfterThursday = Array.from(
        { length: TempslotsAfterBreakThursday },
        (_, index) => index + 1
      );
      slotsBeforeBreakThursday = elementBeforeThursday;
      slotsAfterBreakThursday = elementAfterThursday;

      let serviceTimeFromInMinsFriday =
        parseInt(breakTimeFrom) - parseInt(operatingFridayFrom);
      let serviceTimeToInMinsFriday =
        parseInt(operatingFridayTo) - parseInt(breakTimeTo);
      let TempslotsBeforeBreakFriday =
        parseInt(serviceTimeFromInMinsFriday * 60) / serviceTimeInMin; //2
      let TempslotsAfterBreakFriday =
        parseInt(serviceTimeToInMinsFriday * 60) / serviceTimeInMin; //12
      let elementBeforeFriday = Array.from(
        { length: TempslotsBeforeBreakFriday },
        (_, index) => index + 1
      );
      let elementAfterFriday = Array.from(
        { length: TempslotsAfterBreakFriday },
        (_, index) => index + 1
      );
      slotsBeforeBreakFriday = elementBeforeFriday;
      slotsAfterBreakFriday = elementAfterFriday;

      let serviceTimeFromInMinsSaturday =
        parseInt(breakTimeFrom) - parseInt(operatingSaturdayFrom);
      let serviceTimeToInMinsSaturday =
        parseInt(operatingSaturdayTo) - parseInt(breakTimeTo);
      let TempSlotsBeforeBreakSaturday =
        parseInt(serviceTimeFromInMinsSaturday * 60) / serviceTimeInMin; //2
      let TempSlotsAfterBreakSaturday =
        parseInt(serviceTimeToInMinsSaturday * 60) / serviceTimeInMin; //12
      let elementBeforeSaturday = Array.from(
        { length: TempSlotsBeforeBreakSaturday },
        (_, index) => index + 1
      );
      let elementAfterSaturday = Array.from(
        { length: TempSlotsAfterBreakSaturday },
        (_, index) => index + 1
      );
      slotsBeforeBreakSaturday = elementBeforeSaturday;
      slotsAfterBreakSaturday = elementAfterSaturday;
    }
  };

  const handleSubmit = () => {
    if (serviceTimeHour === "00" || serviceTimeHour === "") {
      // Service Time hour
      setServiceTimeHourValid(true);
    } else if (serviceTimeMin === "00" || serviceTimeMin === "") {
      // Service Time min
      setServiceTimeMinValid(true);
    } else if (serviceTimeHour === "00" && serviceTimeMin === "00") {
      setServiceTimeHourValid(true);
      setServiceTimeMinValid(true);
    } else if (
      breakTimeFrom !== "00" &&
      (breakTimeTo === "00" || breakTimeTo === "")
    ) {
      // Break time if selected from
      setIsBrekTimeToValid(true);
    } else if (
      isSelectedSunday &&
      (operatingSundayFrom === "0" || operatingSundayFrom === "")
    ) {
      // Sunday from
      setIsOperatingSundayFromValid(true);
    } else if (
      isSelectedSunday &&
      (operatingSundayTo === "0" || operatingSundayTo === "")
    ) {
      // Sunday To
      setIsOperatingSundayToValid(true);
    } else if (
      isSelectedMonday &&
      (operatingMondayFrom === "0" || operatingMondayFrom === "")
    ) {
      // Monday From
      setIsOperatingMondayFromValid(true);
    } else if (
      isSelectedMonday &&
      (operatingMondayTo === "0" || operatingMondayTo === "")
    ) {
      //Monday To
      setIsOperatingMondayToValid(true);
    } else if (
      isSelectedTuesday &&
      (operatingTuesdayFrom === "0" || operatingTuesdayFrom === "")
    ) {
      // Tuesday From
      setIsOperatingTuesdayFromValid(true);
    } else if (
      isSelectedTuesday &&
      (operatingTuesdayTo === "0" || operatingTuesdayTo === "")
    ) {
      // Tuesday To
      setIsOperatingTuesdayToValid(true);
    } else if (
      isSelectedWednesday &&
      (operatingWednesdayFrom === "0" || operatingWednesdayFrom === "")
    ) {
      // Wednesday From
      setIsOperatingWednesdayFromValid(true);
    } else if (
      isSelectedWednesday &&
      (operatingWednesdayTo === "0" || operatingWednesdayTo === "")
    ) {
      // Wednesday To
      setIsOperatingWednesdayToValid(true);
    } else if (
      isSelectedThursday &&
      (operatingThursdayFrom === "0" || operatingThursdayFrom === "")
    ) {
      // Thursday From
      setIsOperatingThursdayFromValid(true);
    } else if (
      isSelectedThursday &&
      (operatingThursdayTo === "0" || operatingThursdayTo === "")
    ) {
      // Thursday To
      setIsOperatingThursdayToValid(true);
    } else if (
      isSelectedFriday &&
      (operatingFridayFrom === "0" || operatingFridayFrom === "")
    ) {
      // Friday From
      setIsOperatingFridayFromValid(true);
    } else if (
      isSelectedFriday &&
      (operatingFridayTo === "0" || operatingFridayTo === "")
    ) {
      // Friday To
      setIsOperatingFridayToValid(true);
    } else if (
      isSelectedSaturday &&
      (operatingSaturdayFrom === "0" || operatingSaturdayFrom === "")
    ) {
      // Saturday From
      setIsOperatingSaturdayFromValid(true);
    } else if (
      isSelectedSaturday &&
      (operatingSaturdayTo === "0" || operatingSaturdayTo === "")
    ) {
      // Saturday To
      setIsOperatingSaturdayToValid(true);
    } else if (
      !isSelectedSunday &&
      !isSelectedMonday &&
      !isSelectedTuesday &&
      !isSelectedWednesday &&
      !isSelectedThursday &&
      !isSelectedFriday &&
      !isSelectedSaturday
    ) {
      setIsGeneralError(true);
    } else {
      generateSlotArray();
      setServiceDuration(
        serviceTimeHour,
        serviceTimeMin,
        breakTimeFrom,
        breakTimeTo,
        isSelectedSunday,
        operatingSundayFrom,
        operatingSundayTo,
        slotsBeforeBreakSunday,
        slotsAfterBreakSunday,
        isSelectedMonday,
        operatingMondayFrom,
        operatingMondayTo,
        slotsBeforeBreakMonday,
        slotsAfterBreakMonday,
        isSelectedTuesday,
        operatingTuesdayFrom,
        operatingTuesdayTo,
        slotsBeforeBreakTuesday,
        slotsAfterBreakTuesday,
        isSelectedWednesday,
        operatingWednesdayFrom,
        operatingWednesdayTo,
        slotsBeforeBreakWednesday,
        slotsAfterBreakWednesday,
        isSelectedThursday,
        operatingThursdayFrom,
        operatingThursdayTo,
        slotsBeforeBreakThursday,
        slotsAfterBreakThursday,
        isSelectedFriday,
        operatingFridayFrom,
        operatingFridayTo,
        slotsBeforeBreakFriday,
        slotsAfterBreakFriday,
        isSelectedSaturday,
        operatingSaturdayFrom,
        operatingSaturdayTo,
        slotsBeforeBreakSaturday,
        slotsAfterBreakSaturday
      );
      handleNextClick();
    }
  };

  return (
    <div>
      <div>
        <div
          style={{
            marginTop: "3%",
            backgroundColor: "#FFF3DA",
            padding: 16,
            marginRight: "5%",
          }}
        >
          <IconContext.Provider value={{ size: 22, color: "black" }}>
            <IoInformationCircleOutline />
          </IconContext.Provider>
          <label
            style={{
              fontSize: "16px",
              fontWeight: "500",
              color: "#545454",
              marginLeft: 10,
            }}
          >
            You won’t be able to edit this information later.
          </label>
        </div>
        <div style={{ marginTop: "5%", marginBottom: "3%" }}>
          <SectionTitle
            title={"Service duration time and schedule"}
            subtitle={"Select one of the fields below"}
          />
        </div>
      </div>
      <Form>
        <Row className="mb-3">
          <Col md="6" lg="6" style={{ marginLeft: "3%" }}>
            <div style={{ display: "flex", marginTop: "20px" }}>
              <Col md="12" style={{ color: "#A6A6A6", paddingRight: "10px" }}>
                <Form.Group
                  controlId="validationCustom01"
                  style={{ color: "#A6A6A6" }}
                >
                  <Form.Label style={{ color: netural400 }}>
                    Duration service time
                  </Form.Label>
                  <div style={{ display: "flex" }}>
                    <Col
                      md="6"
                      style={{ color: "#A6A6A6", paddingRight: "2%" }}
                    >
                      <Form.Select
                        aria-label="Default select example"
                        style={{ borderRadius: "12px" }}
                        required
                        onChange={(value) => {
                          setServiceTimeHourValid(false);
                          setServiceTimeMinValid(false);
                          setServiceTimeHour(value.target.value);
                        }}
                      >
                        <option value="00">From</option>
                        <option
                          selected={serviceTimeHour === "0" ? true : false}
                          value="0"
                        >
                          0 hour
                        </option>
                        <option
                          selected={serviceTimeHour === "1" ? true : false}
                          value="1"
                        >
                          1 hour
                        </option>
                        <option
                          selected={serviceTimeHour === "2" ? true : false}
                          value="2"
                        >
                          2 hour
                        </option>
                        <option
                          selected={serviceTimeHour === "3" ? true : false}
                          value="3"
                        >
                          3 hour
                        </option>
                        <option
                          selected={serviceTimeHour === "4" ? true : false}
                          value="4"
                        >
                          4 hour
                        </option>
                        <option
                          selected={serviceTimeHour === "8" ? true : false}
                          value="8"
                        >
                          8 hour
                        </option>
                        <option
                          selected={serviceTimeHour === "10" ? true : false}
                          value="10"
                        >
                          10 hour
                        </option>
                        <option
                          selected={serviceTimeHour === "12" ? true : false}
                          value="12"
                        >
                          12 hour
                        </option>
                        <option
                          selected={serviceTimeHour === "14" ? true : false}
                          value="14"
                        >
                          14 hour
                        </option>
                      </Form.Select>
                      {serviceTimeHourValid ? (
                        <label style={{ fontSize: ".875em", color: "#dc3545" }}>
                          Please select a valid Service Time.
                        </label>
                      ) : null}
                    </Col>
                    <Col md="6" style={{ color: "#A6A6A6", paddingLeft: "2%" }}>
                      <Form.Select
                        aria-label="Default select example"
                        style={{ borderRadius: "12px" }}
                        required
                        onChange={(value) => {
                          setServiceTimeMinValid(false);
                          setServiceTimeHourValid(false);
                          setServiceTimeMin(value.target.value);
                        }}
                      >
                        <option value="00">To</option>
                        <option
                          selected={serviceTimeMin === "0" ? true : false}
                          disabled={serviceTimeHour === "0" ? true : false}
                          value="0"
                        >
                          0 minutes
                        </option>
                        <option
                          selected={serviceTimeMin === "15" ? true : false}
                          disabled={serviceTimeHour === "14" ? true : false}
                          value="15"
                        >
                          15 minutes
                        </option>
                        <option
                          selected={serviceTimeMin === "30" ? true : false}
                          disabled={serviceTimeHour === "14" ? true : false}
                          value="30"
                        >
                          30 minutes
                        </option>
                        <option
                          selected={serviceTimeMin === "45" ? true : false}
                          disabled={serviceTimeHour === "14" ? true : false}
                          value="45"
                        >
                          45 minutes
                        </option>
                      </Form.Select>
                      {serviceTimeMinValid ? (
                        <label style={{ fontSize: ".875em", color: "#dc3545" }}>
                          Please select a valid Service Time.
                        </label>
                      ) : null}
                    </Col>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </div>
                </Form.Group>
              </Col>
            </div>
            <div style={{ display: "flex", marginTop: "20px" }}>
              <Col md="12" style={{ color: "#A6A6A6", paddingRight: "10px" }}>
                <Form.Group
                  controlId="validationCustom01"
                  style={{ color: "#A6A6A6" }}
                >
                  <Form.Label style={{ color: netural400 }}>
                    Break Time
                  </Form.Label>
                  <div style={{ display: "flex" }}>
                    <Col
                      md="6"
                      style={{ color: "#A6A6A6", paddingRight: "2%" }}
                    >
                      <Form.Select
                        aria-label="Default select example"
                        style={{ borderRadius: "12px" }}
                        onChange={(value) => {
                          setBreakTimeFrom(value.target.value);
                        }}
                      >
                        <option value="00">From</option>
                        <option
                          selected={breakTimeFrom === "9" ? true : false}
                          value="9"
                        >
                          9:00
                        </option>
                        <option
                          selected={breakTimeFrom === "10" ? true : false}
                          value="10"
                        >
                          10:00
                        </option>
                        <option
                          selected={breakTimeFrom === "11" ? true : false}
                          value="11"
                        >
                          11:00
                        </option>
                        <option
                          selected={breakTimeFrom === "12" ? true : false}
                          value="12"
                        >
                          12:00
                        </option>
                        <option
                          selected={breakTimeFrom === "13" ? true : false}
                          value="13"
                        >
                          13:00
                        </option>
                        <option
                          selected={breakTimeFrom === "14" ? true : false}
                          value="14"
                        >
                          14:00
                        </option>
                        <option
                          selected={breakTimeFrom === "15" ? true : false}
                          value="15"
                        >
                          15:00
                        </option>
                        <option
                          selected={breakTimeFrom === "16" ? true : false}
                          value="16"
                        >
                          16:00
                        </option>
                        <option
                          selected={breakTimeFrom === "17" ? true : false}
                          value="17"
                        >
                          17:00
                        </option>
                        <option
                          selected={breakTimeFrom === "18" ? true : false}
                          value="18"
                        >
                          18:00
                        </option>
                        <option
                          selected={breakTimeFrom === "19" ? true : false}
                          value="19"
                        >
                          19:00
                        </option>
                        <option
                          selected={breakTimeFrom === "20" ? true : false}
                          value="20"
                        >
                          20:00
                        </option>
                      </Form.Select>
                    </Col>
                    <Col md="6" style={{ color: "#A6A6A6", paddingLeft: "2%" }}>
                      <Form.Select
                        aria-label="Default select example"
                        style={{ borderRadius: "12px" }}
                        onChange={(value) => {
                          setBreakTimeTo(value.target.value);
                        }}
                      >
                        <option value="00">To</option>
                        <option
                          selected={breakTimeTo === "9" ? true : false}
                          disabled={parseInt(breakTimeFrom) >= 9 ? true : false}
                          value="9"
                        >
                          9:00
                        </option>
                        <option
                          selected={breakTimeTo === "10" ? true : false}
                          disabled={
                            parseInt(breakTimeFrom) >= 10 ? true : false
                          }
                          value="10"
                        >
                          10:00
                        </option>
                        <option
                          selected={breakTimeTo === "11" ? true : false}
                          disabled={
                            parseInt(breakTimeFrom) >= 11 ? true : false
                          }
                          value="11"
                        >
                          11:00
                        </option>
                        <option
                          selected={breakTimeTo === "12" ? true : false}
                          disabled={
                            parseInt(breakTimeFrom) >= 12 ? true : false
                          }
                          value="12"
                        >
                          12:00
                        </option>
                        <option
                          selected={breakTimeTo === "13" ? true : false}
                          disabled={
                            parseInt(breakTimeFrom) >= 13 ? true : false
                          }
                          value="13"
                        >
                          13:00
                        </option>
                        <option
                          selected={breakTimeTo === "14" ? true : false}
                          disabled={
                            parseInt(breakTimeFrom) >= 14 ? true : false
                          }
                          value="14"
                        >
                          14:00
                        </option>
                        <option
                          selected={breakTimeTo === "15" ? true : false}
                          disabled={
                            parseInt(breakTimeFrom) >= 15 ? true : false
                          }
                          value="15"
                        >
                          15:00
                        </option>
                        <option
                          selected={breakTimeTo === "16" ? true : false}
                          disabled={
                            parseInt(breakTimeFrom) >= 16 ? true : false
                          }
                          value="16"
                        >
                          16:00
                        </option>
                        <option
                          selected={breakTimeTo === "17" ? true : false}
                          disabled={
                            parseInt(breakTimeFrom) >= 17 ? true : false
                          }
                          value="17"
                        >
                          17:00
                        </option>
                        <option
                          selected={breakTimeTo === "18" ? true : false}
                          disabled={
                            parseInt(breakTimeFrom) >= 18 ? true : false
                          }
                          value="18"
                        >
                          18:00
                        </option>
                        <option
                          selected={breakTimeTo === "19" ? true : false}
                          disabled={
                            parseInt(breakTimeFrom) >= 19 ? true : false
                          }
                          value="19"
                        >
                          19:00
                        </option>
                        <option
                          selected={breakTimeTo === "20" ? true : false}
                          disabled={
                            parseInt(breakTimeFrom) >= 20 ? true : false
                          }
                          value="20"
                        >
                          20:00
                        </option>
                      </Form.Select>
                      {isBreakTimeToValid ? (
                        <label style={{ fontSize: ".875em", color: "#dc3545" }}>
                          Please select a valid Break Time.
                        </label>
                      ) : null}
                    </Col>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </div>
                </Form.Group>
              </Col>
            </div>
            <div style={{ display: "flex", marginTop: "20px" }}>
              <Col md="12" lg="12" sm="12">
                <Form.Group
                  controlId="validationCustom01"
                  style={{ color: "#A6A6A6" }}
                >
                  <Form.Label style={{ color: netural400 }}>
                    Operating days and hours
                  </Form.Label>
                  {/* Sunday */}
                  <Col
                    md="12"
                    style={{
                      color: "#A6A6A6",
                      paddingRight: "10px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Col lg="2" md="" sm="">
                      {["checkbox"].map((type) => (
                        <div key={`inline-${type}`} className="mb-3">
                          <Col
                            lg="12"
                            md="12"
                            sm="12"
                            xs="12"
                            style={{ marginTop: 10 }}
                          >
                            <Form.Check
                              inline
                              label="Sun"
                              name="Sunday"
                              type={type}
                              id={`inline-${type}-2`}
                              onClick={() => {
                                setIsGeneralError(false);
                                setIsSelectedSunday(!isSelectedSunday);
                              }}
                              style={{ color: netural400 }}
                              checked={isSelectedSunday ? true : false}
                            />
                          </Col>
                        </div>
                      ))}
                    </Col>
                    <Col lg="5" md="" sm="" style={{ paddingRight: "10px" }}>
                      <Form.Select
                        aria-label="Default select example"
                        style={{ borderRadius: "12px" }}
                        onChange={(value) => {
                          setIsOperatingSundayFromValid(false);
                          setOperatingSundayFrom(value.target.value);
                        }}
                        disabled={isSelectedSunday ? false : true}
                      >
                        <option value="0">From</option>
                        <option
                          selected={operatingSundayFrom === "6" ? true : false}
                          value="6"
                          disabled={
                            parseInt(breakTimeFrom) <= 6 &&
                            parseInt(breakTimeTo) > 6
                              ? true
                              : false
                          }
                        >
                          6:00
                        </option>
                        <option
                          selected={operatingSundayFrom === "7" ? true : false}
                          value="7"
                          disabled={
                            parseInt(breakTimeFrom) <= 7 &&
                            parseInt(breakTimeTo) > 7
                              ? true
                              : false
                          }
                        >
                          7:00
                        </option>
                        <option
                          selected={operatingSundayFrom === "8" ? true : false}
                          value="8"
                          disabled={
                            parseInt(breakTimeFrom) <= 8 &&
                            parseInt(breakTimeTo) > 8
                              ? true
                              : false
                          }
                        >
                          8:00
                        </option>
                        <option
                          selected={operatingSundayFrom === "9" ? true : false}
                          value="9"
                          disabled={
                            parseInt(breakTimeFrom) <= 9 &&
                            parseInt(breakTimeTo) > 9
                              ? true
                              : false
                          }
                        >
                          9:00
                        </option>
                        <option
                          selected={operatingSundayFrom === "10" ? true : false}
                          value="10"
                          disabled={
                            parseInt(breakTimeFrom) <= 10 &&
                            parseInt(breakTimeTo) > 10
                              ? true
                              : false
                          }
                        >
                          10:00
                        </option>
                        <option
                          selected={operatingSundayFrom === "11" ? true : false}
                          value="11"
                          disabled={
                            parseInt(breakTimeFrom) <= 11 &&
                            parseInt(breakTimeTo) > 11
                              ? true
                              : false
                          }
                        >
                          11:00
                        </option>
                        <option
                          selected={operatingSundayFrom === "12" ? true : false}
                          value="12"
                          disabled={
                            parseInt(breakTimeFrom) <= 12 &&
                            parseInt(breakTimeTo) > 12
                              ? true
                              : false
                          }
                        >
                          12:00
                        </option>
                        <option
                          selected={operatingSundayFrom === "13" ? true : false}
                          value="13"
                          disabled={
                            parseInt(breakTimeFrom) >= 13 &&
                            parseInt(breakTimeTo) > 13
                              ? true
                              : false
                          }
                        >
                          13:00
                        </option>
                        <option
                          selected={operatingSundayFrom === "14" ? true : false}
                          value="14"
                          disabled={
                            parseInt(breakTimeFrom) >= 14 &&
                            parseInt(breakTimeTo) > 14
                              ? true
                              : false
                          }
                        >
                          14:00
                        </option>
                        <option
                          selected={operatingSundayFrom === "15" ? true : false}
                          value="15"
                          disabled={
                            parseInt(breakTimeFrom) >= 15 &&
                            parseInt(breakTimeTo) > 15
                              ? true
                              : false
                          }
                        >
                          15:00
                        </option>
                        <option
                          selected={operatingSundayFrom === "16" ? true : false}
                          value="16"
                          disabled={
                            parseInt(breakTimeFrom) >= 16 &&
                            parseInt(breakTimeTo) > 16
                              ? true
                              : false
                          }
                        >
                          16:00
                        </option>
                        <option
                          selected={operatingSundayFrom === "17" ? true : false}
                          value="17"
                          disabled={
                            parseInt(breakTimeFrom) >= 17 &&
                            parseInt(breakTimeTo) > 17
                              ? true
                              : false
                          }
                        >
                          17:00
                        </option>
                        <option
                          selected={operatingSundayFrom === "18" ? true : false}
                          value="18"
                          disabled={
                            parseInt(breakTimeFrom) >= 18 &&
                            parseInt(breakTimeTo) > 18
                              ? true
                              : false
                          }
                        >
                          18:00
                        </option>
                        <option
                          selected={operatingSundayFrom === "19" ? true : false}
                          value="19"
                          disabled={
                            parseInt(breakTimeFrom) >= 19 &&
                            parseInt(breakTimeTo) > 19
                              ? true
                              : false
                          }
                        >
                          19:00
                        </option>
                        <option
                          selected={operatingSundayFrom === "20" ? true : false}
                          value="20"
                          disabled={
                            parseInt(breakTimeFrom) >= 20 &&
                            parseInt(breakTimeTo) > 20
                              ? true
                              : false
                          }
                        >
                          20:00
                        </option>
                      </Form.Select>
                      {isOperatingSundayFromValid ? (
                        <label style={{ fontSize: ".875em", color: "#dc3545" }}>
                          Please select a valid Operating Time.
                        </label>
                      ) : null}
                    </Col>
                    <Col lg="5" md="" sm="" style={{ paddingLeft: "10px" }}>
                      <Form.Select
                        aria-label="Default select example"
                        style={{ borderRadius: "12px" }}
                        onChange={(value) => {
                          setIsOperatingSundayToValid(false);
                          setOperatingSundayTo(value.target.value);
                        }}
                        disabled={
                          isSelectedSunday && operatingSundayFrom !== ""
                            ? false
                            : true
                        }
                      >
                        <option value="0">To</option>
                        <option
                          selected={operatingSundayTo === "6" ? true : false}
                          disabled={
                            parseInt(operatingSundayFrom) >= 6 ||
                            (parseInt(breakTimeFrom) <= 6 &&
                              parseInt(breakTimeTo) > 6)
                              ? true
                              : false
                          }
                          value="6"
                        >
                          6:00
                        </option>
                        <option
                          selected={operatingSundayTo === "7" ? true : false}
                          disabled={
                            parseInt(operatingSundayFrom) >= 7 ||
                            (parseInt(breakTimeFrom) <= 7 &&
                              parseInt(breakTimeTo) > 7)
                              ? true
                              : false
                          }
                          value="7"
                        >
                          7:00
                        </option>
                        <option
                          selected={operatingSundayTo === "8" ? true : false}
                          disabled={
                            parseInt(operatingSundayFrom) >= 8 ||
                            (parseInt(breakTimeFrom) <= 8 &&
                              parseInt(breakTimeTo) > 8)
                              ? true
                              : false
                          }
                          value="8"
                        >
                          8:00
                        </option>
                        <option
                          selected={operatingSundayTo === "9" ? true : false}
                          disabled={
                            parseInt(operatingSundayFrom) >= 9 ||
                            (parseInt(breakTimeFrom) <= 9 &&
                              parseInt(breakTimeTo) > 9)
                              ? true
                              : false
                          }
                          value="9"
                        >
                          9:00
                        </option>
                        <option
                          selected={operatingSundayTo === "10" ? true : false}
                          disabled={
                            parseInt(operatingSundayFrom) >= 10 ||
                            (parseInt(breakTimeFrom) <= 10 &&
                              parseInt(breakTimeTo) > 10)
                              ? true
                              : false
                          }
                          value="10"
                        >
                          10:00
                        </option>
                        <option
                          selected={operatingSundayTo === "11" ? true : false}
                          disabled={
                            parseInt(operatingSundayFrom) >= 11 ||
                            (parseInt(breakTimeFrom) <= 11 &&
                              parseInt(breakTimeTo) > 11)
                              ? true
                              : false
                          }
                          value="11"
                        >
                          11:00
                        </option>
                        <option
                          selected={operatingSundayTo === "12" ? true : false}
                          disabled={
                            parseInt(operatingSundayFrom) >= 12 ||
                            (parseInt(breakTimeFrom) <= 12 &&
                              parseInt(breakTimeTo) > 12)
                              ? true
                              : false
                          }
                          value="12"
                        >
                          12:00
                        </option>
                        <option
                          selected={operatingSundayTo === "13" ? true : false}
                          disabled={
                            parseInt(operatingSundayFrom) >= 13 ||
                            (parseInt(breakTimeFrom) >= 13 &&
                              parseInt(breakTimeTo) > 13)
                              ? true
                              : false
                          }
                          value="13"
                        >
                          13:00
                        </option>
                        <option
                          selected={operatingSundayTo === "14" ? true : false}
                          disabled={
                            parseInt(operatingSundayFrom) >= 14 ||
                            (parseInt(breakTimeFrom) >= 14 &&
                              parseInt(breakTimeTo) > 14)
                              ? true
                              : false
                          }
                          value="14"
                        >
                          14:00
                        </option>
                        <option
                          selected={operatingSundayTo === "15" ? true : false}
                          disabled={
                            parseInt(operatingSundayFrom) >= 15 ||
                            (parseInt(breakTimeFrom) >= 15 &&
                              parseInt(breakTimeTo) > 15)
                              ? true
                              : false
                          }
                          value="15"
                        >
                          15:00
                        </option>
                        <option
                          selected={operatingSundayTo === "16" ? true : false}
                          disabled={
                            parseInt(operatingSundayFrom) >= 16 ||
                            (parseInt(breakTimeFrom) >= 16 &&
                              parseInt(breakTimeTo) > 16)
                              ? true
                              : false
                          }
                          value="16"
                        >
                          16:00
                        </option>
                        <option
                          selected={operatingSundayTo === "17" ? true : false}
                          disabled={
                            parseInt(operatingSundayFrom) >= 17 ||
                            (parseInt(breakTimeFrom) >= 17 &&
                              parseInt(breakTimeTo) > 17)
                              ? true
                              : false
                          }
                          value="17"
                        >
                          17:00
                        </option>
                        <option
                          selected={operatingSundayTo === "18" ? true : false}
                          disabled={
                            parseInt(operatingSundayFrom) >= 18 ||
                            (parseInt(breakTimeFrom) >= 18 &&
                              parseInt(breakTimeTo) > 18)
                              ? true
                              : false
                          }
                          value="18"
                        >
                          18:00
                        </option>
                        <option
                          selected={operatingSundayTo === "19" ? true : false}
                          disabled={
                            parseInt(operatingSundayFrom) >= 19 ||
                            (parseInt(breakTimeFrom) >= 19 &&
                              parseInt(breakTimeTo) > 19)
                              ? true
                              : false
                          }
                          value="19"
                        >
                          19:00
                        </option>
                        <option
                          selected={operatingSundayTo === "20" ? true : false}
                          disabled={
                            parseInt(operatingSundayFrom) >= 20 ||
                            (parseInt(breakTimeFrom) >= 20 &&
                              parseInt(breakTimeTo) > 20)
                              ? true
                              : false
                          }
                          value="20"
                        >
                          20:00
                        </option>
                      </Form.Select>
                      {isOperatingSundayToValid ? (
                        <label style={{ fontSize: ".875em", color: "#dc3545" }}>
                          Please select a valid Operating Time.
                        </label>
                      ) : null}
                    </Col>
                  </Col>
                  {/* Monday */}
                  <Col
                    md="12"
                    style={{
                      color: "#A6A6A6",
                      paddingRight: "10px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Col lg="2" md="" sm="">
                      {["checkbox"].map((type) => (
                        <div key={`inline-${type}`} className="mb-3">
                          <Col
                            lg="12"
                            md="12"
                            sm="12"
                            xs="12"
                            style={{ marginTop: 10 }}
                          >
                            <Form.Check
                              inline
                              label="Mon"
                              name="Monday"
                              type={type}
                              id={`inline-${type}-2`}
                              onClick={() => {
                                setIsGeneralError(false);
                                setIsSelectedMonday(!isSelectedMonday);
                              }}
                              style={{ color: netural400 }}
                              checked={isSelectedMonday ? true : false}
                            />
                          </Col>
                        </div>
                      ))}
                    </Col>
                    <Col lg="5" md="" sm="" style={{ paddingRight: "10px" }}>
                      <Form.Select
                        aria-label="Default select example"
                        style={{ borderRadius: "12px" }}
                        onChange={(value) => {
                          setIsOperatingMondayFromValid(false);
                          setOperatingMondayFrom(value.target.value);
                        }}
                        disabled={isSelectedMonday ? false : true}
                      >
                        <option value="0">From</option>
                        <option
                          selected={operatingMondayFrom === "6" ? true : false}
                          value="6"
                          disabled={
                            parseInt(breakTimeFrom) <= 6 &&
                            parseInt(breakTimeTo) > 6
                              ? true
                              : false
                          }
                        >
                          6:00
                        </option>
                        <option
                          selected={operatingMondayFrom === "7" ? true : false}
                          value="7"
                          disabled={
                            parseInt(breakTimeFrom) <= 7 &&
                            parseInt(breakTimeTo) > 7
                              ? true
                              : false
                          }
                        >
                          7:00
                        </option>
                        <option
                          selected={operatingMondayFrom === "8" ? true : false}
                          value="8"
                          disabled={
                            parseInt(breakTimeFrom) <= 8 &&
                            parseInt(breakTimeTo) > 8
                              ? true
                              : false
                          }
                        >
                          8:00
                        </option>
                        <option
                          selected={operatingMondayFrom === "9" ? true : false}
                          value="9"
                          disabled={
                            parseInt(breakTimeFrom) <= 9 &&
                            parseInt(breakTimeTo) > 9
                              ? true
                              : false
                          }
                        >
                          9:00
                        </option>
                        <option
                          selected={operatingMondayFrom === "10" ? true : false}
                          value="10"
                          disabled={
                            parseInt(breakTimeFrom) <= 10 &&
                            parseInt(breakTimeTo) > 10
                              ? true
                              : false
                          }
                        >
                          10:00
                        </option>
                        <option
                          selected={operatingMondayFrom === "11" ? true : false}
                          value="11"
                          disabled={
                            parseInt(breakTimeFrom) <= 11 &&
                            parseInt(breakTimeTo) > 11
                              ? true
                              : false
                          }
                        >
                          11:00
                        </option>
                        <option
                          selected={operatingMondayFrom === "12" ? true : false}
                          value="12"
                          disabled={
                            parseInt(breakTimeFrom) <= 12 &&
                            parseInt(breakTimeTo) > 12
                              ? true
                              : false
                          }
                        >
                          12:00
                        </option>
                        <option
                          selected={operatingMondayFrom === "13" ? true : false}
                          value="13"
                          disabled={
                            parseInt(breakTimeFrom) >= 13 &&
                            parseInt(breakTimeTo) > 13
                              ? true
                              : false
                          }
                        >
                          13:00
                        </option>
                        <option
                          selected={operatingMondayFrom === "14" ? true : false}
                          value="14"
                          disabled={
                            parseInt(breakTimeFrom) >= 14 &&
                            parseInt(breakTimeTo) > 14
                              ? true
                              : false
                          }
                        >
                          14:00
                        </option>
                        <option
                          selected={operatingMondayFrom === "15" ? true : false}
                          value="15"
                          disabled={
                            parseInt(breakTimeFrom) >= 15 &&
                            parseInt(breakTimeTo) > 15
                              ? true
                              : false
                          }
                        >
                          15:00
                        </option>
                        <option
                          selected={operatingMondayFrom === "16" ? true : false}
                          value="16"
                          disabled={
                            parseInt(breakTimeFrom) >= 16 &&
                            parseInt(breakTimeTo) > 16
                              ? true
                              : false
                          }
                        >
                          16:00
                        </option>
                        <option
                          selected={operatingMondayFrom === "17" ? true : false}
                          value="17"
                          disabled={
                            parseInt(breakTimeFrom) >= 17 &&
                            parseInt(breakTimeTo) > 17
                              ? true
                              : false
                          }
                        >
                          17:00
                        </option>
                        <option
                          selected={operatingMondayFrom === "18" ? true : false}
                          value="18"
                          disabled={
                            parseInt(breakTimeFrom) >= 18 &&
                            parseInt(breakTimeTo) > 18
                              ? true
                              : false
                          }
                        >
                          18:00
                        </option>
                        <option
                          selected={operatingMondayFrom === "19" ? true : false}
                          value="19"
                          disabled={
                            parseInt(breakTimeFrom) >= 19 &&
                            parseInt(breakTimeTo) > 19
                              ? true
                              : false
                          }
                        >
                          19:00
                        </option>
                        <option
                          selected={operatingMondayFrom === "20" ? true : false}
                          value="20"
                          disabled={
                            parseInt(breakTimeFrom) >= 20 &&
                            parseInt(breakTimeTo) > 20
                              ? true
                              : false
                          }
                        >
                          20:00
                        </option>
                      </Form.Select>
                      {isOperatingMondayFromValid ? (
                        <label style={{ fontSize: ".875em", color: "#dc3545" }}>
                          Please select a valid Operating Time.
                        </label>
                      ) : null}
                    </Col>
                    <Col lg="5" md="" sm="" style={{ paddingLeft: "10px" }}>
                      <Form.Select
                        aria-label="Default select example"
                        style={{ borderRadius: "12px" }}
                        onChange={(value) => {
                          setIsOperatingMondayToValid(false);
                          setOperatingMondayTo(value.target.value);
                        }}
                        disabled={
                          isSelectedMonday && operatingMondayFrom ? false : true
                        }
                      >
                        <option value="0">To</option>
                        <option
                          selected={operatingMondayTo === "6" ? true : false}
                          disabled={
                            parseInt(operatingMondayFrom) >= 6 ||
                            (parseInt(breakTimeFrom) <= 6 &&
                              parseInt(breakTimeTo) > 6)
                              ? true
                              : false
                          }
                          value="6"
                        >
                          6:00
                        </option>
                        <option
                          selected={operatingMondayTo === "7" ? true : false}
                          disabled={
                            parseInt(operatingMondayFrom) >= 7 ||
                            (parseInt(breakTimeFrom) <= 7 &&
                              parseInt(breakTimeTo) > 7)
                              ? true
                              : false
                          }
                          value="7"
                        >
                          7:00
                        </option>
                        <option
                          selected={operatingMondayTo === "8" ? true : false}
                          disabled={
                            parseInt(operatingMondayFrom) >= 8 ||
                            (parseInt(breakTimeFrom) <= 8 &&
                              parseInt(breakTimeTo) > 8)
                              ? true
                              : false
                          }
                          value="8"
                        >
                          8:00
                        </option>
                        <option
                          selected={operatingMondayTo === "9" ? true : false}
                          disabled={
                            parseInt(operatingMondayFrom) >= 9 ||
                            (parseInt(breakTimeFrom) <= 9 &&
                              parseInt(breakTimeTo) > 9)
                              ? true
                              : false
                          }
                          value="9"
                        >
                          9:00
                        </option>
                        <option
                          selected={operatingMondayTo === "10" ? true : false}
                          disabled={
                            parseInt(operatingMondayFrom) >= 10 ||
                            (parseInt(breakTimeFrom) <= 10 &&
                              parseInt(breakTimeTo) > 10)
                              ? true
                              : false
                          }
                          value="10"
                        >
                          10:00
                        </option>
                        <option
                          selected={operatingMondayTo === "11" ? true : false}
                          disabled={
                            parseInt(operatingMondayFrom) >= 11 ||
                            (parseInt(breakTimeFrom) <= 11 &&
                              parseInt(breakTimeTo) > 11)
                              ? true
                              : false
                          }
                          value="11"
                        >
                          11:00
                        </option>
                        <option
                          selected={operatingMondayTo === "12" ? true : false}
                          disabled={
                            parseInt(operatingMondayFrom) >= 12 ||
                            (parseInt(breakTimeFrom) <= 12 &&
                              parseInt(breakTimeTo) > 12)
                              ? true
                              : false
                          }
                          value="12"
                        >
                          12:00
                        </option>
                        <option
                          selected={operatingMondayTo === "13" ? true : false}
                          disabled={
                            parseInt(operatingMondayFrom) >= 13 ||
                            (parseInt(breakTimeFrom) >= 13 &&
                              parseInt(breakTimeTo) > 13)
                              ? true
                              : false
                          }
                          value="13"
                        >
                          13:00
                        </option>
                        <option
                          selected={operatingMondayTo === "14" ? true : false}
                          disabled={
                            parseInt(operatingMondayFrom) >= 14 ||
                            (parseInt(breakTimeFrom) >= 14 &&
                              parseInt(breakTimeTo) > 14)
                              ? true
                              : false
                          }
                          value="14"
                        >
                          14:00
                        </option>
                        <option
                          selected={operatingMondayTo === "15" ? true : false}
                          disabled={
                            parseInt(operatingMondayFrom) >= 15 ||
                            (parseInt(breakTimeFrom) >= 15 &&
                              parseInt(breakTimeTo) > 15)
                              ? true
                              : false
                          }
                          value="15"
                        >
                          15:00
                        </option>
                        <option
                          selected={operatingMondayTo === "16" ? true : false}
                          disabled={
                            parseInt(operatingMondayFrom) >= 16 ||
                            (parseInt(breakTimeFrom) >= 16 &&
                              parseInt(breakTimeTo) > 16)
                              ? true
                              : false
                          }
                          value="16"
                        >
                          16:00
                        </option>
                        <option
                          selected={operatingMondayTo === "17" ? true : false}
                          disabled={
                            parseInt(operatingMondayFrom) >= 17 ||
                            (parseInt(breakTimeFrom) >= 17 &&
                              parseInt(breakTimeTo) > 17)
                              ? true
                              : false
                          }
                          value="17"
                        >
                          17:00
                        </option>
                        <option
                          selected={operatingMondayTo === "18" ? true : false}
                          disabled={
                            parseInt(operatingMondayFrom) >= 18 ||
                            (parseInt(breakTimeFrom) >= 18 &&
                              parseInt(breakTimeTo) > 18)
                              ? true
                              : false
                          }
                          value="18"
                        >
                          18:00
                        </option>
                        <option
                          selected={operatingMondayTo === "19" ? true : false}
                          disabled={
                            parseInt(operatingMondayFrom) >= 19 ||
                            (parseInt(breakTimeFrom) >= 19 &&
                              parseInt(breakTimeTo) > 19)
                              ? true
                              : false
                          }
                          value="19"
                        >
                          19:00
                        </option>
                        <option
                          selected={operatingMondayTo === "20" ? true : false}
                          disabled={
                            parseInt(operatingMondayFrom) >= 20 ||
                            (parseInt(breakTimeFrom) >= 20 &&
                              parseInt(breakTimeTo) > 20)
                              ? true
                              : false
                          }
                          value="20"
                        >
                          20:00
                        </option>
                      </Form.Select>
                      {isOperatingMondayToValid ? (
                        <label style={{ fontSize: ".875em", color: "#dc3545" }}>
                          Please select a valid Operating Time.
                        </label>
                      ) : null}
                    </Col>
                  </Col>
                  {/* Tuesday */}
                  <Col
                    md="12"
                    style={{
                      color: "#A6A6A6",
                      paddingRight: "10px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Col lg="2" md="" sm="">
                      {["checkbox"].map((type) => (
                        <div key={`inline-${type}`} className="mb-3">
                          <Col
                            lg="12"
                            md="12"
                            sm="12"
                            xs="12"
                            style={{ marginTop: 10 }}
                          >
                            <Form.Check
                              inline
                              label="Tue"
                              name="Tuesday"
                              type={type}
                              id={`inline-${type}-2`}
                              onClick={() => {
                                setIsGeneralError(false);
                                setIsSelectedTuesday(!isSelectedTuesday);
                              }}
                              style={{ color: netural400 }}
                              checked={isSelectedTuesday ? true : false}
                            />
                          </Col>
                        </div>
                      ))}
                    </Col>
                    <Col lg="5" md="" sm="" style={{ paddingRight: "10px" }}>
                      <Form.Select
                        aria-label="Default select example"
                        style={{ borderRadius: "12px" }}
                        onChange={(value) => {
                          setIsOperatingTuesdayFromValid(false);
                          setOperatingTuesdayFrom(value.target.value);
                        }}
                        disabled={isSelectedTuesday ? false : true}
                      >
                        <option value="0">From</option>
                        <option
                          selected={operatingTuesdayFrom === "6" ? true : false}
                          value="6"
                          disabled={
                            parseInt(breakTimeFrom) <= 6 &&
                            parseInt(breakTimeTo) > 6
                              ? true
                              : false
                          }
                        >
                          6:00
                        </option>
                        <option
                          selected={operatingTuesdayFrom === "7" ? true : false}
                          value="7"
                          disabled={
                            parseInt(breakTimeFrom) <= 7 &&
                            parseInt(breakTimeTo) > 7
                              ? true
                              : false
                          }
                        >
                          7:00
                        </option>
                        <option
                          selected={operatingTuesdayFrom === "8" ? true : false}
                          value="8"
                          disabled={
                            parseInt(breakTimeFrom) <= 8 &&
                            parseInt(breakTimeTo) > 8
                              ? true
                              : false
                          }
                        >
                          8:00
                        </option>
                        <option
                          selected={operatingTuesdayFrom === "9" ? true : false}
                          value="9"
                          disabled={
                            parseInt(breakTimeFrom) <= 9 &&
                            parseInt(breakTimeTo) > 9
                              ? true
                              : false
                          }
                        >
                          9:00
                        </option>
                        <option
                          selected={
                            operatingTuesdayFrom === "10" ? true : false
                          }
                          value="10"
                          disabled={
                            parseInt(breakTimeFrom) <= 10 &&
                            parseInt(breakTimeTo) > 10
                              ? true
                              : false
                          }
                        >
                          10:00
                        </option>
                        <option
                          selected={
                            operatingTuesdayFrom === "11" ? true : false
                          }
                          value="11"
                          disabled={
                            parseInt(breakTimeFrom) <= 11 &&
                            parseInt(breakTimeTo) > 11
                              ? true
                              : false
                          }
                        >
                          11:00
                        </option>
                        <option
                          selected={
                            operatingTuesdayFrom === "12" ? true : false
                          }
                          value="12"
                          disabled={
                            parseInt(breakTimeFrom) <= 12 &&
                            parseInt(breakTimeTo) > 12
                              ? true
                              : false
                          }
                        >
                          12:00
                        </option>
                        <option
                          selected={
                            operatingTuesdayFrom === "13" ? true : false
                          }
                          value="13"
                          disabled={
                            parseInt(breakTimeFrom) >= 13 &&
                            parseInt(breakTimeTo) > 13
                              ? true
                              : false
                          }
                        >
                          13:00
                        </option>
                        <option
                          selected={
                            operatingTuesdayFrom === "14" ? true : false
                          }
                          value="14"
                          disabled={
                            parseInt(breakTimeFrom) >= 14 &&
                            parseInt(breakTimeTo) > 14
                              ? true
                              : false
                          }
                        >
                          14:00
                        </option>
                        <option
                          selected={
                            operatingTuesdayFrom === "15" ? true : false
                          }
                          value="15"
                          disabled={
                            parseInt(breakTimeFrom) >= 15 &&
                            parseInt(breakTimeTo) > 15
                              ? true
                              : false
                          }
                        >
                          15:00
                        </option>
                        <option
                          selected={
                            operatingTuesdayFrom === "16" ? true : false
                          }
                          value="16"
                          disabled={
                            parseInt(breakTimeFrom) >= 16 &&
                            parseInt(breakTimeTo) > 16
                              ? true
                              : false
                          }
                        >
                          16:00
                        </option>
                        <option
                          selected={
                            operatingTuesdayFrom === "17" ? true : false
                          }
                          value="17"
                          disabled={
                            parseInt(breakTimeFrom) >= 17 &&
                            parseInt(breakTimeTo) > 17
                              ? true
                              : false
                          }
                        >
                          17:00
                        </option>
                        <option
                          selected={
                            operatingTuesdayFrom === "18" ? true : false
                          }
                          value="18"
                          disabled={
                            parseInt(breakTimeFrom) >= 18 &&
                            parseInt(breakTimeTo) > 18
                              ? true
                              : false
                          }
                        >
                          18:00
                        </option>
                        <option
                          selected={
                            operatingTuesdayFrom === "19" ? true : false
                          }
                          value="19"
                          disabled={
                            parseInt(breakTimeFrom) >= 19 &&
                            parseInt(breakTimeTo) > 19
                              ? true
                              : false
                          }
                        >
                          19:00
                        </option>
                        <option
                          selected={
                            operatingTuesdayFrom === "20" ? true : false
                          }
                          value="20"
                          disabled={
                            parseInt(breakTimeFrom) >= 20 &&
                            parseInt(breakTimeTo) > 20
                              ? true
                              : false
                          }
                        >
                          20:00
                        </option>
                      </Form.Select>
                      {isOperatingTuesdayFromValid ? (
                        <label style={{ fontSize: ".875em", color: "#dc3545" }}>
                          Please select a valid Operating Time.
                        </label>
                      ) : null}
                    </Col>
                    <Col lg="5" md="" sm="" style={{ paddingLeft: "10px" }}>
                      <Form.Select
                        aria-label="Default select example"
                        style={{ borderRadius: "12px" }}
                        onChange={(value) => {
                          setIsOperatingTuesdayToValid(false);
                          setOperatingTuesdayTo(value.target.value);
                        }}
                        disabled={
                          isSelectedTuesday && operatingTuesdayFrom
                            ? false
                            : true
                        }
                      >
                        <option value="0">To</option>
                        <option
                          selected={operatingTuesdayTo === "6" ? true : false}
                          disabled={
                            parseInt(operatingTuesdayFrom) >= 6 ||
                            (parseInt(breakTimeFrom) <= 6 &&
                              parseInt(breakTimeTo) > 6)
                              ? true
                              : false
                          }
                          value="6"
                        >
                          6:00
                        </option>
                        <option
                          selected={operatingTuesdayTo === "7" ? true : false}
                          disabled={
                            parseInt(operatingTuesdayFrom) >= 7 ||
                            (parseInt(breakTimeFrom) <= 7 &&
                              parseInt(breakTimeTo) > 7)
                              ? true
                              : false
                          }
                          value="7"
                        >
                          7:00
                        </option>
                        <option
                          selected={operatingTuesdayTo === "8" ? true : false}
                          disabled={
                            parseInt(operatingTuesdayFrom) >= 8 ||
                            (parseInt(breakTimeFrom) <= 8 &&
                              parseInt(breakTimeTo) > 8)
                              ? true
                              : false
                          }
                          value="8"
                        >
                          8:00
                        </option>
                        <option
                          selected={operatingTuesdayTo === "9" ? true : false}
                          disabled={
                            parseInt(operatingTuesdayFrom) >= 9 ||
                            (parseInt(breakTimeFrom) <= 9 &&
                              parseInt(breakTimeTo) > 9)
                              ? true
                              : false
                          }
                          value="9"
                        >
                          9:00
                        </option>
                        <option
                          selected={operatingTuesdayTo === "10" ? true : false}
                          disabled={
                            parseInt(operatingTuesdayFrom) >= 10 ||
                            (parseInt(breakTimeFrom) <= 10 &&
                              parseInt(breakTimeTo) > 10)
                              ? true
                              : false
                          }
                          value="10"
                        >
                          10:00
                        </option>
                        <option
                          selected={operatingTuesdayTo === "11" ? true : false}
                          disabled={
                            parseInt(operatingTuesdayFrom) >= 11 ||
                            (parseInt(breakTimeFrom) <= 11 &&
                              parseInt(breakTimeTo) > 11)
                              ? true
                              : false
                          }
                          value="11"
                        >
                          11:00
                        </option>
                        <option
                          selected={operatingTuesdayTo === "12" ? true : false}
                          disabled={
                            parseInt(operatingTuesdayFrom) >= 12 ||
                            (parseInt(breakTimeFrom) <= 12 &&
                              parseInt(breakTimeTo) > 12)
                              ? true
                              : false
                          }
                          value="12"
                        >
                          12:00
                        </option>
                        <option
                          selected={operatingTuesdayTo === "13" ? true : false}
                          disabled={
                            parseInt(operatingTuesdayFrom) >= 13 ||
                            (parseInt(breakTimeFrom) >= 13 &&
                              parseInt(breakTimeTo) > 13)
                              ? true
                              : false
                          }
                          value="13"
                        >
                          13:00
                        </option>
                        <option
                          selected={operatingTuesdayTo === "14" ? true : false}
                          disabled={
                            parseInt(operatingTuesdayFrom) >= 14 ||
                            (parseInt(breakTimeFrom) >= 14 &&
                              parseInt(breakTimeTo) > 14)
                              ? true
                              : false
                          }
                          value="14"
                        >
                          14:00
                        </option>
                        <option
                          selected={operatingTuesdayTo === "15" ? true : false}
                          disabled={
                            parseInt(operatingTuesdayFrom) >= 15 ||
                            (parseInt(breakTimeFrom) >= 15 &&
                              parseInt(breakTimeTo) > 15)
                              ? true
                              : false
                          }
                          value="15"
                        >
                          15:00
                        </option>
                        <option
                          selected={operatingTuesdayTo === "16" ? true : false}
                          disabled={
                            parseInt(operatingTuesdayFrom) >= 16 ||
                            (parseInt(breakTimeFrom) >= 16 &&
                              parseInt(breakTimeTo) > 16)
                              ? true
                              : false
                          }
                          value="16"
                        >
                          16:00
                        </option>
                        <option
                          selected={operatingTuesdayTo === "17" ? true : false}
                          disabled={
                            parseInt(operatingTuesdayFrom) >= 17 ||
                            (parseInt(breakTimeFrom) >= 17 &&
                              parseInt(breakTimeTo) > 17)
                              ? true
                              : false
                          }
                          value="17"
                        >
                          17:00
                        </option>
                        <option
                          selected={operatingTuesdayTo === "18" ? true : false}
                          disabled={
                            parseInt(operatingTuesdayFrom) >= 18 ||
                            (parseInt(breakTimeFrom) >= 18 &&
                              parseInt(breakTimeTo) > 18)
                              ? true
                              : false
                          }
                          value="18"
                        >
                          18:00
                        </option>
                        <option
                          selected={operatingTuesdayTo === "19" ? true : false}
                          disabled={
                            parseInt(operatingTuesdayFrom) >= 19 ||
                            (parseInt(breakTimeFrom) >= 19 &&
                              parseInt(breakTimeTo) > 19)
                              ? true
                              : false
                          }
                          value="19"
                        >
                          19:00
                        </option>
                        <option
                          selected={operatingTuesdayTo === "20" ? true : false}
                          disabled={
                            parseInt(operatingTuesdayFrom) >= 20 ||
                            (parseInt(breakTimeFrom) >= 20 &&
                              parseInt(breakTimeTo) > 20)
                              ? true
                              : false
                          }
                          value="20"
                        >
                          20:00
                        </option>
                      </Form.Select>
                      {isOperatingTuesdayToValid ? (
                        <label style={{ fontSize: ".875em", color: "#dc3545" }}>
                          Please select a valid Operating Time.
                        </label>
                      ) : null}
                    </Col>
                  </Col>
                  {/* Wednesday */}
                  <Col
                    md="12"
                    style={{
                      color: "#A6A6A6",
                      paddingRight: "10px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Col lg="2" md="" sm="">
                      {["checkbox"].map((type) => (
                        <div key={`inline-${type}`} className="mb-3">
                          <Col
                            lg="12"
                            md="12"
                            sm="12"
                            xs="12"
                            style={{ marginTop: 10 }}
                          >
                            <Form.Check
                              inline
                              label="Wed"
                              name="Wednesday"
                              type={type}
                              id={`inline-${type}-2`}
                              onClick={() => {
                                setIsGeneralError(false);
                                setIsSelectedWednesday(!isSelectedWednesday);
                              }}
                              style={{ color: netural400 }}
                              checked={isSelectedWednesday ? true : false}
                            />
                          </Col>
                        </div>
                      ))}
                    </Col>
                    <Col lg="5" md="" sm="" style={{ paddingRight: "10px" }}>
                      <Form.Select
                        aria-label="Default select example"
                        style={{ borderRadius: "12px" }}
                        onChange={(value) => {
                          setIsOperatingWednesdayFromValid(false);
                          setOperatingWednesdayFrom(value.target.value);
                        }}
                        disabled={isSelectedWednesday ? false : true}
                      >
                        <option value="0">From</option>
                        <option
                          selected={
                            operatingWednesdayFrom === "6" ? true : false
                          }
                          value="6"
                          disabled={
                            parseInt(breakTimeFrom) <= 6 &&
                            parseInt(breakTimeTo) > 6
                              ? true
                              : false
                          }
                        >
                          6:00
                        </option>
                        <option
                          selected={
                            operatingWednesdayFrom === "7" ? true : false
                          }
                          value="7"
                          disabled={
                            parseInt(breakTimeFrom) <= 7 &&
                            parseInt(breakTimeTo) > 7
                              ? true
                              : false
                          }
                        >
                          7:00
                        </option>
                        <option
                          selected={
                            operatingWednesdayFrom === "8" ? true : false
                          }
                          value="8"
                          disabled={
                            parseInt(breakTimeFrom) <= 8 &&
                            parseInt(breakTimeTo) > 8
                              ? true
                              : false
                          }
                        >
                          8:00
                        </option>
                        <option
                          selected={
                            operatingWednesdayFrom === "9" ? true : false
                          }
                          value="9"
                          disabled={
                            parseInt(breakTimeFrom) <= 9 &&
                            parseInt(breakTimeTo) > 9
                              ? true
                              : false
                          }
                        >
                          9:00
                        </option>
                        <option
                          selected={
                            operatingWednesdayFrom === "10" ? true : false
                          }
                          value="10"
                          disabled={
                            parseInt(breakTimeFrom) <= 10 &&
                            parseInt(breakTimeTo) > 10
                              ? true
                              : false
                          }
                        >
                          10:00
                        </option>
                        <option
                          selected={
                            operatingWednesdayFrom === "11" ? true : false
                          }
                          value="11"
                          disabled={
                            parseInt(breakTimeFrom) <= 11 &&
                            parseInt(breakTimeTo) > 11
                              ? true
                              : false
                          }
                        >
                          11:00
                        </option>
                        <option
                          selected={
                            operatingWednesdayFrom === "12" ? true : false
                          }
                          value="12"
                          disabled={
                            parseInt(breakTimeFrom) <= 12 &&
                            parseInt(breakTimeTo) > 12
                              ? true
                              : false
                          }
                        >
                          12:00
                        </option>
                        <option
                          selected={
                            operatingWednesdayFrom === "13" ? true : false
                          }
                          value="13"
                          disabled={
                            parseInt(breakTimeFrom) >= 13 &&
                            parseInt(breakTimeTo) > 13
                              ? true
                              : false
                          }
                        >
                          13:00
                        </option>
                        <option
                          selected={
                            operatingWednesdayFrom === "14" ? true : false
                          }
                          value="14"
                          disabled={
                            parseInt(breakTimeFrom) >= 14 &&
                            parseInt(breakTimeTo) > 14
                              ? true
                              : false
                          }
                        >
                          14:00
                        </option>
                        <option
                          selected={
                            operatingWednesdayFrom === "15" ? true : false
                          }
                          value="15"
                          disabled={
                            parseInt(breakTimeFrom) >= 15 &&
                            parseInt(breakTimeTo) > 15
                              ? true
                              : false
                          }
                        >
                          15:00
                        </option>
                        <option
                          selected={
                            operatingWednesdayFrom === "16" ? true : false
                          }
                          value="16"
                          disabled={
                            parseInt(breakTimeFrom) >= 16 &&
                            parseInt(breakTimeTo) > 16
                              ? true
                              : false
                          }
                        >
                          16:00
                        </option>
                        <option
                          selected={
                            operatingWednesdayFrom === "17" ? true : false
                          }
                          value="17"
                          disabled={
                            parseInt(breakTimeFrom) >= 17 &&
                            parseInt(breakTimeTo) > 17
                              ? true
                              : false
                          }
                        >
                          17:00
                        </option>
                        <option
                          selected={
                            operatingWednesdayFrom === "18" ? true : false
                          }
                          value="18"
                          disabled={
                            parseInt(breakTimeFrom) >= 18 &&
                            parseInt(breakTimeTo) > 18
                              ? true
                              : false
                          }
                        >
                          18:00
                        </option>
                        <option
                          selected={
                            operatingWednesdayFrom === "19" ? true : false
                          }
                          value="19"
                          disabled={
                            parseInt(breakTimeFrom) >= 19 &&
                            parseInt(breakTimeTo) > 19
                              ? true
                              : false
                          }
                        >
                          19:00
                        </option>
                        <option
                          selected={
                            operatingWednesdayFrom === "20" ? true : false
                          }
                          value="20"
                          disabled={
                            parseInt(breakTimeFrom) >= 20 &&
                            parseInt(breakTimeTo) > 20
                              ? true
                              : false
                          }
                        >
                          20:00
                        </option>
                      </Form.Select>
                      {isOperatingWednesdayFromValid ? (
                        <label style={{ fontSize: ".875em", color: "#dc3545" }}>
                          Please select a valid Operating Time.
                        </label>
                      ) : null}
                    </Col>
                    <Col lg="5" md="" sm="" style={{ paddingLeft: "10px" }}>
                      <Form.Select
                        aria-label="Default select example"
                        style={{ borderRadius: "12px" }}
                        onChange={(value) => {
                          setIsOperatingWednesdayToValid(false);
                          setOperatingWednesdayTo(value.target.value);
                        }}
                        disabled={
                          isSelectedWednesday && operatingWednesdayFrom
                            ? false
                            : true
                        }
                      >
                        <option value="0">To</option>
                        <option
                          selected={operatingWednesdayTo === "6" ? true : false}
                          disabled={
                            parseInt(operatingWednesdayFrom) >= 6 ||
                            (parseInt(breakTimeFrom) <= 6 &&
                              parseInt(breakTimeTo) > 6)
                              ? true
                              : false
                          }
                          value="6"
                        >
                          6:00
                        </option>
                        <option
                          selected={operatingWednesdayTo === "7" ? true : false}
                          disabled={
                            parseInt(operatingWednesdayFrom) >= 7 ||
                            (parseInt(breakTimeFrom) <= 7 &&
                              parseInt(breakTimeTo) > 7)
                              ? true
                              : false
                          }
                          value="7"
                        >
                          7:00
                        </option>
                        <option
                          selected={operatingWednesdayTo === "8" ? true : false}
                          disabled={
                            parseInt(operatingWednesdayFrom) >= 8 ||
                            (parseInt(breakTimeFrom) <= 8 &&
                              parseInt(breakTimeTo) > 8)
                              ? true
                              : false
                          }
                          value="8"
                        >
                          8:00
                        </option>
                        <option
                          selected={operatingWednesdayTo === "9" ? true : false}
                          disabled={
                            parseInt(operatingWednesdayFrom) >= 9 ||
                            (parseInt(breakTimeFrom) <= 9 &&
                              parseInt(breakTimeTo) > 9)
                              ? true
                              : false
                          }
                          value="9"
                        >
                          9:00
                        </option>
                        <option
                          selected={
                            operatingWednesdayTo === "10" ? true : false
                          }
                          disabled={
                            parseInt(operatingWednesdayFrom) >= 10 ||
                            (parseInt(breakTimeFrom) <= 10 &&
                              parseInt(breakTimeTo) > 10)
                              ? true
                              : false
                          }
                          value="10"
                        >
                          10:00
                        </option>
                        <option
                          selected={
                            operatingWednesdayTo === "11" ? true : false
                          }
                          disabled={
                            parseInt(operatingWednesdayFrom) >= 11 ||
                            (parseInt(breakTimeFrom) <= 11 &&
                              parseInt(breakTimeTo) > 11)
                              ? true
                              : false
                          }
                          value="11"
                        >
                          11:00
                        </option>
                        <option
                          selected={
                            operatingWednesdayTo === "12" ? true : false
                          }
                          disabled={
                            parseInt(operatingWednesdayFrom) >= 12 ||
                            (parseInt(breakTimeFrom) <= 12 &&
                              parseInt(breakTimeTo) > 12)
                              ? true
                              : false
                          }
                          value="12"
                        >
                          12:00
                        </option>
                        <option
                          selected={
                            operatingWednesdayTo === "13" ? true : false
                          }
                          disabled={
                            parseInt(operatingWednesdayFrom) >= 13 ||
                            (parseInt(breakTimeFrom) >= 13 &&
                              parseInt(breakTimeTo) > 13)
                              ? true
                              : false
                          }
                          value="13"
                        >
                          13:00
                        </option>
                        <option
                          selected={
                            operatingWednesdayTo === "14" ? true : false
                          }
                          disabled={
                            parseInt(operatingWednesdayFrom) >= 14 ||
                            (parseInt(breakTimeFrom) >= 14 &&
                              parseInt(breakTimeTo) > 14)
                              ? true
                              : false
                          }
                          value="14"
                        >
                          14:00
                        </option>
                        <option
                          selected={
                            operatingWednesdayTo === "15" ? true : false
                          }
                          disabled={
                            parseInt(operatingWednesdayFrom) >= 15 ||
                            (parseInt(breakTimeFrom) >= 15 &&
                              parseInt(breakTimeTo) > 15)
                              ? true
                              : false
                          }
                          value="15"
                        >
                          15:00
                        </option>
                        <option
                          selected={
                            operatingWednesdayTo === "16" ? true : false
                          }
                          disabled={
                            parseInt(operatingWednesdayFrom) >= 16 ||
                            (parseInt(breakTimeFrom) >= 16 &&
                              parseInt(breakTimeTo) > 16)
                              ? true
                              : false
                          }
                          value="16"
                        >
                          16:00
                        </option>
                        <option
                          selected={
                            operatingWednesdayTo === "17" ? true : false
                          }
                          disabled={
                            parseInt(operatingWednesdayFrom) >= 17 ||
                            (parseInt(breakTimeFrom) >= 17 &&
                              parseInt(breakTimeTo) > 17)
                              ? true
                              : false
                          }
                          value="17"
                        >
                          17:00
                        </option>
                        <option
                          selected={
                            operatingWednesdayTo === "18" ? true : false
                          }
                          disabled={
                            parseInt(operatingWednesdayFrom) >= 18 ||
                            (parseInt(breakTimeFrom) >= 18 &&
                              parseInt(breakTimeTo) > 18)
                              ? true
                              : false
                          }
                          value="18"
                        >
                          18:00
                        </option>
                        <option
                          selected={
                            operatingWednesdayTo === "19" ? true : false
                          }
                          disabled={
                            parseInt(operatingWednesdayFrom) >= 19 ||
                            (parseInt(breakTimeFrom) >= 19 &&
                              parseInt(breakTimeTo) > 19)
                              ? true
                              : false
                          }
                          value="19"
                        >
                          19:00
                        </option>
                        <option
                          selected={
                            operatingWednesdayTo === "20" ? true : false
                          }
                          disabled={
                            parseInt(operatingWednesdayFrom) >= 20 ||
                            (parseInt(breakTimeFrom) >= 20 &&
                              parseInt(breakTimeTo) > 20)
                              ? true
                              : false
                          }
                          value="20"
                        >
                          20:00
                        </option>
                      </Form.Select>
                      {isOperatingWednesdayToValid ? (
                        <label style={{ fontSize: ".875em", color: "#dc3545" }}>
                          Please select a valid Operating Time.
                        </label>
                      ) : null}
                    </Col>
                  </Col>
                  {/* Thursday */}
                  <Col
                    md="12"
                    style={{
                      color: "#A6A6A6",
                      paddingRight: "10px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Col lg="2" md="" sm="">
                      {["checkbox"].map((type) => (
                        <div key={`inline-${type}`} className="mb-3">
                          <Col
                            lg="12"
                            md="12"
                            sm="12"
                            xs="12"
                            style={{ marginTop: 10 }}
                          >
                            <Form.Check
                              inline
                              label="Thu"
                              name="Thursday"
                              type={type}
                              id={`inline-${type}-2`}
                              onClick={() => {
                                setIsGeneralError(false);
                                setIsSelectedThursday(!isSelectedThursday);
                              }}
                              style={{ color: netural400 }}
                              checked={isSelectedThursday ? true : false}
                            />
                          </Col>
                        </div>
                      ))}
                    </Col>
                    <Col lg="5" md="" sm="" style={{ paddingRight: "10px" }}>
                      <Form.Select
                        aria-label="Default select example"
                        style={{ borderRadius: "12px" }}
                        onChange={(value) => {
                          setIsOperatingThursdayFromValid(false);
                          setOperatingThursdayFrom(value.target.value);
                        }}
                        disabled={isSelectedThursday ? false : true}
                      >
                        <option value="0">From</option>
                        <option
                          selected={
                            operatingThursdayFrom === "6" ? true : false
                          }
                          value="6"
                          disabled={
                            parseInt(breakTimeFrom) <= 6 &&
                            parseInt(breakTimeTo) > 6
                              ? true
                              : false
                          }
                        >
                          6:00
                        </option>
                        <option
                          selected={
                            operatingThursdayFrom === "7" ? true : false
                          }
                          value="7"
                          disabled={
                            parseInt(breakTimeFrom) <= 7 &&
                            parseInt(breakTimeTo) > 7
                              ? true
                              : false
                          }
                        >
                          7:00
                        </option>
                        <option
                          selected={
                            operatingThursdayFrom === "8" ? true : false
                          }
                          value="8"
                          disabled={
                            parseInt(breakTimeFrom) <= 8 &&
                            parseInt(breakTimeTo) > 8
                              ? true
                              : false
                          }
                        >
                          8:00
                        </option>
                        <option
                          selected={
                            operatingThursdayFrom === "9" ? true : false
                          }
                          value="9"
                          disabled={
                            parseInt(breakTimeFrom) <= 9 &&
                            parseInt(breakTimeTo) > 9
                              ? true
                              : false
                          }
                        >
                          9:00
                        </option>
                        <option
                          selected={
                            operatingThursdayFrom === "10" ? true : false
                          }
                          value="10"
                          disabled={
                            parseInt(breakTimeFrom) <= 10 &&
                            parseInt(breakTimeTo) > 10
                              ? true
                              : false
                          }
                        >
                          10:00
                        </option>
                        <option
                          selected={
                            operatingThursdayFrom === "11" ? true : false
                          }
                          value="11"
                          disabled={
                            parseInt(breakTimeFrom) <= 11 &&
                            parseInt(breakTimeTo) > 11
                              ? true
                              : false
                          }
                        >
                          11:00
                        </option>
                        <option
                          selected={
                            operatingThursdayFrom === "12" ? true : false
                          }
                          value="12"
                          disabled={
                            parseInt(breakTimeFrom) <= 12 &&
                            parseInt(breakTimeTo) > 12
                              ? true
                              : false
                          }
                        >
                          12:00
                        </option>
                        <option
                          selected={
                            operatingThursdayFrom === "13" ? true : false
                          }
                          value="13"
                          disabled={
                            parseInt(breakTimeFrom) >= 13 &&
                            parseInt(breakTimeTo) > 13
                              ? true
                              : false
                          }
                        >
                          13:00
                        </option>
                        <option
                          selected={
                            operatingThursdayFrom === "14" ? true : false
                          }
                          value="14"
                          disabled={
                            parseInt(breakTimeFrom) >= 14 &&
                            parseInt(breakTimeTo) > 14
                              ? true
                              : false
                          }
                        >
                          14:00
                        </option>
                        <option
                          selected={
                            operatingThursdayFrom === "15" ? true : false
                          }
                          value="15"
                          disabled={
                            parseInt(breakTimeFrom) >= 15 &&
                            parseInt(breakTimeTo) > 15
                              ? true
                              : false
                          }
                        >
                          15:00
                        </option>
                        <option
                          selected={
                            operatingThursdayFrom === "16" ? true : false
                          }
                          value="16"
                          disabled={
                            parseInt(breakTimeFrom) >= 16 &&
                            parseInt(breakTimeTo) > 16
                              ? true
                              : false
                          }
                        >
                          16:00
                        </option>
                        <option
                          selected={
                            operatingThursdayFrom === "17" ? true : false
                          }
                          value="17"
                          disabled={
                            parseInt(breakTimeFrom) >= 17 &&
                            parseInt(breakTimeTo) > 17
                              ? true
                              : false
                          }
                        >
                          17:00
                        </option>
                        <option
                          selected={
                            operatingThursdayFrom === "18" ? true : false
                          }
                          value="18"
                          disabled={
                            parseInt(breakTimeFrom) >= 18 &&
                            parseInt(breakTimeTo) > 18
                              ? true
                              : false
                          }
                        >
                          18:00
                        </option>
                        <option
                          selected={
                            operatingThursdayFrom === "19" ? true : false
                          }
                          value="19"
                          disabled={
                            parseInt(breakTimeFrom) >= 19 &&
                            parseInt(breakTimeTo) > 19
                              ? true
                              : false
                          }
                        >
                          19:00
                        </option>
                        <option
                          selected={
                            operatingThursdayFrom === "20" ? true : false
                          }
                          value="20"
                          disabled={
                            parseInt(breakTimeFrom) >= 20 &&
                            parseInt(breakTimeTo) > 20
                              ? true
                              : false
                          }
                        >
                          20:00
                        </option>
                      </Form.Select>
                      {isOperatingThursdayFromValid ? (
                        <label style={{ fontSize: ".875em", color: "#dc3545" }}>
                          Please select a valid Operating Time.
                        </label>
                      ) : null}
                    </Col>
                    <Col lg="5" md="" sm="" style={{ paddingLeft: "10px" }}>
                      <Form.Select
                        aria-label="Default select example"
                        style={{ borderRadius: "12px" }}
                        onChange={(value) => {
                          setIsOperatingThursdayToValid(false);
                          setOperatingThursdayTo(value.target.value);
                        }}
                        disabled={
                          isSelectedThursday && operatingThursdayFrom
                            ? false
                            : true
                        }
                      >
                        <option value="0">To</option>
                        <option
                          selected={operatingThursdayTo === "6" ? true : false}
                          disabled={
                            parseInt(operatingThursdayFrom) >= 6 ||
                            (parseInt(breakTimeFrom) <= 6 &&
                              parseInt(breakTimeTo) > 6)
                              ? true
                              : false
                          }
                          value="6"
                        >
                          6:00
                        </option>
                        <option
                          selected={operatingThursdayTo === "7" ? true : false}
                          disabled={
                            parseInt(operatingThursdayFrom) >= 7 ||
                            (parseInt(breakTimeFrom) <= 7 &&
                              parseInt(breakTimeTo) > 7)
                              ? true
                              : false
                          }
                          value="7"
                        >
                          7:00
                        </option>
                        <option
                          selected={operatingThursdayTo === "8" ? true : false}
                          disabled={
                            parseInt(operatingThursdayFrom) >= 8 ||
                            (parseInt(breakTimeFrom) <= 8 &&
                              parseInt(breakTimeTo) > 8)
                              ? true
                              : false
                          }
                          value="8"
                        >
                          8:00
                        </option>
                        <option
                          selected={operatingThursdayTo === "9" ? true : false}
                          disabled={
                            parseInt(operatingThursdayFrom) >= 9 ||
                            (parseInt(breakTimeFrom) <= 9 &&
                              parseInt(breakTimeTo) > 9)
                              ? true
                              : false
                          }
                          value="9"
                        >
                          9:00
                        </option>
                        <option
                          selected={operatingThursdayTo === "10" ? true : false}
                          disabled={
                            parseInt(operatingThursdayFrom) >= 10 ||
                            (parseInt(breakTimeFrom) <= 10 &&
                              parseInt(breakTimeTo) > 10)
                              ? true
                              : false
                          }
                          value="10"
                        >
                          10:00
                        </option>
                        <option
                          selected={operatingThursdayTo === "11" ? true : false}
                          disabled={
                            parseInt(operatingThursdayFrom) >= 11 ||
                            (parseInt(breakTimeFrom) <= 11 &&
                              parseInt(breakTimeTo) > 11)
                              ? true
                              : false
                          }
                          value="11"
                        >
                          11:00
                        </option>
                        <option
                          selected={operatingThursdayTo === "12" ? true : false}
                          disabled={
                            parseInt(operatingThursdayFrom) >= 12 ||
                            (parseInt(breakTimeFrom) <= 12 &&
                              parseInt(breakTimeTo) > 12)
                              ? true
                              : false
                          }
                          value="12"
                        >
                          12:00
                        </option>
                        <option
                          selected={operatingThursdayTo === "13" ? true : false}
                          disabled={
                            parseInt(operatingThursdayFrom) >= 13 ||
                            (parseInt(breakTimeFrom) >= 13 &&
                              parseInt(breakTimeTo) > 13)
                              ? true
                              : false
                          }
                          value="13"
                        >
                          13:00
                        </option>
                        <option
                          selected={operatingThursdayTo === "14" ? true : false}
                          disabled={
                            parseInt(operatingThursdayFrom) >= 14 ||
                            (parseInt(breakTimeFrom) >= 14 &&
                              parseInt(breakTimeTo) > 14)
                              ? true
                              : false
                          }
                          value="14"
                        >
                          14:00
                        </option>
                        <option
                          selected={operatingThursdayTo === "15" ? true : false}
                          disabled={
                            parseInt(operatingThursdayFrom) >= 15 ||
                            (parseInt(breakTimeFrom) >= 15 &&
                              parseInt(breakTimeTo) > 15)
                              ? true
                              : false
                          }
                          value="15"
                        >
                          15:00
                        </option>
                        <option
                          selected={operatingThursdayTo === "16" ? true : false}
                          disabled={
                            parseInt(operatingThursdayFrom) >= 16 ||
                            (parseInt(breakTimeFrom) >= 16 &&
                              parseInt(breakTimeTo) > 16)
                              ? true
                              : false
                          }
                          value="16"
                        >
                          16:00
                        </option>
                        <option
                          selected={operatingThursdayTo === "17" ? true : false}
                          disabled={
                            parseInt(operatingThursdayFrom) >= 17 ||
                            (parseInt(breakTimeFrom) >= 17 &&
                              parseInt(breakTimeTo) > 17)
                              ? true
                              : false
                          }
                          value="17"
                        >
                          17:00
                        </option>
                        <option
                          selected={operatingThursdayTo === "18" ? true : false}
                          disabled={
                            parseInt(operatingThursdayFrom) >= 18 ||
                            (parseInt(breakTimeFrom) >= 18 &&
                              parseInt(breakTimeTo) > 18)
                              ? true
                              : false
                          }
                          value="18"
                        >
                          18:00
                        </option>
                        <option
                          selected={operatingThursdayTo === "19" ? true : false}
                          disabled={
                            parseInt(operatingThursdayFrom) >= 19 ||
                            (parseInt(breakTimeFrom) >= 19 &&
                              parseInt(breakTimeTo) > 19)
                              ? true
                              : false
                          }
                          value="19"
                        >
                          19:00
                        </option>
                        <option
                          selected={operatingThursdayTo === "20" ? true : false}
                          disabled={
                            parseInt(operatingThursdayFrom) >= 20 ||
                            (parseInt(breakTimeFrom) >= 20 &&
                              parseInt(breakTimeTo) > 20)
                              ? true
                              : false
                          }
                          value="20"
                        >
                          20:00
                        </option>
                      </Form.Select>
                      {isOperatingThursdayToValid ? (
                        <label style={{ fontSize: ".875em", color: "#dc3545" }}>
                          Please select a valid Operating Time.
                        </label>
                      ) : null}
                    </Col>
                  </Col>
                  {/* Friday */}
                  <Col
                    md="12"
                    style={{
                      color: "#A6A6A6",
                      paddingRight: "10px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Col lg="2" md="" sm="">
                      {["checkbox"].map((type) => (
                        <div key={`inline-${type}`} className="mb-3">
                          <Col
                            lg="12"
                            md="12"
                            sm="12"
                            xs="12"
                            style={{ marginTop: 10 }}
                          >
                            <Form.Check
                              inline
                              label="Fri"
                              name="Friday"
                              type={type}
                              id={`inline-${type}-2`}
                              onClick={() => {
                                setIsGeneralError(false);
                                setIsSelectedFriday(!isSelectedFriday);
                              }}
                              style={{ color: netural400 }}
                              checked={isSelectedFriday ? true : false}
                            />
                          </Col>
                        </div>
                      ))}
                    </Col>
                    <Col lg="5" md="" sm="" style={{ paddingRight: "10px" }}>
                      <Form.Select
                        aria-label="Default select example"
                        style={{ borderRadius: "12px" }}
                        onChange={(value) => {
                          setIsOperatingFridayFromValid(false);
                          setOperatingFridayFrom(value.target.value);
                        }}
                        disabled={isSelectedFriday ? false : true}
                      >
                        <option value="0">From</option>
                        <option
                          selected={operatingFridayFrom === "6" ? true : false}
                          value="6"
                          disabled={
                            parseInt(breakTimeFrom) <= 6 &&
                            parseInt(breakTimeTo) > 6
                              ? true
                              : false
                          }
                        >
                          6:00
                        </option>
                        <option
                          selected={operatingFridayFrom === "7" ? true : false}
                          value="7"
                          disabled={
                            parseInt(breakTimeFrom) <= 7 &&
                            parseInt(breakTimeTo) > 7
                              ? true
                              : false
                          }
                        >
                          7:00
                        </option>
                        <option
                          selected={operatingFridayFrom === "8" ? true : false}
                          value="8"
                          disabled={
                            parseInt(breakTimeFrom) <= 8 &&
                            parseInt(breakTimeTo) > 8
                              ? true
                              : false
                          }
                        >
                          8:00
                        </option>
                        <option
                          selected={operatingFridayFrom === "9" ? true : false}
                          value="9"
                          disabled={
                            parseInt(breakTimeFrom) <= 9 &&
                            parseInt(breakTimeTo) >= 9
                              ? true
                              : false
                          }
                        >
                          9:00
                        </option>
                        <option
                          selected={operatingFridayFrom === "10" ? true : false}
                          value="10"
                          disabled={
                            parseInt(breakTimeFrom) <= 10 &&
                            parseInt(breakTimeTo) > 10
                              ? true
                              : false
                          }
                        >
                          10:00
                        </option>
                        <option
                          selected={operatingFridayFrom === "11" ? true : false}
                          value="11"
                          disabled={
                            parseInt(breakTimeFrom) <= 11 &&
                            parseInt(breakTimeTo) > 11
                              ? true
                              : false
                          }
                        >
                          11:00
                        </option>
                        <option
                          selected={operatingFridayFrom === "12" ? true : false}
                          value="12"
                          disabled={
                            parseInt(breakTimeFrom) <= 12 &&
                            parseInt(breakTimeTo) > 12
                              ? true
                              : false
                          }
                        >
                          12:00
                        </option>
                        <option
                          selected={operatingFridayFrom === "13" ? true : false}
                          value="13"
                          disabled={
                            parseInt(breakTimeFrom) >= 13 &&
                            parseInt(breakTimeTo) > 13
                              ? true
                              : false
                          }
                        >
                          13:00
                        </option>
                        <option
                          selected={operatingFridayFrom === "14" ? true : false}
                          value="14"
                          disabled={
                            parseInt(breakTimeFrom) >= 14 &&
                            parseInt(breakTimeTo) > 14
                              ? true
                              : false
                          }
                        >
                          14:00
                        </option>
                        <option
                          selected={operatingFridayFrom === "15" ? true : false}
                          value="15"
                          disabled={
                            parseInt(breakTimeFrom) >= 15 &&
                            parseInt(breakTimeTo) > 15
                              ? true
                              : false
                          }
                        >
                          15:00
                        </option>
                        <option
                          selected={operatingFridayFrom === "16" ? true : false}
                          value="16"
                          disabled={
                            parseInt(breakTimeFrom) >= 16 &&
                            parseInt(breakTimeTo) > 16
                              ? true
                              : false
                          }
                        >
                          16:00
                        </option>
                        <option
                          selected={operatingFridayFrom === "17" ? true : false}
                          value="17"
                          disabled={
                            parseInt(breakTimeFrom) >= 17 &&
                            parseInt(breakTimeTo) > 17
                              ? true
                              : false
                          }
                        >
                          17:00
                        </option>
                        <option
                          selected={operatingFridayFrom === "18" ? true : false}
                          value="18"
                          disabled={
                            parseInt(breakTimeFrom) >= 18 &&
                            parseInt(breakTimeTo) > 18
                              ? true
                              : false
                          }
                        >
                          18:00
                        </option>
                        <option
                          selected={operatingFridayFrom === "19" ? true : false}
                          value="19"
                          disabled={
                            parseInt(breakTimeFrom) >= 19 &&
                            parseInt(breakTimeTo) > 19
                              ? true
                              : false
                          }
                        >
                          19:00
                        </option>
                        <option
                          selected={operatingFridayFrom === "20" ? true : false}
                          value="20"
                          disabled={
                            parseInt(breakTimeFrom) >= 20 &&
                            parseInt(breakTimeTo) > 20
                              ? true
                              : false
                          }
                        >
                          20:00
                        </option>
                      </Form.Select>
                      {isOperatingFridayFromValid ? (
                        <label style={{ fontSize: ".875em", color: "#dc3545" }}>
                          Please select a valid Operating Time.
                        </label>
                      ) : null}
                    </Col>
                    <Col lg="5" md="" sm="" style={{ paddingLeft: "10px" }}>
                      <Form.Select
                        aria-label="Default select example"
                        style={{ borderRadius: "12px" }}
                        onChange={(value) => {
                          setIsOperatingFridayToValid(false);
                          setOperatingFridayTo(value.target.value);
                        }}
                        disabled={
                          isSelectedFriday && operatingFridayFrom ? false : true
                        }
                      >
                        <option value="0">To</option>
                        <option
                          selected={operatingFridayTo === "6" ? true : false}
                          disabled={
                            parseInt(operatingFridayFrom) >= 6 ||
                            (parseInt(breakTimeFrom) <= 6 &&
                              parseInt(breakTimeTo) > 6)
                              ? true
                              : false
                          }
                          value="6"
                        >
                          6:00
                        </option>
                        <option
                          selected={operatingFridayTo === "7" ? true : false}
                          disabled={
                            parseInt(operatingFridayFrom) >= 7 ||
                            (parseInt(breakTimeFrom) <= 7 &&
                              parseInt(breakTimeTo) > 7)
                              ? true
                              : false
                          }
                          value="7"
                        >
                          7:00
                        </option>
                        <option
                          selected={operatingFridayTo === "8" ? true : false}
                          disabled={
                            parseInt(operatingFridayFrom) >= 8 ||
                            (parseInt(breakTimeFrom) <= 8 &&
                              parseInt(breakTimeTo) > 8)
                              ? true
                              : false
                          }
                          value="8"
                        >
                          8:00
                        </option>
                        <option
                          selected={operatingFridayTo === "9" ? true : false}
                          disabled={
                            parseInt(operatingFridayFrom) >= 9 ||
                            (parseInt(breakTimeFrom) <= 9 &&
                              parseInt(breakTimeTo) > 9)
                              ? true
                              : false
                          }
                          value="9"
                        >
                          9:00
                        </option>
                        <option
                          selected={operatingFridayTo === "10" ? true : false}
                          disabled={
                            parseInt(operatingFridayFrom) >= 10 ||
                            (parseInt(breakTimeFrom) <= 10 &&
                              parseInt(breakTimeTo) > 10)
                              ? true
                              : false
                          }
                          value="10"
                        >
                          10:00
                        </option>
                        <option
                          selected={operatingFridayTo === "11" ? true : false}
                          disabled={
                            parseInt(operatingFridayFrom) >= 11 ||
                            (parseInt(breakTimeFrom) <= 11 &&
                              parseInt(breakTimeTo) > 11)
                              ? true
                              : false
                          }
                          value="11"
                        >
                          11:00
                        </option>
                        <option
                          selected={operatingFridayTo === "12" ? true : false}
                          disabled={
                            parseInt(operatingFridayFrom) >= 12 ||
                            (parseInt(breakTimeFrom) <= 12 &&
                              parseInt(breakTimeTo) > 12)
                              ? true
                              : false
                          }
                          value="12"
                        >
                          12:00
                        </option>
                        <option
                          selected={operatingFridayTo === "13" ? true : false}
                          disabled={
                            parseInt(operatingFridayFrom) >= 13 ||
                            (parseInt(breakTimeFrom) >= 13 &&
                              parseInt(breakTimeTo) > 13)
                              ? true
                              : false
                          }
                          value="13"
                        >
                          13:00
                        </option>
                        <option
                          selected={operatingFridayTo === "14" ? true : false}
                          disabled={
                            parseInt(operatingFridayFrom) >= 14 ||
                            (parseInt(breakTimeFrom) >= 14 &&
                              parseInt(breakTimeTo) > 14)
                              ? true
                              : false
                          }
                          value="14"
                        >
                          14:00
                        </option>
                        <option
                          selected={operatingFridayTo === "15" ? true : false}
                          disabled={
                            parseInt(operatingFridayFrom) >= 15 ||
                            (parseInt(breakTimeFrom) >= 15 &&
                              parseInt(breakTimeTo) > 15)
                              ? true
                              : false
                          }
                          value="15"
                        >
                          15:00
                        </option>
                        <option
                          selected={operatingFridayTo === "16" ? true : false}
                          disabled={
                            parseInt(operatingFridayFrom) >= 16 ||
                            (parseInt(breakTimeFrom) >= 16 &&
                              parseInt(breakTimeTo) > 16)
                              ? true
                              : false
                          }
                          value="16"
                        >
                          16:00
                        </option>
                        <option
                          selected={operatingFridayTo === "17" ? true : false}
                          disabled={
                            parseInt(operatingFridayFrom) >= 17 ||
                            (parseInt(breakTimeFrom) >= 17 &&
                              parseInt(breakTimeTo) > 17)
                              ? true
                              : false
                          }
                          value="17"
                        >
                          17:00
                        </option>
                        <option
                          selected={operatingFridayTo === "18" ? true : false}
                          disabled={
                            parseInt(operatingFridayFrom) >= 18 ||
                            (parseInt(breakTimeFrom) >= 18 &&
                              parseInt(breakTimeTo) > 18)
                              ? true
                              : false
                          }
                          value="18"
                        >
                          18:00
                        </option>
                        <option
                          selected={operatingFridayTo === "19" ? true : false}
                          disabled={
                            parseInt(operatingFridayFrom) >= 19 ||
                            (parseInt(breakTimeFrom) >= 19 &&
                              parseInt(breakTimeTo) > 19)
                              ? true
                              : false
                          }
                          value="19"
                        >
                          19:00
                        </option>
                        <option
                          selected={operatingFridayTo === "20" ? true : false}
                          disabled={
                            parseInt(operatingFridayFrom) >= 20 ||
                            (parseInt(breakTimeFrom) >= 20 &&
                              parseInt(breakTimeTo) > 20)
                              ? true
                              : false
                          }
                          value="20"
                        >
                          20:00
                        </option>
                      </Form.Select>
                      {isOperatingFridayToValid ? (
                        <label style={{ fontSize: ".875em", color: "#dc3545" }}>
                          Please select a valid Operating Time.
                        </label>
                      ) : null}
                    </Col>
                  </Col>
                  {/* Saturday */}
                  <Col
                    md="12"
                    style={{
                      color: "#A6A6A6",
                      paddingRight: "10px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Col lg="2" md="" sm="">
                      {["checkbox"].map((type) => (
                        <div key={`inline-${type}`} className="mb-3">
                          <Col
                            lg="12"
                            md="12"
                            sm="12"
                            xs="12"
                            style={{ marginTop: 10 }}
                          >
                            <Form.Check
                              inline
                              label="Sat"
                              name="Saturday"
                              type={type}
                              id={`inline-${type}-2`}
                              onClick={() => {
                                setIsGeneralError(false);
                                setIsSelectedSaturday(!isSelectedSaturday);
                              }}
                              style={{ color: netural400 }}
                              checked={isSelectedSaturday ? true : false}
                            />
                          </Col>
                        </div>
                      ))}
                    </Col>
                    <Col lg="5" md="" sm="" style={{ paddingRight: "10px" }}>
                      <Form.Select
                        aria-label="Default select example"
                        style={{ borderRadius: "12px" }}
                        onChange={(value) => {
                          setIsOperatingSaturdayFromValid(false);
                          setIsOperatingSaturdayToValid(false);
                          setOperatingSaturdayFrom(value.target.value);
                        }}
                        disabled={isSelectedSaturday ? false : true}
                      >
                        <option value="0">From</option>
                        <option
                          selected={
                            operatingSaturdayFrom === "6" ? true : false
                          }
                          value="6"
                          disabled={
                            parseInt(breakTimeFrom) <= 6 &&
                            parseInt(breakTimeTo) > 6
                              ? true
                              : false
                          }
                        >
                          6:00
                        </option>
                        <option
                          selected={
                            operatingSaturdayFrom === "7" ? true : false
                          }
                          value="7"
                          disabled={
                            parseInt(breakTimeFrom) <= 7 &&
                            parseInt(breakTimeTo) > 7
                              ? true
                              : false
                          }
                        >
                          7:00
                        </option>
                        <option
                          selected={
                            operatingSaturdayFrom === "8" ? true : false
                          }
                          value="8"
                          disabled={
                            parseInt(breakTimeFrom) <= 8 &&
                            parseInt(breakTimeTo) > 8
                              ? true
                              : false
                          }
                        >
                          8:00
                        </option>
                        <option
                          selected={
                            operatingSaturdayFrom === "9" ? true : false
                          }
                          value="9"
                          disabled={
                            parseInt(breakTimeFrom) <= 9 &&
                            parseInt(breakTimeTo) > 9
                              ? true
                              : false
                          }
                        >
                          9:00
                        </option>
                        <option
                          selected={
                            operatingSaturdayFrom === "10" ? true : false
                          }
                          value="10"
                          disabled={
                            parseInt(breakTimeFrom) <= 10 &&
                            parseInt(breakTimeTo) > 10
                              ? true
                              : false
                          }
                        >
                          10:00
                        </option>
                        <option
                          selected={
                            operatingSaturdayFrom === "11" ? true : false
                          }
                          value="11"
                          disabled={
                            parseInt(breakTimeFrom) <= 11 &&
                            parseInt(breakTimeTo) > 11
                              ? true
                              : false
                          }
                        >
                          11:00
                        </option>
                        <option
                          selected={
                            operatingSaturdayFrom === "12" ? true : false
                          }
                          value="12"
                          disabled={
                            parseInt(breakTimeFrom) <= 12 &&
                            parseInt(breakTimeTo) > 12
                              ? true
                              : false
                          }
                        >
                          12:00
                        </option>
                        <option
                          selected={
                            operatingSaturdayFrom === "13" ? true : false
                          }
                          value="13"
                          disabled={
                            parseInt(breakTimeFrom) >= 13 &&
                            parseInt(breakTimeTo) > 13
                              ? true
                              : false
                          }
                        >
                          13:00
                        </option>
                        <option
                          selected={
                            operatingSaturdayFrom === "14" ? true : false
                          }
                          value="14"
                          disabled={
                            parseInt(breakTimeFrom) >= 14 &&
                            parseInt(breakTimeTo) > 14
                              ? true
                              : false
                          }
                        >
                          14:00
                        </option>
                        <option
                          selected={
                            operatingSaturdayFrom === "15" ? true : false
                          }
                          value="15"
                          disabled={
                            parseInt(breakTimeFrom) >= 15 &&
                            parseInt(breakTimeTo) > 15
                              ? true
                              : false
                          }
                        >
                          15:00
                        </option>
                        <option
                          selected={
                            operatingSaturdayFrom === "16" ? true : false
                          }
                          value="16"
                          disabled={
                            parseInt(breakTimeFrom) >= 16 &&
                            parseInt(breakTimeTo) > 16
                              ? true
                              : false
                          }
                        >
                          16:00
                        </option>
                        <option
                          selected={
                            operatingSaturdayFrom === "17" ? true : false
                          }
                          value="17"
                          disabled={
                            parseInt(breakTimeFrom) >= 17 &&
                            parseInt(breakTimeTo) > 17
                              ? true
                              : false
                          }
                        >
                          17:00
                        </option>
                        <option
                          selected={
                            operatingSaturdayFrom === "18" ? true : false
                          }
                          value="18"
                          disabled={
                            parseInt(breakTimeFrom) >= 18 &&
                            parseInt(breakTimeTo) > 18
                              ? true
                              : false
                          }
                        >
                          18:00
                        </option>
                        <option
                          selected={
                            operatingSaturdayFrom === "19" ? true : false
                          }
                          value="19"
                          disabled={
                            parseInt(breakTimeFrom) >= 19 &&
                            parseInt(breakTimeTo) > 19
                              ? true
                              : false
                          }
                        >
                          19:00
                        </option>
                        <option
                          selected={
                            operatingSaturdayFrom === "20" ? true : false
                          }
                          value="20"
                          disabled={
                            parseInt(breakTimeFrom) >= 20 &&
                            parseInt(breakTimeTo) > 20
                              ? true
                              : false
                          }
                        >
                          20:00
                        </option>
                      </Form.Select>
                      {isOperatingSaturdayFromValid ? (
                        <label style={{ fontSize: ".875em", color: "#dc3545" }}>
                          Please select a valid Operating Time.
                        </label>
                      ) : null}
                    </Col>
                    <Col lg="5" md="" sm="" style={{ paddingLeft: "10px" }}>
                      <Form.Select
                        aria-label="Default select example"
                        style={{ borderRadius: "12px" }}
                        onChange={(value) => {
                          setIsOperatingSaturdayToValid(false);
                          setOperatingSaturdayTo(value.target.value);
                        }}
                        disabled={
                          isSelectedSaturday && operatingSaturdayFrom
                            ? false
                            : true
                        }
                      >
                        <option value="0">To</option>
                        <option
                          selected={operatingSaturdayTo === "6" ? true : false}
                          disabled={
                            parseInt(operatingSaturdayFrom) >= 6 ||
                            (parseInt(breakTimeFrom) <= 6 &&
                              parseInt(breakTimeTo) > 6)
                              ? true
                              : false
                          }
                          value="6"
                        >
                          6:00
                        </option>
                        <option
                          selected={operatingSaturdayTo === "7" ? true : false}
                          disabled={
                            parseInt(operatingSaturdayFrom) >= 7 ||
                            (parseInt(breakTimeFrom) <= 7 &&
                              parseInt(breakTimeTo) > 7)
                              ? true
                              : false
                          }
                          value="7"
                        >
                          7:00
                        </option>
                        <option
                          selected={operatingSaturdayTo === "8" ? true : false}
                          disabled={
                            parseInt(operatingSaturdayFrom) >= 8 ||
                            (parseInt(breakTimeFrom) <= 8 &&
                              parseInt(breakTimeTo) > 8)
                              ? true
                              : false
                          }
                          value="8"
                        >
                          8:00
                        </option>
                        <option
                          selected={operatingSaturdayTo === "9" ? true : false}
                          disabled={
                            parseInt(operatingSaturdayFrom) >= 9 ||
                            (parseInt(breakTimeFrom) <= 9 &&
                              parseInt(breakTimeTo) > 9)
                              ? true
                              : false
                          }
                          value="9"
                        >
                          9:00
                        </option>
                        <option
                          selected={operatingSaturdayTo === "10" ? true : false}
                          disabled={
                            parseInt(operatingSaturdayFrom) >= 10 ||
                            (parseInt(breakTimeFrom) <= 10 &&
                              parseInt(breakTimeTo) > 10)
                              ? true
                              : false
                          }
                          value="10"
                        >
                          10:00
                        </option>
                        <option
                          selected={operatingSaturdayTo === "11" ? true : false}
                          disabled={
                            parseInt(operatingSaturdayFrom) >= 11 ||
                            (parseInt(breakTimeFrom) <= 11 &&
                              parseInt(breakTimeTo) > 11)
                              ? true
                              : false
                          }
                          value="11"
                        >
                          11:00
                        </option>
                        <option
                          selected={operatingSaturdayTo === "12" ? true : false}
                          disabled={
                            parseInt(operatingSaturdayFrom) >= 12 ||
                            (parseInt(breakTimeFrom) <= 12 &&
                              parseInt(breakTimeTo) > 12)
                              ? true
                              : false
                          }
                          value="12"
                        >
                          12:00
                        </option>
                        <option
                          selected={operatingSaturdayTo === "13" ? true : false}
                          disabled={
                            parseInt(operatingSaturdayFrom) >= 13 ||
                            (parseInt(breakTimeFrom) >= 13 &&
                              parseInt(breakTimeTo) > 13)
                              ? true
                              : false
                          }
                          value="13"
                        >
                          13:00
                        </option>
                        <option
                          selected={operatingSaturdayTo === "14" ? true : false}
                          disabled={
                            parseInt(operatingSaturdayFrom) >= 14 ||
                            (parseInt(breakTimeFrom) >= 14 &&
                              parseInt(breakTimeTo) > 14)
                              ? true
                              : false
                          }
                          value="14"
                        >
                          14:00
                        </option>
                        <option
                          selected={operatingSaturdayTo === "15" ? true : false}
                          disabled={
                            parseInt(operatingSaturdayFrom) >= 15 ||
                            (parseInt(breakTimeFrom) >= 15 &&
                              parseInt(breakTimeTo) > 15)
                              ? true
                              : false
                          }
                          value="15"
                        >
                          15:00
                        </option>
                        <option
                          selected={operatingSaturdayTo === "16" ? true : false}
                          disabled={
                            parseInt(operatingSaturdayFrom) >= 16 ||
                            (parseInt(breakTimeFrom) >= 16 &&
                              parseInt(breakTimeTo) > 16)
                              ? true
                              : false
                          }
                          value="16"
                        >
                          16:00
                        </option>
                        <option
                          selected={operatingSaturdayTo === "17" ? true : false}
                          disabled={
                            parseInt(operatingSaturdayFrom) >= 17 ||
                            (parseInt(breakTimeFrom) >= 17 &&
                              parseInt(breakTimeTo) > 17)
                              ? true
                              : false
                          }
                          value="17"
                        >
                          17:00
                        </option>
                        <option
                          selected={operatingSaturdayTo === "18" ? true : false}
                          disabled={
                            parseInt(operatingSaturdayFrom) >= 18 ||
                            (parseInt(breakTimeFrom) >= 18 &&
                              parseInt(breakTimeTo) > 18)
                              ? true
                              : false
                          }
                          value="18"
                        >
                          18:00
                        </option>
                        <option
                          selected={operatingSaturdayTo === "19" ? true : false}
                          disabled={
                            parseInt(operatingSaturdayFrom) >= 19 ||
                            (parseInt(breakTimeFrom) >= 19 &&
                              parseInt(breakTimeTo) > 19)
                              ? true
                              : false
                          }
                          value="19"
                        >
                          19:00
                        </option>
                        <option
                          selected={operatingSaturdayTo === "20" ? true : false}
                          disabled={
                            parseInt(operatingSaturdayFrom) >= 20 ||
                            (parseInt(breakTimeFrom) >= 20 &&
                              parseInt(breakTimeTo) > 20)
                              ? true
                              : false
                          }
                          value="20"
                        >
                          20:00
                        </option>
                      </Form.Select>
                      {isOperatingSaturdayToValid ? (
                        <label style={{ fontSize: ".875em", color: "#dc3545" }}>
                          Please select a valid Operating Time.
                        </label>
                      ) : null}
                    </Col>
                  </Col>
                </Form.Group>
              </Col>
            </div>
            {isGeneralError ? (
              <label
                style={{
                  fontSize: ".875em",
                  color: "#dc3545",
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "2%",
                }}
              >
                Please select a atleast one day.
              </label>
            ) : null}
            <FormFooterButtons
              displayPreviousButton={true}
              previousButtonText={"Back"}
              handlePreviouButtonClick={handleBackClick}
              nextButtonText={"Generate Schedule"}
              handleNextButtonClick={handleSubmit}
            />
          </Col>
          <Col
            md="5"
            lg="5"
            style={{
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
            }}
          >
            <IllustrationImage />
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default ServiceDurationForm;
