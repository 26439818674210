import { Form, Row, Col } from "react-bootstrap";
import { useState, useEffect } from "react";
import { neturalBlack } from "../../constants/color";
import IllustrationImage from "../shared/illustration-image/illustration-image.component";
import FormFooterButtons from "../../components/form-footer-buttons/form-footer-buttons.component";
import SectionTitle from "../section-title/section-title.component";

const ProductPricingForm = ({
  handleBackClick,
  handleNextClick,
  setProductPricing,
  formValue,
}) =>{
  const [validated, setValidated] = useState(false);
  const [productPrice, setProductPrice] = useState("");
  const [productRestricedUser, setProductRestricedUser] = useState("0");
  const [productDiscount, setProductDiscount] = useState("");
  const [productQuantity, setProductQuantity] = useState("");
  const [productFinalPrice, setProductFinalPrice] = useState("00.00");
  const [productSKU, setProductSKU] = useState("");

  useEffect(() => {
    if (formValue.productPrice && formValue.productPrice !== "") {
      setProductPrice(formValue.productPrice);
      setProductRestricedUser(formValue.productRestricedUser);
      setProductDiscount(formValue.productDiscount);
      setProductQuantity(formValue.productQuantity);
      setProductSKU(formValue.productSKU)
    }
  }, [formValue]);

  useEffect(() => {
    if (productPrice || productDiscount) {
      const discountPrice = (productPrice * productDiscount) / 100;
      let total = productPrice - discountPrice;
      setProductFinalPrice(total.toFixed(2));
    } else {
      setProductFinalPrice("0.00");
    }
  }, [productPrice, productDiscount]);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);

    if (form.checkValidity() === true) {
      setProductPricing(
        productPrice,
        productRestricedUser,
        productDiscount,
        productQuantity,
        productSKU
      );
      handleNextClick();
    }
  };

  return (
    <div>
      <div style={{ marginTop: "5%", marginBottom: "3%" }}>
        <SectionTitle title={"Price Information"} subtitle={"Fill all fields below"} />
      </div>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Row className="mb-3">
          <Col md="6" lg="6" style={{marginLeft:"3%"}}>
            <div style={{ display: "flex", marginTop: "20px" }}>
              <Col
                md="6"
                lg="6"
                style={{ color: "#A6A6A6", paddingRight: "10px" }}
              >
                <Form.Group
                  controlId="validationCustom01"
                  style={{ color: "#A6A6A6" }}
                >
                  <Form.Label>Quantity</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    inputMode="numeric"
                    placeholder="Quantity"
                    style={{ borderRadius: "12px" }}
                    value={productQuantity}
                    onChange={(event) => {
                      const newValue = event.target.value;
                      const regex = /^[0-9\b]+$/;
                      if (
                        (newValue === "" || regex.test(newValue)) &&
                        newValue.length < 7
                      ) {
                        setProductQuantity(newValue);
                      }
                    }}
                    step={"0.01"}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide a valid Quantity.
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col
                md="6"
                lg="6"
                style={{ color: "#A6A6A6", paddingLeft: "10px" }}
              >
                <Form.Group
                  controlId="validationCustom01"
                  style={{ color: "#A6A6A6" }}
                >
                  <Form.Label>Units restricted per user</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    style={{ borderRadius: "12px" }}
                    onChange={(value) => {
                      setProductRestricedUser(value.target.value);
                    }}
                  >
                    <option
                      selected={productRestricedUser === "0" ? true : false}
                      value="0"
                      disabled
                    >
                      Select any one if required.
                    </option>
                    <option
                      selected={productRestricedUser === "1" ? true : false}
                      value="1"
                    >
                      Once
                    </option>
                    <option
                      selected={productRestricedUser === "2" ? true : false}
                      value="2"
                    >
                      Twice
                    </option>
                    <option
                      selected={productRestricedUser === "3" ? true : false}
                      value="3"
                    >
                      Thrice
                    </option>
                    <option
                      selected={productRestricedUser === "4" ? true : false}
                      value="4"
                    >
                      Four times
                    </option>
                    <option
                      selected={productRestricedUser === "5" ? true : false}
                      value="5"
                    >
                      Five times
                    </option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    Please provide a valid currency.
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </div>
            <div style={{ display: "flex", marginTop: "20px" }}>
              <Col
                md="6"
                lg="6"
                style={{ color: "#A6A6A6", paddingRight: "10px" }}
              >
                <Form.Group
                  controlId="validationCustom01"
                  style={{ color: "#A6A6A6" }}
                >
                  <Form.Label>Price</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      inputMode="numeric"
                      placeholder="55"
                      style={{ borderRadius: "12px" }}
                      value={productPrice}
                      onChange={(value) => {
                        const newValue = value.target.value.trim();
                        // Check if the input is empty and handle backspace/delete key
                        if (newValue === '' || newValue === '.') {
                          setProductPrice('');
                        } else if (/^[0-9]+(\.[0-9]*)?$/.test(newValue) ) {
                          setProductPrice(newValue);
                        }else{
                          setProductPrice(productPrice)
                        }
                      }}
                      step="0.01"
                      minLength={4}
                      maxLength={7}
                    />
                  <Form.Label
                    style={{ fontSize: 12, color: "#7d7d7d", marginLeft: "4%" }}
                  >
                    Example: C$50.00
                  </Form.Label>
                  <Form.Control.Feedback type="invalid">
                    Please provide a valid Price.
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col
                md="6"
                lg="6"
                style={{ color: "#A6A6A6", paddingLeft: "10px" }}
              >
                <Form.Group
                  controlId="validationCustom01"
                  style={{ color: "#A6A6A6" }}
                >
                  <Form.Label>Discount</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      inputMode="numeric"
                      placeholder="Discount"
                      style={{ borderRadius: "12px" }}
                      value={productDiscount}
                      onChange={(event) => {
                        const newValue = event.target.value.trim();
                        // Check if the input is empty and handle backspace/delete key
                        if (newValue === '' || newValue === '.') {
                          setProductDiscount('');
                        } else if (/^[0-9]+(\.[0-9]*)?$/.test(newValue) && parseFloat(newValue) >= 0 && parseFloat(newValue) <= 100) {
                          setProductDiscount(newValue);
                        }else{
                          setProductDiscount(productDiscount)
                        }
                      }}
                      step="0.01"
                      minLength={1}
                      maxLength={5}
                    />
                  <Form.Label
                    style={{ fontSize: 12, color: "#7d7d7d", marginLeft: "4%" }}
                  >
                    Example: 50 %
                  </Form.Label>
                  <Form.Control.Feedback type="invalid">
                    {productDiscount < 0 || productDiscount > 100
                      ? "Discount must be between 0 and 100"
                      : "Please provide a valid Discount."}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </div>
            <div
              style={{
                display: "flex",
                marginTop: "20px",
                justifyContent: "center",
              }}
            >
              <Col
                md="6"
                lg="6"
                style={{ color: "#A6A6A6", paddingRight: "10px" }}
              >
                <Form.Group
                  controlId="validationCustom01"
                  style={{ color: "#A6A6A6" }}
                >
                  <Form.Label>SKU</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder="SKU"
                    style={{ borderRadius: "12px" }}
                    value={productSKU}
                    onChange={(event) => {
                      setProductSKU(event.target.value);
                    }}
                    step={"0.01"}
                    minLength={3}
                    maxLength={15}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide a valid SKU.
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </div>
            <div style={{ display: "flex", marginTop: "20px" }}>
              <Col
                md="6"
                lg="6"
                style={{
                  color: "#A6A6A6",
                  paddingRight: "10px",
                  display: "flex",
                }}
              >
                <Col md="4" lg="4">
                  <Form.Group
                    controlId="validationCustom01"
                    style={{ color: "#A6A6A6", display: "flex" }}
                  >
                    <Form.Label
                      style={{
                        fontSize: 16,
                        fontWeight: "bold",
                        color: neturalBlack,
                      }}
                    >
                      Final price
                    </Form.Label>
                  </Form.Group>
                </Col>
                <Col
                  md="8"
                  lg="8"
                  style={{ display: "flex", justifyContent: "right" }}
                >
                  <Form.Group
                    controlId="validationCustom01"
                    style={{ color: "#A6A6A6", display: "flex" }}
                  >
                    <Form.Label
                      style={{
                        fontSize: 16,
                        fontWeight: "bold",
                        color: neturalBlack,
                      }}
                    >
                      $ {productFinalPrice}
                    </Form.Label>
                  </Form.Group>
                </Col>
              </Col>
            </div>
            <FormFooterButtons displayPreviousButton={true} previousButtonText={"Back"} handlePreviouButtonClick={handleBackClick} nextButtonText={"Continue"}/>
          </Col>
          <Col
            md="5"
            lg="5"
            style={{
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
            }}
          >
            <IllustrationImage/>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default ProductPricingForm;
