import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { enc,AES } from 'crypto-js';
import moment from "moment";
import { auth } from '../firebase';
import { useDispatch } from 'react-redux';
import {  clearUser } from '../actions/userActions';
import { clearBusinesProfile } from "../actions/businessProfileActions";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { clearSales } from '../actions/salesAction';

// Custom hook to verify token expiry globally
export function useAuth() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch =useDispatch();
  useEffect(() => {
    // Replace this with your token expiry logic
    const isTokenExpired = checkTokenExpiry(); // Implement your token expiry check logic here
    // console.log(location.pathname,"LOCATIOn-------")
    if (isTokenExpired && location.pathname !== "/login") {
    toast.error('Your session is expired', {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      });
      auth.signOut().then(()=>{
        navigate("/login")
        dispatch(clearBusinesProfile());
        dispatch(clearUser());
        dispatch(clearSales());
      }).catch((error)=>{
        // console.log(error,"Error in logout");
        toast.info('Something went wrong, Please try again later', {
          style:{left:-50  ,width:'400px'},
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          });
      })
    } 
  },[location,navigate]);

  // Token expiry check logic
  function checkTokenExpiry() {
    // Return true if the token is expired, false otherwise
    let encryptedAuthTime = localStorage.getItem("expiryTime");
    if(encryptedAuthTime){
    const decryptedExpirationTime = AES.decrypt(encryptedAuthTime, process.env.REACT_APP_ENCRYPTION_KEY).toString(enc.Utf8);
    if(decryptedExpirationTime){
        const currentTime =moment(new Date());
        const authTime = moment.unix(decryptedExpirationTime);
        const duration = moment.duration(currentTime.diff(authTime));
        if(duration.asHours() > 24){
            return true;
        }else{
            return false;
        }
    }else{
        return true;
    }
    }else{
        return true;
    }
  }

  return null; // This hook doesn't render any UI
}
