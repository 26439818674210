import { Image } from "react-bootstrap";
import { neturalBlack, primaryGrey } from "../../constants/color";

const DesktopOnlyScreen =()=> {
  return (
    <div
      style={{
        width: "100%",
        height: "90vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Image src="./images/Mobile-screen.png" alt="Mobile" style={{ width: '60%' }} />
      <label style={{ fontSize: 24, color: neturalBlack, fontWeight: 700, marginTop: '2%' }}>
        Desktop-only
      </label>
      <label style={{ fontSize: 16, color: primaryGrey, fontWeight: 500, width: '90%', marginTop: '3%' }}>
        To create your service, please visit our portal using a desktop to
        benefit from all features built specifically for your business.
      </label>
    </div>
  );
}
export default DesktopOnlyScreen;
