import { Form, Row, Col } from "react-bootstrap";
import { useEffect, useState } from "react";
import {
  CATEGORY_SEARCH,
  FETCH_SUBCATEGORIES_BY_CATEGORY,
} from "../../services/graphql/product";
import { useLazyQuery } from "@apollo/client";
import LoadingSpinner from "../shared/loading-spinner/loading-spinner.component";
import IllustrationImage from "../shared/illustration-image/illustration-image.component";
import FormFooterButtons from "../../components/form-footer-buttons/form-footer-buttons.component";
import SectionTitle from "../section-title/section-title.component";
import Multiselect from "multiselect-react-dropdown";
import { primaryOrange } from "../../constants/color";

const ProductGeneralInfoForm = ({
  handleNextClick,
  setGeneralInfo,
  formValue,
}) => {
  const [validated, setValidated] = useState(false);

  const [productTitle, setProductTitle] = useState("");
  const [productDescription, setProductDescription] = useState("");
  const [productDetail, setProductDetail] = useState("");
  const [errorFields, setErrorFields] = useState({
    productTitle: "",
    productDescription: "",
    productDetail: "",
  });
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [categoriesList, setCategoriesList] = useState([]);
  const [subcategoriesList, setSubcategoriesList] = useState([]);
  const [selectedSubcategories, setSelectedSubcategories] = useState([]);
  const [isSubcategories, setIsSubcategories] = useState(false);

  useEffect(() => {
    if (formValue.productTitle && formValue.productTitle !== "") {
      setProductTitle(formValue.productTitle);
      setProductDescription(formValue.productDescription);
      setProductDetail(formValue.productDetail);
      setSelectedCategory(formValue.selectedCategory);
      setSelectedSubcategories(formValue.selectedSubcategories);
      if (
        formValue.selectedSubcategories &&
        formValue.selectedSubcategories.length > 0
      ) {
        setIsSubcategories(true);
      }
    }
    getCategory();
  }, []);

  const getCategory = async () => {
    await categorySearch({
      variables: {
        query: "",
      },
    });
  };

  const handleOnChange = async (selectedList, type) => {
    // console.log("Selected category----------------", selectedList, type);
    if (type === "category") {
      if (selectedList && selectedList[0].subCategories.length > 0) {
        // await selectedList.map((category) => {
        //   delete category.__typename;
        //   delete category.isChecked;
        //   delete category.interestsCount;
        //   delete category.subCategories;
        //   delete category.subCategoryCount;
        // });
        let selectedItemList = [
          {
            _id: selectedList[0]._id,
            name: selectedList[0].name,
          },
        ];
        setSelectedCategory(selectedItemList);
        await fetchSubCategoriesByCategoryId({
          variables: {
            categoryId: selectedList[0]._id,
          },
        });
        setIsSubcategories(true);
      } else {
        let selectedItemList = [
          {
            _id: selectedList[0]._id,
            name: selectedList[0].name,
          },
        ];
        setSelectedCategory(selectedItemList);
        setIsSubcategories(false);
      }
    } else {
      let removedList = [
        {
          _id: selectedCategory[0]._id,
          name: selectedCategory[0].name,
          subCategories: selectedList,
        },
      ];
      setSelectedCategory(removedList);
      setSelectedSubcategories(selectedList);
    }
  };

  const handleOnRemove = (selectedList, type) => {
    if (type === "category") {
      if (selectedList.length === 0) {
        setSelectedCategory([]);
        setIsSubcategories(false);
        // Clear the selected sub-category list when the category is removed
        setSelectedSubcategories([]);
      } else {
        setSelectedCategory(selectedList);
        setIsSubcategories(true);
      }
    } else {
      selectedCategory.subCategories = selectedList;
      setSelectedSubcategories(selectedList); // Update the selected sub-categories list
    }
  };

  const [fetchSubCategoriesByCategoryId] = useLazyQuery(
    FETCH_SUBCATEGORIES_BY_CATEGORY,
    {
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        if (data && data.fetchSubCategoriesByCategoryId) {
          let listOfSubCategory = data.fetchSubCategoriesByCategoryId;
          setSubcategoriesList(listOfSubCategory);
        } else {
        }
      },
    }
  );

  const [categorySearch, { loading: loadingCategoryList }] = useLazyQuery(
    CATEGORY_SEARCH,
    {
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        if (data && data.categorySearch) {
          let listOfCategory = data.categorySearch;
          setCategoriesList(listOfCategory);
        } else {
        }
      },
    }
  );

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();

    // Custom validation logic
    const errors = {};

    if (productTitle.trim().length === 0) {
      errors.productTitle = "Please provide a Product title.";
    } else if (productTitle.length < 4) {
      errors.productTitle =
        "Please provide a Product title with a minimum length of 4 characters.";
    } else if (productTitle.length > 100) {
      errors.productTitle =
        "Please provide a Product title with a maximum length of 100 characters.";
    }

    if (selectedCategory && selectedCategory.length === 0) {
      errors.selectedCategory = "Please select any one category";
    }

    if (productDescription.trim().length === 0) {
      errors.productDescription = "Please provide a Product description.";
    } else if (productDescription.length < 50) {
      errors.productDescription =
        "Please provide a Product description with a minimum length of 50 characters.";
    } else if (productDescription.length > 3000) {
      errors.productDescription =
        "Please provide a Product description with a maximum length of 3000 characters.";
    }

    if (productDetail.trim().length === 0) {
      errors.productDetail = "Please provide a Product detail.";
    } else if (productDetail.length < 50) {
      errors.productDetail =
        "Please provide a Product detail with a minimum length of 50 characters.";
    } else if (productDetail.length > 3000) {
      errors.productDetail =
        "Please provide a Product detail with a maximum length of 3000 characters.";
    }

    // Set errors in state
    setErrorFields(errors);

    if (Object.keys(errors).length === 0) {
      setValidated(true); // Mark the form as validated
      setGeneralInfo(
        productTitle,
        selectedCategory,
        selectedSubcategories,
        productDescription,
        productDetail
      );
      handleNextClick();
    }
  };

  return loadingCategoryList && categoriesList.length === 0 ? (
    <LoadingSpinner />
  ) : (
    <div>
      <div style={{ marginTop: "5%", marginBottom: "3%" }}>
        <SectionTitle
          title={"General Information"}
          subtitle={"Fill all fields below"}
        />
      </div>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Row className="mb-3" style={{ marginLeft: "10%" }}>
          <Col md="6" lg="6" style={{ marginLeft: "3%" }}>
            <div style={{ display: "flex", marginTop: "20px" }}>
              <Col
                xl={12}
                md={12}
                lg={12}
                style={{ color: "#A6A6A6", paddingRight: "10px" }}
              >
                <Form.Group
                  controlId="validationCustom01"
                  style={{ color: "#A6A6A6" }}
                >
                  <Form.Label>Product title</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder="Product title"
                    value={productTitle}
                    onChange={(e) => setProductTitle(e.target.value)}
                    minLength={4}
                    maxLength={100}
                    isInvalid={!!errorFields.productTitle}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errorFields.productTitle}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </div>
            <div style={{ marginTop: "3%", display: "flex" }}>
              <Col
                xl="6"
                md="6"
                lg="6"
                style={{ color: "#A6A6A6", paddingRight: "10px" }}
              >
                <Form.Group
                  controlId="validationCustom02"
                  style={{ color: "#A6A6A6" }}
                >
                  <Form.Label>Category</Form.Label>
                  <Multiselect
                    options={categoriesList} // Options to display in the dropdown
                    // selectedValues={this.state.selectedValue} // Preselected value to persist in dropdown
                    onSelect={(selectedList, selectedItem) => {
                      handleOnChange(selectedList, "category");
                    }} // Function will trigger on select event
                    onRemove={(selectedList) => {
                      handleOnRemove(selectedList, "category");
                    }} // Function will trigger on remove event
                    displayValue="name" // Property name to display in the dropdown options
                    showCheckbox
                    selectionLimit={1}
                    selectedValues={selectedCategory}
                    closeOnSelect={true}
                    style={{
                      chips: {
                        background: primaryOrange,
                      },
                    }}
                    isInvalid={!!errorFields.selectedCategory}
                  />
                  {errorFields.selectedCategory && (
                    <label style={{ fontSize: "0.875rem", color: "#dc3545" }}>
                      {errorFields.selectedCategory}
                    </label>
                  )}
                </Form.Group>
              </Col>
              <Col
                xl="6"
                md="6"
                lg="6"
                style={{ color: "#A6A6A6", paddingLeft: "10px" }}
              >
                <Form.Group
                  controlId="validationCustom02"
                  style={{ color: "#A6A6A6" }}
                >
                  <Form.Label>Sub-category</Form.Label>
                  <Multiselect
                    options={subcategoriesList} // Options to display in the dropdown
                    // selectedValues={this.state.selectedValue} // Preselected value to persist in dropdown
                    onSelect={(selectedList) => {
                      handleOnChange(selectedList, "subCategory");
                    }} // Function will trigger on select event
                    onRemove={(selectedList) => {
                      handleOnRemove(selectedList, "subCategory");
                    }} // Function will trigger on remove event
                    displayValue="name" // Property name to display in the dropdown options
                    showCheckbox
                    selectionLimit={3}
                    selectedValues={selectedSubcategories}
                    style={{
                      chips: {
                        background: primaryOrange,
                      },
                    }}
                    disable={!isSubcategories}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please select a sub-category.
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </div>
            <div style={{ marginTop: "20px" }}>
              <Col md="12" style={{ color: "#A6A6A6" }}>
                <Form.Group controlId="validationCustom03">
                  <Form.Label>Product description</Form.Label>
                  <Form.Control
                    required
                    as="textarea"
                    rows={5}
                    placeholder="Product description"
                    value={productDescription}
                    onChange={(e) => setProductDescription(e.target.value)}
                    minLength={50}
                    maxLength={3000}
                    isInvalid={!!errorFields.productDescription}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errorFields.productDescription}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md="12" style={{ color: "#A6A6A6" }}>
                <Form.Group controlId="validationCustom04">
                  <Form.Label>Product detail</Form.Label>
                  <Form.Control
                    required
                    as="textarea"
                    rows={5}
                    placeholder="Product detail"
                    value={productDetail}
                    onChange={(e) => setProductDetail(e.target.value)}
                    minLength={50}
                    maxLength={3000}
                    isInvalid={!!errorFields.productDetail}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errorFields.productDetail}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </div>
            <FormFooterButtons
              displayPreviousButton={false}
              nextButtonText={"Continue"}
            />
          </Col>
          <Col
            md="5"
            lg="5"
            style={{
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
            }}
          >
            <IllustrationImage />
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default ProductGeneralInfoForm;
