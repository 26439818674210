import React from 'react';
import {Navigate } from 'react-router-dom';

const PrivateRoute = ({ element: Element, ...props }) => {
  let isAuthenticated = localStorage.getItem("isAuthenticated");
  return isAuthenticated ? (
    <Element {...props} />
  ) : (
    <Navigate to="/login" replace />
  );
};

export default PrivateRoute;
