import { Route, Routes } from "react-router-dom";
import SalesList from "../../components/sales/sales-list.component";
import SalesProductDetail from "../../components/sales-product-detail/sales-product-detail.component";
import SalesServiceDetail from "../../components/sales-service-detail/sales-service-detail.component";

const Sales = () => {

    return(
        <Routes>
            <Route index element={<SalesList />} />
            <Route path="productDetail/:orderId" element={<SalesProductDetail />} />
            <Route path="serviceDetail/:orderId" element={<SalesServiceDetail />} />
        </Routes>
    )
}

export default Sales;