import { useState, useEffect } from "react";
import { React } from "react";
import { Form, Col, Row } from "react-bootstrap";
import { IconContext } from "react-icons";
import { IoInformationCircleOutline } from "react-icons/io5";
import IllustrationImage from "../shared/illustration-image/illustration-image.component";
import FormFooterButtons from "../../components/form-footer-buttons/form-footer-buttons.component";
import SectionTitle from "../section-title/section-title.component";

const ServiceQuestionForm = ({
  handleBackClick,
  handleNextClick,
  setServiceQuestion,
  formValue,
}) => {
  const [isSelectedOption, setIsSelectedOption] = useState("");
  const [isSelectedOnlineOption, setIsSelectedOnlineOption] = useState(false);
  const [isSelectedInPersonOption, setIsSelectedInPersonOption] =
    useState(false);
  const [isError, setIsError] = useState("");

  useEffect(() => {
    if (formValue.servicePlace && formValue.servicePlace !== "") {
      setIsSelectedOption(formValue.servicePlace);
      setIsSelectedOnlineOption(formValue.serviceInPerson);
      setIsSelectedInPersonOption(formValue.serviceOnline);
    }
  }, []);

  const handleSubmit = () => {
    if (isSelectedOption === "option1") {
      setServiceQuestion(
        isSelectedOption,
        isSelectedOnlineOption,
        isSelectedInPersonOption
      );
      handleNextClick();
    } else if (isSelectedOption === "option2") {
      if (isSelectedInPersonOption || isSelectedOnlineOption) {
        setServiceQuestion(
          isSelectedOption,
          isSelectedOnlineOption,
          isSelectedInPersonOption
        );
        handleNextClick();
      } else {
        setIsError("Please select proper option.");
      }
    } else {
      setIsError("Please select proper option.");
    }
  };

  return (
    <div>
      <div>
        <div
          style={{
            marginTop: "5%",
            backgroundColor: "#FFF3DA",
            padding: 16,
            marginRight: "5%",
          }}
        >
          <IconContext.Provider value={{ size: 22, color: "black" }}>
            <IoInformationCircleOutline />
          </IconContext.Provider>
          <label
            style={{
              fontSize: "16px",
              fontWeight: "500",
              color: "#545454",
              marginLeft: 10,
            }}
          >
            You won’t be able to edit this information later.
          </label>
        </div>
        <div style={{ marginTop: "5%", marginBottom: "3%" }}>
        <SectionTitle title={"Where will your service take place?"} subtitle={"Select one of the fields below"} />
      </div>
      </div>
      <Form noValidate>
        <Row>
          <Col lg="6" md="6" sm="6" xs="6" style={{marginLeft:"3%"}}>
            {["radio"].map((type) => (
              <div key={`default-${type}`} className="mb-3">
                <Row>
                  <Col
                    lg="12"
                    md="12"
                    sm="12"
                    xs="12"
                    style={{ display: "flex", marginTop: "1%" }}
                  >
                    <Col lg="1" md="1" sm="1" xs="1">
                      <Form.Check.Input
                        type={type}
                        name="group1"
                        onClick={() => {
                          setIsSelectedOption("option1");
                        }}
                        checked={isSelectedOption === "option1" ? true : false}
                      />
                    </Col>
                    <Col lg="11" md="11" sm="11" xs="11">
                      <Col lg="12" md="12" sm="12" xs="12">
                        <lable
                          style={{
                            marginLeft: 10,
                            color: "#1B1C1E",
                            fontSize: "16px",
                          }}
                        >
                          I’ll need to go to the customer’s address
                        </lable>
                      </Col>
                      <Col lg="12" md="12" sm="12" xs="12">
                        <label
                          style={{
                            marginLeft: 10,
                            color: "#545454",
                            fontSize: "14px",
                          }}
                        >
                          Services that require your presence at the customer’s
                          address such as cleaning, painting, plumbing, moving,
                          etc.
                        </label>
                      </Col>
                    </Col>
                  </Col>
                  <Col
                    lg="12"
                    md="12"
                    sm="12"
                    xs="12"
                    style={{ display: "flex", marginTop: "3%" }}
                  >
                    <Col lg="1" md="1" sm="1" xs="1">
                      <Form.Check.Input
                        type={type}
                        name="group1"
                        onClick={() => {
                          setIsSelectedOption("option2");
                        }}
                        checked={isSelectedOption === "option2" ? true : false}
                      />
                    </Col>
                    <Col lg="11" md="11" sm="11" xs="11">
                      <Col lg="12" md="12" sm="12" xs="12">
                        <lable
                          style={{
                            marginLeft: 10,
                            color: "#1B1C1E",
                            fontSize: "16px",
                          }}
                        >
                          I’ll meet the customer at my address and/or virtually
                        </lable>
                      </Col>
                      <Col lg="12" md="12" sm="12" xs="12">
                        <label
                          style={{
                            marginLeft: 10,
                            color: "#545454",
                            fontSize: "14px",
                          }}
                        >
                          Services that are provided at a specific location and
                          or virtually, such as medical consultations, meetings,
                          restaurants, tours etc.
                        </label>
                      </Col>
                      {isSelectedOption === "option2"
                        ? ["checkbox"].map((type) => (
                            <div key={`inline-${type}`} className="mb-3">
                              <Col
                                lg="12"
                                md="12"
                                sm="12"
                                xs="12"
                                style={{ marginTop: 10 }}
                              >
                                <Form.Check
                                  inline
                                  label="In person"
                                  name="In person"
                                  type={type}
                                  id={`inline-${type}-2`}
                                  onClick={() => {
                                    setIsSelectedInPersonOption(
                                      !isSelectedInPersonOption
                                    );
                                  }}
                                  checked={
                                    isSelectedInPersonOption ? true : false
                                  }
                                />
                              </Col>
                              <Col
                                lg="12"
                                md="12"
                                sm="12"
                                xs="12"
                                style={{ marginTop: 5 }}
                              >
                                <Form.Check
                                  inline
                                  label="Online"
                                  name="Online"
                                  type={type}
                                  id={`inline-${type}-1`}
                                  onClick={() => {
                                    setIsSelectedOnlineOption(
                                      !isSelectedOnlineOption
                                    );
                                  }}
                                  checked={
                                    isSelectedOnlineOption ? true : false
                                  }
                                />
                              </Col>
                            </div>
                          ))
                        : null}
                    </Col>
                  </Col>
                  {isError ? (
                    <Col
                      lg="12"
                      md="12"
                      sm="12"
                      xs="12"
                      style={{
                        display: "flex",
                        marginTop: "3%",
                        justifyContent: "center",
                      }}
                    >
                      <label style={{ fontSize: ".875em", color: "#dc3545" }}>
                        {" "}
                        {isError}{" "}
                      </label>
                    </Col>
                  ) : null}
                </Row>
              </div>
            ))}
            <FormFooterButtons displayPreviousButton={true} previousButtonText={"Back"} handlePreviouButtonClick={handleBackClick} nextButtonText={"Continue"} handleNextButtonClick={handleSubmit}/>
          </Col>
          <Col
            md="5"
            lg="5"
            style={{
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
            }}
          >
            <IllustrationImage/>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default ServiceQuestionForm;
