import HeaderSection from "../header/header.component";
import { useState, useEffect, useCallback } from "react";
import CustomCalendar from "../custom-calendar/custom-calendar.component";
import BookingList from "../booking-list/booking-list.component";
import { useLazyQuery } from "@apollo/client";
import { FETCH_BOOKING_FOR_SELECTED_DATE } from "../../services/graphql/booking";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { format } from "date-fns";
import { netural50 } from "../../constants/color";
import { Fragment } from "react";

const Bookings = () => {
  const businesprofile = useSelector((state) => state.businesprofile);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [bookingList, setBookingList] = useState([]);
  const [serviceColorMap, setServiceColorMap] = useState({});
  const onChangeDate = (value) => {
    setSelectedDate(value);
  };

  const generateNewColor = useCallback(() => {
    let color;
    do {
      color = `#${Math.random().toString(16).slice(2, 8)}`;
    } while (color === "#ffffff");
    return color;
  });

  const [
    fetchBookingForSelectedDate,
    { loading: loadingBookingForSelectedDate },
  ] = useLazyQuery(FETCH_BOOKING_FOR_SELECTED_DATE, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      if (data && data.fetchBookingsForSelectedDate) {
        let bookingData = data.fetchBookingsForSelectedDate;
        let newServiceColorMap = { ...serviceColorMap };
        let validBookings = [];
        bookingData.bookings.forEach((booking) => {
          if (!booking.error) {
            newServiceColorMap[booking.bookingId] = generateNewColor();
            validBookings.push(booking);
          } else {
            console.error(
              `Error fetching booking ${booking.bookingId} : ${booking.error}`
            );
          }
        });
        setServiceColorMap(newServiceColorMap);
        setBookingList(bookingData.bookings);
      }
    },
    onError: (error) => {
      toast.error(error.message, {
        style: { width: "450px" },
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored ",
      });
    },
  });
  const fetchBookinData = useCallback(async () => {
    let formatedDate = format(selectedDate, "yyyy-MM-dd");
    await fetchBookingForSelectedDate({
      variables: {
        selectedDate: formatedDate,
        businessId: businesprofile._id,
      },
    });
  }, [selectedDate, fetchBookingForSelectedDate, businesprofile._id]);
  useEffect(() => {
    fetchBookinData();
  }, [fetchBookinData]);
  return (
    <div className="main-div-right-section">
      <Fragment>
        <HeaderSection currentTab={"Bookings"} />
        <div style={{ display: "flex", margin: "3%" }}>
          <CustomCalendar
            selectedDate={selectedDate}
            onChangeDate={onChangeDate}
          />
          <BookingList
            data={bookingList}
            bookingListColors={serviceColorMap}
            selecetdDate={selectedDate}
            loading={loadingBookingForSelectedDate}
          />
        </div>
      </Fragment>
    </div>
  );
};
export default Bookings;
