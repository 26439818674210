import "../../App.css";
import { Col, Row } from "react-bootstrap";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import HeaderSection from "../header/header.component";
import ServiceClientForm from "../service-client-form/service-client-form.component";
import ServiceGeneralInfoForm from "../service-general-info-form/service-general-info-form.component";
import ServicePricingForm from "../../components/service-pricing-form/service-pricing-form.component";
import ServiceDurationForm from "../service-duration-form/service-duration-form.component";
import ServiceImagesForm from "../service-images-form/service-images-form.component"
import ServiceLocationForm from "../../components/service-location-form/service-location-form.component.jsx";
import ServiceQuestionForm from "../../components/service-question-form/service-question-form.component";
import ServiceScheduleForm from "../../components/service-schedule-form/service-schedule-form.component.jsx";
import ItemCreatedConfirmation from "../../components/item-created-confirmation/item-created-confirmation.component";

const CreateServiceForm = ()=> {
  const [formData, setFormData] = useState({
    name: "",
    serviceStartDate: "",
    serviceEndDate: "",
    serviceDescription: "",
    servicePrice: "",
    serviceCurrency: "",
    serviceDiscount: "",
    servicePlace: "",
    serviceInPerson: "",
    serviceOnline: "",
    serviceCountry: "",
    serviceState: "",
    serviceCity: "",
    serviceNoClient: "",
    serviceMaxClient: "",
    serviceTimeHour: "",
    serviceTimeMin: "",
    serviceBreakFrom: "",
    serviceBreakTo: "",
    serviceOperatingSunday: false,
    serviceOperatingSundayFrom: "",
    serviceOperatingSundayTo: "",
    slotsBeforeBreakSunday: "",
    slotsAfterBreakSunday: "",
    serviceOperatingMonday: false,
    serviceOperatingMondayFrom: "",
    serviceOperatingMondayTo: "",
    slotsBeforeBreakMonday: "",
    slotsAfterBreakMonday: "",
    serviceOperatingTuesday: false,
    serviceOperatingTuesdayFrom: "",
    serviceOperatingTuesdayTo: "",
    slotsBeforeBreakTuesday: "",
    slotsAfterBreakTuesday: "",
    serviceOperatingWednesday: false,
    serviceOperatingWednesdayFrom: "",
    serviceOperatingWednesdayTo: "",
    slotsBeforeBreakWednesday: "",
    slotsAfterBreakWednesday: "",
    serviceOperatingThursday: false,
    serviceOperatingThursdayFrom: "",
    serviceOperatingThursdayTo: "",
    slotsBeforeBreakThursday: "",
    slotsAfterBreakThursday: "",
    serviceOperatingFriday: false,
    serviceOperatingFridayFrom: "",
    serviceOperatingFridayTo: "",
    slotsBeforeBreakFirday: "",
    slotsAfterBreakFirday: "",
    serviceOperatingSaturday: false,
    serviceOperatingSaturdayFrom: "",
    serviceOperatingSaturdayTo: "",
    slotsBeforeBreakSaturday: "",
    slotsAfterBreakSaturday: "",
    serviceImageList: [],
  });

  const [stepsCount, setSetpsCount] = useState(1);

  const navigate = useNavigate();

  const handleContinueClick = () => {
    setSetpsCount(stepsCount + 1);
  };

  const handlePageBackClick = () => {
    setSetpsCount(stepsCount - 1);
  };

  const handleBrowserBackButton = () => {
    if (stepsCount > 1) {
      setSetpsCount(stepsCount - 1);
    } else {
      navigate(-1);
    }
    window.addEventListener("popstate", handleBrowserBackButton);
    return () => {
      window.removeEventListener("popstate", handleBrowserBackButton);
    };
  };

  const setGeneralInfo = (
    name,
    serviceStartDate,
    serviceEndDate,
    serviceDescription
  ) => {
    setFormData({
      ...formData,
      name: name,
      serviceStartDate: serviceStartDate,
      serviceEndDate: serviceEndDate,
      serviceDescription: serviceDescription,
    });
  };

  const setServicePrice = (servicePrice, serviceCurrency, serviceDiscount) => {
    setFormData({
      ...formData,
      servicePrice: servicePrice,
      serviceCurrency: serviceCurrency,
      serviceDiscount: serviceDiscount,
    });
  };

  const setServiceQuestion = (
    isSelectedOption,
    isSelectedOnlineOption,
    isSelectedInPersonOption
  ) => {
    setFormData({
      ...formData,
      servicePlace: isSelectedOption,
      serviceInPerson: isSelectedInPersonOption,
      serviceOnline: isSelectedOnlineOption,
    });
    // console.log("Value----------------------",isSelectedOption,isSelectedOnlineOption,isSelectedInPersonOption)
  };

  const setServiceLocation = (allCountry, selectedState, selectedCity) => {
    setFormData({
      ...formData,
      serviceCountry: allCountry,
      serviceState: selectedState,
      serviceCity: selectedCity,
    });
    // console.log("Value----------------------",isSelectedOption,isSelectedOnlineOption,isSelectedInPersonOption)
  };

  const setServiceClient = (serviceNoClient, serviceMaxClient) => {
    setFormData({
      ...formData,
      serviceNoClient,
      serviceMaxClient,
    });
    // console.log("Value----------------------",serviceNoClient,serviceMaxClient)
  };

  const setServiceDuration = (
    serviceTimeHour,
    serviceTimeMin,
    serviceBreakFrom,
    serviceBreakTo,
    serviceOperatingSunday,
    serviceOperatingSundayFrom,
    serviceOperatingSundayTo,
    slotsBeforeBreakSunday,
    slotsAfterBreakSunday,
    serviceOperatingMonday,
    serviceOperatingMondayFrom,
    serviceOperatingMondayTo,
    slotsBeforeBreakMonday,
    slotsAfterBreakMonday,
    serviceOperatingTuesday,
    serviceOperatingTuesdayFrom,
    serviceOperatingTuesdayTo,
    slotsBeforeBreakTuesday,
    slotsAfterBreakTuesday,
    serviceOperatingWednesday,
    serviceOperatingWednesdayFrom,
    serviceOperatingWednesdayTo,
    slotsBeforeBreakWednesday,
    slotsAfterBreakWednesday,
    serviceOperatingThursday,
    serviceOperatingThursdayFrom,
    serviceOperatingThursdayTo,
    slotsBeforeBreakThursday,
    slotsAfterBreakThursday,
    serviceOperatingFriday,
    serviceOperatingFridayFrom,
    serviceOperatingFridayTo,
    slotsBeforeBreakFriday,
    slotsAfterBreakFriday,
    serviceOperatingSaturday,
    serviceOperatingSaturdayFrom,
    serviceOperatingSaturdayTo,
    slotsBeforeBreakSaturday,
    slotsAfterBreakSaturday
  ) => {
    setFormData({
      ...formData,
      serviceTimeHour,
      serviceTimeMin,
      serviceBreakFrom,
      serviceBreakTo,
      serviceOperatingSunday,
      serviceOperatingSundayFrom,
      serviceOperatingSundayTo,
      slotsBeforeBreakSunday,
      slotsAfterBreakSunday,
      serviceOperatingMonday,
      serviceOperatingMondayFrom,
      serviceOperatingMondayTo,
      slotsBeforeBreakMonday,
      slotsAfterBreakMonday,
      serviceOperatingTuesday,
      serviceOperatingTuesdayFrom,
      serviceOperatingTuesdayTo,
      slotsBeforeBreakTuesday,
      slotsAfterBreakTuesday,
      serviceOperatingWednesday,
      serviceOperatingWednesdayFrom,
      serviceOperatingWednesdayTo,
      slotsBeforeBreakWednesday,
      slotsAfterBreakWednesday,
      serviceOperatingThursday,
      serviceOperatingThursdayFrom,
      serviceOperatingThursdayTo,
      slotsBeforeBreakThursday,
      slotsAfterBreakThursday,
      serviceOperatingFriday,
      serviceOperatingFridayFrom,
      serviceOperatingFridayTo,
      slotsBeforeBreakFriday,
      slotsAfterBreakFriday,
      serviceOperatingSaturday,
      serviceOperatingSaturdayFrom,
      serviceOperatingSaturdayTo,
      slotsBeforeBreakSaturday,
      slotsAfterBreakSaturday,
    });
    // console.log("Value----------------------",serviceNoClient,serviceMaxClient)
  };

  const setServiceImage = (imageList) => {
    setFormData({
      ...formData,
      serviceImageList: imageList,
    });
  };

  const formComponents = [
    ServiceGeneralInfoForm,
    ServicePricingForm,
    ServiceQuestionForm,
    ServiceLocationForm,
    ServiceClientForm,
    ServiceDurationForm,
    ServiceScheduleForm,
    ServiceImagesForm,
  ]
  const ProgressIndicator = () => {
    return (
      <Row>
        <Col md="12" lg="12" style={{ display: "flex" }}>
          {formComponents.map((_, index) => (
            <Col lg="1" md="1" key={index}>
              <div
                style={{
                  height: 3,
                  backgroundColor: stepsCount >= index + 1 ? "#FF8D24" : null,
                }}
              ></div>
            </Col>
          ))}
        </Col>
      </Row>
    );
  };

  const CurrentFormStep = formComponents[stepsCount - 1];
  return (
    <div
      style={{
        backgroundColor: "white",
        // flexDirection: "row",
        // display: "flex",
        // height: "100vh",
        width: '100%',
        overflow:"auto"
      }}
    >
      <div
        style={{ backgroundColor: "white", width: "100%", }}
      >
        {/* Page header section with searcbh and profile */}
        <HeaderSection currentTab={"Service"} />
        <div style={{ height: "89%" }}>
          {stepsCount !== 9 ? (
            <div style={{ marginLeft: "5%" }}>
              <ProgressIndicator/>
              <div>
                {CurrentFormStep ? (
                  <CurrentFormStep
                  handleNextClick={handleContinueClick}
                  handleBackClick={handlePageBackClick}
                  setGeneralInfo={setGeneralInfo}
                  setServicePricing={setServicePrice}
                  setServiceQuestion={setServiceQuestion}
                  setServiceLocation={setServiceLocation}
                  setServiceClient={setServiceClient}
                  setServiceDuration={setServiceDuration}
                  setServiceImage={setServiceImage}
                  formValue={formData}
                />
                ) : null}
              </div>
            </div>
          ) : (
            <ItemCreatedConfirmation 
              title={"Service created!"}
              message={"Congratulations! Your service has been successfully published. You \n can now fully operate and explore all the features on the dashboard."}
              linkLabel={"Back to Services"}
              linkTo={"/services"} />
          )}
        </div>
      </div>
    </div>
  );
}

export default CreateServiceForm;
