import { gql } from "@apollo/client";

export const FETCH_SELLING_PLAN_LIST = gql`
  query fetchSellingPlanList(
    $businessId: String!
    $search: String!
    $pageNumber: Int!
    $pageSize: Int!
  ) {
    fetchSellingPlanList(
      businessId: $businessId
      search: $search
      pageNumber: $pageNumber
      pageSize: $pageSize
    ) {
      totalCount
      sellingPlanList {
        _id
        planName
        discount
      }
    }
  }
`;
