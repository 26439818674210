import { combineReducers } from "redux";
import businesProfileReducer from "./businesProfileReducer";
import salesReducer from "./salesReducer";
import userReducer from "./user/userReducer";

const rootReducer = combineReducers({
  user: userReducer,
  businesprofile: businesProfileReducer,
  sales: salesReducer,
});

export default rootReducer;
