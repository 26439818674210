import { Col, Row } from "react-bootstrap";
import { neturalBlack, primaryOrange } from "../../constants/color";
import SalesListHeader from "../sales-list-header/sales-list-header.component";
import { Link } from "react-router-dom";
import LoadingSpinner from "../shared/loading-spinner/loading-spinner.component";
import EmptySalesList from "../empty-sales-list/empty-sales-list.component";
import DropDwonWithPaginationHeader from "../drop-down-with-pagination-header/drop-down-with-pagination-header.component";

const SalesListCard = ({
  totalCount,
  salesList,
  page,
  type,
  setType,
  setPage,
  totalPages,
  pageSize,
  loading,
  search,
  dropDownValue,
}) => {
  // Calculate SR_NO for each record
  const calculateSR_NO = (recordIndex) => {
    return (page - 1) * pageSize + recordIndex + 1;
  };

  return (
    <div
      style={{
        marginTop: "3%",
        backgroundColor: "white",
        width: "100%",
        borderRadius: "12px",
        paddingBottom: "1%",
      }}
    >
      <DropDwonWithPaginationHeader
        type={type}
        setType={setType}
        loading={loading}
        dropDownValue={dropDownValue}
        totalCount={totalCount}
        page={page}
        pageSize={pageSize}
        setPage={setPage}
        totalPages={totalPages}
      />
      {search && search.trim().length == 0 && totalCount === 0 ? null : (
        <SalesListHeader type={type} />
      )}
      {loading ? (
        <div style={{ marginTop: "4%" }}>
          <LoadingSpinner overlay={false} />
        </div>
      ) : search && search.trim().length === 0 && totalCount === 0 ? (
        <EmptySalesList type={type} />
      ) :search && search.trim().length != 0 && totalCount === 0 ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "3%",
          }}
        >
          <label
            style={{ fontSize: "18px", fontWeight: "600", color: neturalBlack }}
          >
            No {type} found
          </label>
        </div>
      ) : (
        salesList.map((data, index) => {
          return (
            <div
              style={{ marginTop: "2%", marginLeft: "1%", marginBottom: "2%" }}
            >
              <Row>
                <Col lg={1} md={1} sm={1} xs={1}>
                  <label style={{ fontSize: "16px" }}>
                    {calculateSR_NO(index)}
                  </label>
                </Col>
                <Col lg={3} md={3} sm={3} xs={3}>
                  <label style={{ fontSize: "16px" }}>{data.id}</label>
                </Col>
                <Col lg={3} md={3} sm={3} xs={3}>
                  <label style={{ fontSize: "16px" }}>{data.date}</label>
                </Col>
                <Col lg={3} md={3} sm={3} xs={3}>
                  <label style={{ fontSize: "16px" }}>{data.name}</label>
                </Col>
                <Col lg={2} md={2} sm={2} xs={2}>
                  <Link
                    to={
                      type === "Product"
                        ? `productDetail/${data.id}`
                        : `serviceDetail/${data.id}`
                    }
                    style={{
                      fontSize: "16px",
                      fontWeight: "600",
                      color: primaryOrange,
                      textDecoration: "none",
                    }}
                  >
                    View
                  </Link>
                </Col>
              </Row>
            </div>
          );
        })
      )}
    </div>
  );
};

export default SalesListCard;
