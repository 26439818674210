import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { netural50 } from "../../constants/color";
import { neturalBlack } from "../../constants/color";

const ModalPopup =(props) =>{
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header closeButton style={{ borderBottom: 0, marginLeft: "3%", marginRight: "3%" }}>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ marginLeft: "3%" }} >
        <p style={{ marginBottom: 0 }}>{props.body}</p>
        <p>{props.body2}</p>
      </Modal.Body>
      <Modal.Footer style={{ borderTop: 0, marginLeft: "3%", marginRight: "3%" }}>
        {props.onCancelPress?
        <Button
          style={{
            backgroundColor: netural50,
            borderColor: netural50,
            color: neturalBlack,
            borderRadius: "12px",
            paddingLeft: "2%",
            paddingRight: "2%",
            fontWeight:"bold"
          }}
          onClick={props.onCancelPress}
        >
          Cancel
        </Button>:null}
        {props.onSubmit ?
        <Button
          style={{
            color: "white",
            backgroundColor: props.title === "Delete Service" || "Delete Product" ? "red" : "green",
            borderRadius: "12px",
            paddingLeft: "2%",
            paddingRight: "2%",
            fontWeight:"bold"
          }}
          onClick={props.onSubmit}
        >
          {props.submitButton}
        </Button>:null}
        {props.onOkayPress ? 
        <Button
          style={{
            backgroundColor: netural50,
            borderColor: netural50,
            color: neturalBlack,
            borderRadius: "12px",
            paddingLeft: "2%",
            paddingRight: "2%",
            fontWeight:"bold"
          }}
          onClick={props.onOkayPress}
        >
          {props.okayButtonText}
        </Button>:null}
      </Modal.Footer>
    </Modal>
  );
}

export default ModalPopup;
