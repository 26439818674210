import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "../custom-calendar/custom-calendar.styles.css";

const CustomCalendar = ({ selectedDate, onChangeDate }) => {
  return (
    <div
      style={{
        width: "35%",
      }}
    >
      <Calendar value={selectedDate} onChange={onChangeDate} />
    </div>
  );
};

export default CustomCalendar;
